import styled from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';

import Colors from '~/styles/colors';
import InputComponent from '~/components/InputComponent';
import { Typography } from '@material-ui/core';

export const DivContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.primary};

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 360px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 300px;
  margin-bottom: 10px;
`;

export const Input = styled(InputComponent)`
  input {
    color: ${Colors.primary};
    border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.primary)};

    &:hover {
      border-bottom-color: ${Colors.primary};
    }
  }
  & label {
    color: ${Colors.primary};
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${Colors.blue};
  }

  && .MuiInput-underline:before {
    border-bottom-color: ${Colors.primary};
  }
  && .MuiInput-underline:hover:before {
    border-bottom: 1px solid ${Colors.primary};
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: red;
    }
    &:hover fieldset {
      border-bottom-color: ${Colors.blue};
    }
    &.Mui-focused fieldset {
      border-bottom-color: ${Colors.blue};
    }
  }
`;

export const Link = styled(BaseLink)`
  color: ${Colors.primary};
  margin-top: 15px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

export const Label = styled(Typography)`
  color: ${Colors.primary};
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;
