import React, { useEffect } from 'react';
import { FormHelperText, TextField } from '@material-ui/core';

import Loading from '~/components/Loading';
import { CategoryType, ManufacturerType, ModelType, useFipe, VehicleType } from '~/services/hooks/useFipe';
import { onlyNumbers } from '~/util/format';
import { Divider, TitleModal } from '~/pages/NewOpportunity/styles';
import { Association } from '~/types';
import { CustomAutocomplete } from './styles';

interface NewVehicleProps {
  modalVehicleIsOpen: boolean;
  fipeModel: FipeModel;
  setFipeModel: (value: FipeModel) => void;
  initialData?: VehicleInitialData;
  title?: string;
}

export interface FipeModel {
  association?: Association;
  association_error?: boolean;
  status?: VehicleStatusType;
  status_error?: boolean;
  category?: CategoryType;
  category_error?: boolean;
  manufacturer?: ManufacturerType;
  manufacturer_value?: string;
  manufacturer_error?: boolean;
  vehicle?: VehicleType;
  vehicle_value?: string;
  vehicle_error?: boolean;
  model?: ModelType;
  model_value?: string;
  model_error?: boolean;
  license_plate?: string;
  license_plate_error?: boolean;
  vin?: string;
  color?: string;
  color_error?: boolean;
  city?: string;
  city_error?: boolean;
  state?: string;
  state_error?: boolean;
}

export type SelectedBenefits = Record<string, string>;

export type VehicleInitialData = {
  vehicleId?: string;
  associationId?: string;
  categoryId?: string;
  manufacturerDescription?: string;
  vehicleDescription?: string;
  modelDescription?: string;
  licensePlate?: string;
  vin?: string;
  color?: string;
  state?: string;
  city?: string;
  status?: string;
};

export type VehicleStatusType = keyof typeof VehicleStatus | undefined;

export const VehicleStatus = {
  ACTIVE: 'ATIVO',
  INACTIVE: 'INATIVO',
  SUSPENDED: 'SUSPENSO',
  PENDING: 'PENDENTE',
};

export function VehicleRegistration({
  modalVehicleIsOpen,
  fipeModel,
  setFipeModel,
  initialData,
  title = 'Novo veículo',
}: NewVehicleProps) {
  const {
    categoryTypes: fipeTypes,
    loadingCategory: loadingFipe,
    manufacturerTypes,
    loadingManufacturer,
    loadingVehicles: loadingVehiclesFipe,
    vehiclesTypes,
    modelsTypes,
    loadingModels,
    isSpecialVehicle,
  } = useFipe({
    getInfo: modalVehicleIsOpen,
    category: fipeModel.category,
    manufacturer: fipeModel.manufacturer,
    vehicle: fipeModel.vehicle,
  });

  const handleChangeSelect = (type: keyof typeof fipeModel, value?: string, upperCase = false) => {
    const newValue =
      type === 'model' && isSpecialVehicle(fipeModel.category?.description)
        ? onlyNumbers(value?.toUpperCase())
        : type === 'license_plate'
        ? value
            ?.toUpperCase()
            .replace(/-/, '')
            .replace(/(\w{3})(\w{4})/, '$1-$2')
        : upperCase
        ? value?.toUpperCase()
        : value;

    switch (type) {
      case 'category':
        setFipeModel({
          ...fipeModel,
          [type]: newValue,
          [`${type}_error`]: false,
          manufacturer: undefined,
          manufacturer_error: false,
          vehicle: undefined,
          vehicle_error: false,
          model: undefined,
          model_error: false,
        });
        break;

      case 'manufacturer':
        setFipeModel({
          ...fipeModel,
          [type]: newValue,
          [`${type}_error`]: false,
          vehicle: undefined,
          vehicle_error: false,
          model: undefined,
          model_error: false,
        });
        break;

      case 'vehicle':
        setFipeModel({
          ...fipeModel,
          [type]: newValue,
          [`${type}_error`]: false,
          model: undefined,
          model_error: false,
        });
        break;

      default:
        setFipeModel({ ...fipeModel, [type]: newValue, [`${type}_error`]: false });
        break;
    }
  };

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const categoryFound = fipeTypes.find(category => category.id === initialData.categoryId);

    setFipeModel({
      ...fipeModel,
      category: categoryFound,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fipeTypes, initialData, setFipeModel]);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const manufacturerFound = manufacturerTypes.find(
      manufacture => manufacture.name.toUpperCase() === initialData.manufacturerDescription?.toUpperCase(),
    );

    setFipeModel({
      ...fipeModel,
      manufacturer: manufacturerFound,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturerTypes, initialData, setFipeModel]);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const vehicleFound = vehiclesTypes.find(
      vehicle => vehicle.name.toUpperCase() === initialData.vehicleDescription?.toUpperCase(),
    );

    setFipeModel({
      ...fipeModel,
      vehicle: vehicleFound,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesTypes, initialData, setFipeModel]);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const modelFound = modelsTypes.find(
      model => model.name.toUpperCase() === initialData.modelDescription?.toUpperCase(),
    );
    setFipeModel({
      ...fipeModel,
      model: modelFound,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelsTypes, initialData, setFipeModel]);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const statusFound = VehicleStatus[initialData.status || VehicleStatus.ACTIVE];

    setFipeModel({
      ...fipeModel,
      license_plate: initialData.licensePlate,
      vin: initialData.vin,
      color: initialData.color,
      state: initialData.state,
      city: initialData.city,
      status: statusFound,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, setFipeModel]);

  if (loadingFipe) {
    return <Loading />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TitleModal>{title}</TitleModal>
      <Divider height={15} />

      <CustomAutocomplete
        id='combo-box-category'
        options={fipeTypes}
        getOptionLabel={type => type.description}
        style={{ width: 300 }}
        renderInput={params => <TextField {...params} label='Categoria' />}
        onChange={(_, value) => {
          setFipeModel({
            ...fipeModel,
            category: value ?? undefined,
            category_error: false,
            manufacturer: undefined,
            manufacturer_error: false,
            vehicle: undefined,
            vehicle_error: false,
            model: undefined,
            model_error: false,
          });
        }}
        value={fipeModel.category ?? null}
      />
      <FormHelperText error>{fipeModel.category_error ? 'Categoria obrigatória' : undefined}</FormHelperText>

      {isSpecialVehicle(fipeModel.category?.description) ? (
        <TextField
          id='manufacturer-field'
          label='Marca'
          value={fipeModel.manufacturer}
          onChange={({ target }) => {
            handleChangeSelect('manufacturer', target.value, true);
          }}
          error={fipeModel.manufacturer_error}
          helperText={fipeModel.manufacturer_error ? 'Marca obrigatória' : ''}
        />
      ) : loadingManufacturer ? (
        <Loading />
      ) : (
        <>
          <CustomAutocomplete
            id='combo-box-manufacturer'
            options={manufacturerTypes}
            getOptionLabel={type => type.name}
            style={{ width: 300 }}
            renderInput={params => <TextField {...params} label='Marca' />}
            onChange={(_, value) => {
              setFipeModel({
                ...fipeModel,
                manufacturer: value ?? undefined,
                manufacturer_error: false,
                vehicle: undefined,
                vehicle_error: false,
                model: undefined,
                model_error: false,
              });
            }}
            value={fipeModel.manufacturer ?? null}
          />
          <FormHelperText error>{fipeModel.manufacturer_error ? 'Marca obrigatória' : undefined}</FormHelperText>
        </>
      )}

      {isSpecialVehicle(fipeModel.category?.description) ? (
        <TextField
          id='vehicle-field'
          label='Modelo'
          value={fipeModel.vehicle}
          onChange={({ target }) => {
            handleChangeSelect('vehicle', target.value, true);
          }}
          error={fipeModel.vehicle_error}
          helperText={fipeModel.vehicle_error ? 'Modelo obrigatório' : ''}
        />
      ) : loadingVehiclesFipe ? (
        <Loading />
      ) : (
        <>
          <CustomAutocomplete
            id='combo-box-vehicle'
            options={vehiclesTypes}
            getOptionLabel={type => type.name}
            style={{ width: 300 }}
            renderInput={params => <TextField {...params} label='Modelo' />}
            onChange={(_, value) => {
              setFipeModel({
                ...fipeModel,
                vehicle: value ?? undefined,
                vehicle_error: false,
                model: undefined,
                model_error: false,
              });
            }}
            value={fipeModel.vehicle ?? null}
          />
          <FormHelperText error>{fipeModel.vehicle_error ? 'Modelo obrigatório' : undefined}</FormHelperText>
        </>
      )}

      {isSpecialVehicle(fipeModel.category?.description) ? (
        <TextField
          id='year-field'
          label='Ano'
          value={fipeModel.model}
          onChange={({ target }) => {
            handleChangeSelect('model', target.value, true);
          }}
          error={fipeModel.model_error}
          helperText={fipeModel.model_error ? 'Ano obrigatório' : ''}
          inputProps={{ maxLength: 4 }}
        />
      ) : loadingModels ? (
        <Loading />
      ) : (
        <>
          <CustomAutocomplete
            id='combo-box-model'
            options={modelsTypes}
            getOptionLabel={type => type.name}
            style={{ width: 300 }}
            renderInput={params => <TextField {...params} label='Ano' />}
            onChange={(_, value) => {
              setFipeModel({ ...fipeModel, model: value ?? undefined, vehicle_error: false });
            }}
            value={fipeModel.model ?? null}
          />
          <FormHelperText error>{fipeModel.model_error ? 'Ano obrigatório' : undefined}</FormHelperText>
        </>
      )}

      <TextField
        id='license-plate-field'
        label='Placa'
        value={fipeModel.license_plate}
        onChange={({ target }) => {
          handleChangeSelect('license_plate', target.value, true);
        }}
        error={fipeModel.license_plate_error}
        helperText={fipeModel.license_plate_error ? 'Placa inválida' : ''}
        inputProps={{ maxLength: 7 }}
      />

      <TextField
        id='vin-field'
        label='Chassi (opcional)'
        value={fipeModel.vin}
        onChange={({ target }) => {
          handleChangeSelect('vin', target.value, true);
        }}
      />

      <TextField
        id='color-field'
        label='Cor'
        value={fipeModel.color}
        onChange={({ target }) => {
          handleChangeSelect('color', target.value, true);
        }}
        error={fipeModel.color_error}
        helperText={fipeModel.color_error ? 'Cor obrigatória' : ''}
      />

      <TextField
        id='city-field'
        label='Cidade'
        value={fipeModel.city}
        onChange={({ target }) => {
          handleChangeSelect('city', target.value, true);
        }}
        error={fipeModel.city_error}
        helperText={fipeModel.city_error ? 'Cidade obrigatória' : ''}
      />

      <TextField
        id='state-field'
        label='Estado'
        value={fipeModel.state}
        onChange={({ target }) => {
          handleChangeSelect('state', target.value, true);
        }}
        error={fipeModel.state_error}
        helperText={fipeModel.state_error ? 'Estado obrigatório' : ''}
        inputProps={{ maxLength: 2 }}
      />
    </div>
  );
}
