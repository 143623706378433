import React from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Label, Icon } from './styles';

const HasOrderInFirestore = () => {
  const hystory = useHistory();

  const handleOrdersInExecution = () => {
    hystory.push('/orders-in-execution');
  };

  return (
    <Container onClick={handleOrdersInExecution}>
      <Label>Solicitação em execução</Label>
      <Icon />
    </Container>
  );
};

export default HasOrderInFirestore;
