import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InputAdornment, Radio, RadioGroup, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { formatCellPhone, formatUsername, removeFormat, removeSpaces } from '~/util/format';
import { validCNPJ, validCPF, validPassword, validEmail } from '~/util/validate';
import { Container, SubmitButton, Label } from './styles';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';
import { Input } from '../ConfirmSignUp/styles';
import Colors from '~/styles/colors';
import { signUpConfirmation, signUpRequest } from '~/store/ducks/auth';
import { ConfirmationType } from '~/types';

type UserType = {
  username: string;
  given_name: string;
  family_name: string;
  password: string;
  locale: string;
  zoneinfo: string;
  email?: string;
  phone_number?: string;
};

export default function SignUp() {
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [field, setField] = useState('');
  const [confirmField, setConfirmField] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [secureTextPassword, setSecureTextPassword] = useState(true);
  const [secureTextConfirmPassword, setSecureTextConfirmPassword] = useState(true);
  const [physical, setPhysical] = useState(true);
  const [selectedConfirmation, setSelectedConfirmation] = useState<ConfirmationType>('email');

  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToast();
  const signUpState = useSelector(state => state.auth.signUp);

  const submitForm = () => {
    try {
      let userName = removeFormat(username);
      if (!userName.trim()) {
        throw Error('message.app.usename.mandatory');
      }

      if (userName.length <= 11) {
        userName = userName.padStart(11, '0');
        if (!validCPF(userName)) {
          throw Error('message.cpf.invalid');
        }
      } else {
        userName = userName.padStart(14, '0');
        if (!validCNPJ(userName)) {
          throw Error('message.cnpj.invalid');
        }
      }

      if (!name.trim()) {
        throw Error('message.customer.batch.givenname.mandatory');
      }

      if (physical && !lastName.trim()) {
        throw Error('message.customer.batch.familyname.mandatory');
      }

      if (!field.trim()) {
        if (selectedConfirmation === 'email') {
          throw Error('message.user.email.mandatory');
        }

        throw Error('message.user.phoneNumber.mandatory');
      }

      if (selectedConfirmation === 'email' && !validEmail(field)) {
        throw Error('message.user.email.invalid');
      }

      if (field !== confirmField) {
        if (selectedConfirmation === 'email') {
          throw Error('message.app.email.not-matched');
        }

        throw Error('message.app.phoneNumber.not-matched');
      }

      if (!password.trim()) {
        throw Error('message.app.password.mandatory');
      }

      if (!validPassword(password)) {
        throw Error('message.app.invalid.password');
      }

      if (password !== confirmPassword) {
        throw Error('message.app.password.not-matched');
      }

      const user: UserType = {
        username: userName,
        given_name: name,
        family_name: lastName,
        password,
        locale: 'pt-BR',
        zoneinfo: 'America/Sao_Paulo',
      };

      selectedConfirmation === 'email' ? (user.email = field) : (user.phone_number = `+55${removeFormat(field)}`);

      dispatch(signUpRequest(user));
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao criar a conta, tente novamente');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  };

  const handleUsername = e => {
    let userName = formatUsername(e.target.value);
    setUsername(userName);
    userName = removeFormat(userName);

    if (userName.length > 11) {
      return setPhysical(false);
    }

    return setPhysical(true);
  };

  const handleChangeConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedConfirmation(event.target.value as ConfirmationType);
  };

  const populateField = (func: (param: string) => void, value: string) => {
    let newValue = removeSpaces(value);

    if (selectedConfirmation === 'phone_number') {
      newValue = formatCellPhone(removeFormat(newValue));
    }

    func(newValue);
  };

  useEffect(() => {
    if (signUpState?.loading === false && signUpState?.error === false) {
      dispatch(signUpConfirmation());
      return history.push('/');
    }

    if (signUpState?.loading === false && signUpState?.error === true) {
      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: signUpState.message,
      });
    }
  }, [addToast, dispatch, history, signUpState]);

  useEffect(() => {
    setField('');
    setConfirmField('');
  }, [selectedConfirmation]);

  return (
    <Container>
      <Label>CADASTRO</Label>

      <Input label='CPF / CNPJ' onChange={handleUsername} value={username} inputProps={{ inputMode: 'numeric' }} />

      <Input label={physical ? 'Nome' : 'Razão Social'} onChange={e => setName(e.target.value)} value={name} />

      {physical && <Input label='Sobrenome' onChange={e => setLastName(e.target.value)} value={lastName} />}

      <Typography style={{ color: Colors.primary, marginTop: 24, textAlign: 'left' }}>
        Confirmação de cadastro por:
      </Typography>
      <RadioGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Radio
          checked={selectedConfirmation === 'email'}
          onChange={handleChangeConfirmation}
          value='email'
          name='typeConfirmation'
          inputProps={{ 'aria-label': 'E-mail' }}
        />
        <Typography style={{ color: Colors.primary }}>E-mail</Typography>
        <div style={{ width: 24, display: 'flex' }} />

        <Radio
          checked={selectedConfirmation === 'phone_number'}
          onChange={handleChangeConfirmation}
          value='phone_number'
          name='typeConfirmation'
          inputProps={{ 'aria-label': 'Phone Number' }}
        />
        <Typography style={{ color: Colors.primary }}>Telefone</Typography>
      </RadioGroup>

      <Input
        label={selectedConfirmation === 'email' ? 'E-mail' : 'Telefone'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => populateField(setField, e.target.value)}
        autoCapitalize='none'
        value={field}
        inputProps={{
          inputMode: selectedConfirmation === 'email' ? 'email' : 'numeric',
          ...(selectedConfirmation === 'phone_number' ? { maxLength: 14 } : {}),
        }}
      />

      <Input
        label={selectedConfirmation === 'email' ? 'Confirmar e-mail' : 'Confirmar telefone'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => populateField(setConfirmField, e.target.value)}
        autoCapitalize='none'
        value={confirmField}
        inputProps={{
          inputMode: selectedConfirmation === 'email' ? 'email' : 'numeric',
          ...(selectedConfirmation === 'phone_number' ? { maxLength: 14 } : {}),
        }}
      />

      <Input
        name='password'
        label='Senha'
        type={secureTextPassword ? 'password' : 'text'}
        value={password}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        autoCapitalize='none'
        InputProps={{
          endAdornment: (
            <InputAdornment
              position='end'
              onClick={() => setSecureTextPassword(secure => !secure)}
              style={{ cursor: 'pointer', color: Colors.primary }}
            >
              {secureTextPassword ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          ),
        }}
      />

      <Input
        name='confirm-password'
        label='Confirmar Senha'
        type={secureTextConfirmPassword ? 'password' : 'text'}
        value={confirmPassword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
        autoCapitalize='none'
        InputProps={{
          endAdornment: (
            <InputAdornment
              position='end'
              onClick={() => setSecureTextConfirmPassword(secure => !secure)}
              style={{ cursor: 'pointer', color: Colors.primary }}
            >
              {secureTextConfirmPassword ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          ),
        }}
      />

      <SubmitButton
        label='CRIAR'
        width='100%'
        backgroundColor={Colors.primary}
        color={Colors.white}
        onClick={submitForm}
      />

      <SubmitButton
        type='button'
        label='Voltar'
        backgroundColor={Colors.white}
        color={Colors.primary}
        onClick={history.goBack}
        border={`1px solid ${Colors.blue}`}
      />
    </Container>
  );
}
