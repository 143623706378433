import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { loginRequest, signUpClear } from '~/store/ducks/auth';

import Button from '~/components/Button';

import { Content, Divider, Fields, FormLogin, Header, Input, Label, Link } from './styles';
import { formatUsername, removeFormat } from '~/util/format';
import { validCNPJ, validCPF } from '~/util/validate';
import Colors from '~/styles/colors';

interface MessageError {
  message: string;
}

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userError, setUserError] = useState<MessageError>();
  const [passError, setPassError] = useState<MessageError>();
  const [secureText, setSecureText] = useState(true);

  const { loading, error } = useSelector(state => state.auth);
  const { isAssociate } = (useLocation().state || {}) as { isAssociate?: boolean };
  const history = useHistory();

  useEffect(() => {
    if (error) {
      setUserError({ message: 'Usuário/senha inválido' });
      setPassError(undefined);
    }
  }, [error]);

  const dispatch = useDispatch();

  function handleSubmit(event) {
    event.preventDefault();
    setUserError(undefined);
    setPassError(undefined);
    let newUsername = removeFormat(username);

    if (!newUsername.trim()) {
      return setUserError({ message: 'CPF / CNPJ não informado!' });
    }

    if (newUsername.length <= 11) {
      newUsername = newUsername.padStart(11, '0');
      if (!validCPF(newUsername)) {
        return setUserError({ message: 'CPF inválido!' });
      }
    } else {
      newUsername = newUsername.padStart(14, '0');
      if (!validCNPJ(newUsername)) {
        return setUserError({ message: 'CNPJ inválido!' });
      }
    }

    if (!password.trim()) {
      return setPassError({ message: 'Senha não informada!' });
    }
    dispatch(loginRequest(newUsername.trim().toLowerCase(), password));
    return true;
  }

  useEffect(() => {
    dispatch(signUpClear());
  }, [dispatch]);

  return (
    <FormLogin autoComplete='off' onSubmit={handleSubmit}>
      <Content>
        <Header>
          <Label data={{ fontWeight: 'bold', fontSize: 30 }}>Bem vindo{isAssociate && ', associado'}!</Label>
          <Label data={{ fontSize: 28 }}>Insira seus dados</Label>
        </Header>

        <Fields>
          <Input
            name='username'
            label='CPF / CNPJ'
            value={username}
            onChange={e => setUsername(formatUsername(e.target.value))}
            error={userError}
            autoFocus
            style={{ flex: '0 1 auto' }}
          />

          <Input
            name='password'
            label='Senha'
            type={secureText ? 'password' : 'text'}
            value={password}
            onChange={e => setPassword(e.target.value)}
            error={passError}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position='end'
                  onClick={() => setSecureText(secure => !secure)}
                  style={{ cursor: 'pointer', color: Colors.primary }}
                >
                  {secureText ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
              ),
            }}
            style={{ flex: '0 1 auto' }}
          />
          <Divider />

          <Link to='/forgot-password'>Esqueceu a senha?</Link>

          {isAssociate && (
            <Label data={{ fontSize: 14, textAlign: 'left' }}>
              <br />
              <br />
              <Label data={{ fontWeight: 'bold' }}>Sua senha inicial é composta por:</Label>
              <br />
              <br />
              Primeira letra do seu NOME em Maiúsculo{'\n'}
              Segunda letra do seu NOME em Minúsculo{'\n'}6 primeiros dígitos do seu CPF ou CNPJ
            </Label>
          )}
        </Fields>

        <Button
          type='submit'
          label='Entrar'
          loading={loading}
          backgroundColor={Colors.primary}
          color={Colors.white}
          width='100%'
        />
        <Button
          type='button'
          label='Voltar'
          backgroundColor={Colors.white}
          color={Colors.primary}
          onClick={history.goBack}
          border={`1px solid ${Colors.blue}`}
        />
        <Divider />

        {!isAssociate && (
          <Link to='/sign-up'>
            Ainda não tem conta? <Label data={{ fontWeight: 'bold' }}>Crie aqui</Label>
          </Link>
        )}
        <Divider />
      </Content>
    </FormLogin>
  );
}
