import styled from 'styled-components';
import Colors from '~/styles/colors';

export const Form = styled.form`
  display: grid;
`;

export const Title = styled.h1`
  font-size: 36px;
  color: ${Colors.primary};
`;
