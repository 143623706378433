import { Typography, Button, Container as ContainerBase } from '@material-ui/core';
import styled from 'styled-components';

import Colors from '~/styles/colors';

interface TextCardProps {
  fontSize?: number;
}

export const CardItem = styled(Button)`
  margin: 8px 0px;
`;

export const TextCard = styled(Typography)<TextCardProps>`
  color: #fff;
  padding: 0 5px;
  flex: 1;
  color: ${Colors.primary};
  font-size: ${({ fontSize }) => fontSize || 16}px;
  font-weight: bold;
  flex-wrap: wrap;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  flex: 1;
  align-items: center;
`;

export const CardData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Container = styled(ContainerBase)`
  padding: 0 16px;
`;

export const Label = styled(Typography)`
  text-align: center;
`;
