import styled from 'styled-components';
import { Container as ContainerBase, Typography } from '@material-ui/core';

import Colors from '~/styles/colors';
import { CSSProperties } from 'react';

interface LabelProps {
  fontWeight?: string;
  marginTop?: number;
  marginHorizontal?: number;
  fontSize?: number;
  textAlign?: string;
}

interface SpacerProps {
  flex?: CSSProperties['flex'];
}

export const Container = styled(ContainerBase)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  justify-content: center;
  background-color: ${Colors.lightGreyNew};
  height: 100vh;
`;

export const ViewLabel = styled.div`
  align-items: center;
`;

export const Label = styled(Typography)<LabelProps>`
  color: ${Colors.primary};
  font-weight: ${props => props.fontWeight ?? 'normal'};
  margin-top: ${props => props.marginTop ?? 0}px;
  margin-left: ${props => props.marginHorizontal ?? 20}px;
  margin-right: ${props => props.marginHorizontal ?? 20}px;
  font-size: ${props => props.fontSize ?? 16}px;
  text-align: ${props => props.textAlign ?? 'center'};
`;

export const Spacer = styled.div<SpacerProps>`
  display: flex;
  flex: ${props => props.flex ?? 1};
`;

export const ViewButton = styled.div`
  width: 70%;
  align-self: center;
`;
