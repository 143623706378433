import styled from 'styled-components';
import { IconButton as IconButtonBase } from '@material-ui/core';
import { Close as CloseBase } from '@material-ui/icons';

import Colors from '~/styles/colors';

export const IconButton = styled(IconButtonBase)`
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 20px;
  background-color: red;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;

  -webkit-box-shadow: 0px 0px 15px -2px #000000;
  box-shadow: 0px 0px 15px -2px #000000;
`;

export const Close = styled(CloseBase)`
  color: ${Colors.white};
  font-size: 2.2rem;
  font-weight: bold;
`;
