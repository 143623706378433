import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { doc, onSnapshot, deleteDoc, setDoc } from 'firebase/firestore';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AttachMoney, Cancel, ChevronLeft, ChevronRight, Loop } from '@material-ui/icons';
import { GrCopy } from 'react-icons/gr';

import { db } from '~/config/firebase';
import WhatsAppIcon from '~/assets/svg/whats-app.svg';
import { getIconService } from '~/util/getIcon';
import { formatCellPhone, removeFormat } from '~/util/format';
import { useNewOrder } from '~/services/hooks/useNewOrder';
import { useToast } from '~/services/hooks/toast';
import { handleApiErrorResponse } from '~/services/handleErrors';
import Colors from '~/styles/colors';
import Title from '../../components/Title';
import ModalCancel from '../../components/ModalCancel';
import Modal from '~/components/ModalNewVehicle';
import LocationSearchInput from '../../components/LocationSearchInput';
import Loading from '~/components/Loading';
import InputComponent from '~/components/InputComponent';
import {
  Container,
  Content,
  GridItemContainer,
  ModalSuccessBottom,
  ModalSuccessCard,
  ModalSuccessContainer,
  ModalSuccessLink,
  ModalSuccessTop,
  Pin,
} from './styles';
import {
  AddressComponent,
  Coords,
  CoordsOrder,
  ErrorResponse,
  LatLng,
  MapCalculatorCostParameters,
  MapCalculatorRequest,
  MapCalculatorResponse,
  OrderRequest,
  OrderResponse,
  PaymentStatus,
} from '~/types';
import { needClear } from '~/store/ducks/order';
import SimpleBottomNavigation from '~/components/SimpleBottomNavigation';
import SimpleBottomAction from '~/components/SimpleBottomAction';
import { Divider } from '../../styles';
import { useAssociationBalance } from '~/services/hooks';
import { GridContainer } from './components/GridContainer';

enum CalcStatus {
  'NEW',
  'CALCULATING',
  'CALCULATED',
  'ERROR',
  'SEARCH',
}

enum OrderStatus {
  'PAYMENT_PENDING',
  'PAYMENT_APPROVED',
  'PAYMENT_REFUSED',
  'ALLOCATING',
  'ON_GOING',
}

interface MapLinkResponse {
  solution?: {
    id: string;
    clientId: string;
    legs: Array<{
      points: Array<{
        latitude: number;
        longitude: number;
      }>;
    }>;
    totalDistance?: number;
    totalSpeedProfilePenalty?: number;
  };
}

interface Points {
  lat: number;
  lng: number;
}

type ValueType = {
  [key: string]: {
    value: number;
  };
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

const OpportunityMap = () => {
  const [modalCancel, setModalCancel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [origin, setOrigin] = useState<Coords>();
  const [destination, setDestination] = useState<Coords>();
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState<JSX.Element>();
  const [calcStatus, setCalcStatus] = useState<CalcStatus>();
  const [forcePickTow, setForcePickTow] = useState(false);
  const [mapLinkID, setMapLinkID] = useState<string>();
  const [currentLatLng, setCurrentLatLng] = useState<LatLng>();
  const [map, setMap] = useState<any>();
  const [points, setPoints] = useState<Points[]>();
  const [dataCalculator, setDataCalculator] = useState<MapCalculatorResponse>();
  const [valueOverDistance, setValueOverDistance] = useState<MapCalculatorCostParameters>();
  const [timeZone, setTimeZone] = useState<string>();
  const [orderStatus, setOrderStatus] = useState<OrderStatus>();
  const [mapLinkData, setMapLinkData] = useState<MapLinkResponse>();
  const [contactPhoneNumber, setContactPhoneNumber] = useState('');
  const [confirmationType, setConfirmationType] = useState<string>();
  const [openModalPhoneNumber, setOpenModalPhoneNumber] = useState(false);
  const [addressOrigin, setAddressOrigin] = useState<AddressComponent>();
  const [addressDestination, setAddressDestination] = useState<AddressComponent>();
  const [paymentError, setPaymentError] = useState<string>();
  const [orderId, setOrderId] = useState<string>();
  const [positionCenter, setPositionCenter] = useState<{ lat: number; lng: number }>();
  const [zoomPosition, setZoomPosition] = useState(16);
  const [pixCode, setPixCode] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);

  const refPhone = useRef(null);
  const refAllocation = useRef(false);
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    validateRegionsService,
    getTimeZoneByGoogle,
    maplinkTrip,
    mapCalculator,
    payments,
    createOrder,
  } = useNewOrder();
  const { addToast } = useToast();

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    service,
    vehicle,
    customerId,
    separateService,
    uuid,
    quizAnswers,
    associationBalance,
    errorBalance,
  } = useSelector(state => ({
    service: state.order.service,
    vehicle: state.order.vehicle,
    customerId: state.auth.user['cognito:username'],
    separateService: state.order.separateService,
    uuid: state.order.uuid,
    quizAnswers: state.order.quizAnswers,
    associationBalance: state.associationBalance.account_statement,
    errorBalance: state.associationBalance.error,
  }));

  useAssociationBalance(vehicle?.association_id);

  const flightPath = useMemo(() => {
    const google = window.google;

    return new google.maps.Polyline({
      strokeColor: Colors.strokeColor,
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const {
        coords: { latitude, longitude },
      } = position;
      setCurrentLatLng({ latitude, longitude });
    });
  }, []);

  useEffect(() => {
    if (!points) {
      flightPath.setMap(null);
      return;
    }

    const flightPathCoordinates: google.maps.LatLngLiteral[] = points;

    flightPath.setPath(flightPathCoordinates);

    flightPath.setMap(map);
  }, [flightPath, map, points]);

  const handleCancel = useCallback(() => {
    setModalCancel(true);
  }, []);

  const handleConfirmCancel = useCallback(async () => {
    history.push('/new-opportunity');
  }, [history]);

  const calculator = useCallback(
    async (points, mapLinkData?: MapLinkResponse) => {
      setCalcStatus(CalcStatus.CALCULATING);
      try {
        const timeZoneId = origin && (await getTimeZoneByGoogle(origin));

        let data: MapCalculatorRequest = {
          time_zone_id: timeZoneId || 'America/Sao_Paulo',
          displace: service?.displace,
          service_id: service?.id,
          association_id: vehicle?.association_id,
          vehicle_id: vehicle?.id,
          social_security: customerId,
          has_picktow: forcePickTow || separateService,
          points,
          source: addressOrigin?.city,
        };

        if (service?.displace) {
          data = {
            ...data,
            total_distance: mapLinkData?.solution?.totalDistance,
            total_speed_profile_penalty: mapLinkData?.solution?.totalSpeedProfilePenalty,
            destination: addressDestination?.city,
          };
        }

        const responseCalculator = await mapCalculator(data);

        setDataCalculator(responseCalculator.data);

        if (responseCalculator.data.charge_additional_fee) {
          setMessage(<Typography align='center'>Um valor excedente será cobrado!</Typography>);
          setOpenModal(true);
        }

        if (service?.displace) {
          const parameters = responseCalculator.data.cost_parameters;
          const filterCostParameters = parameters.filter(f => f.type === 'OVER_DISTANCE' && f.payer === 'CUSTOMER');
          setValueOverDistance(filterCostParameters[0]);
        }

        setTimeZone(timeZoneId);
        setCalcStatus(CalcStatus.CALCULATED);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao realizar o calculo');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      addToast,
      addressDestination,
      addressOrigin,
      customerId,
      forcePickTow,
      getTimeZoneByGoogle,
      mapCalculator,
      origin,
      separateService,
      service,
      vehicle,
    ],
  );

  const showModalSuccess = useCallback(() => {
    setModalSuccess(true);
  }, []);

  const handleCloseModalSuccess = () => {
    dispatch(needClear());
    history.push('/new-opportunity');
  };

  const confirmLocations = useCallback(async () => {
    if (!origin || !origin.latitude || !origin.longitude) {
      addToast({ title: 'Validação', type: 'error', description: 'Origem é obrigatória!' });
      return;
    }

    if (service?.displace && (!destination || !destination.latitude || !destination.longitude)) {
      addToast({ title: 'Validação', type: 'error', description: 'Destino é obrigatório!' });
      return;
    }

    setLoading(true);
    const regionValid = origin && (await validateRegionsService(origin));

    if (!regionValid) {
      setMessage(
        <>
          <Typography>Não podemos atender sua solicitação</Typography>
          <Typography>Fora da área de atuação da Picktow.</Typography>
        </>,
      );
      setLoading(false);
      setOpenModal(true);
      return;
    }

    if (!service?.displace) {
      calculator([origin]);
      return;
    }

    try {
      setLoading(true);

      const response = await maplinkTrip({
        problem: {
          calculationMode: 'THE_FASTEST',
          points: [
            {
              ...origin,
              siteId: 'source',
            },
            {
              ...destination,
              siteId: 'destination',
            },
          ],
          useRealSpeeds: true,
          startDate: 0,
        },
        identity: uuid,
      });

      const location = response.headers?.location;

      if (location) {
        const linkId = location.split('/').pop();
        setMapLinkID(linkId);
      }

      setCalcStatus(CalcStatus.NEW);
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao realizar a rota!');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });

      setLoading(false);
    }
  }, [addToast, calculator, destination, maplinkTrip, origin, service, uuid, validateRegionsService]);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'users', uuid), snapshot => {
      if (!snapshot) {
        return;
      }

      const data = snapshot.data() as MapLinkResponse | undefined;
      setMapLinkData(data);

      if (
        service?.displace &&
        loading &&
        !!mapLinkID &&
        mapLinkID === data?.solution?.id &&
        calcStatus === CalcStatus.NEW
      ) {
        setPoints(data.solution.legs[0].points.map(item => ({ lat: item.latitude, lng: item.longitude })));
        calculator(data.solution.legs[0].points, data);
      }
    });

    return unsubscribe;
  }, [calcStatus, calculator, loading, mapLinkID, service, uuid]);

  useEffect(() => {
    if (!orderId) {
      return;
    }

    const unsubscribe = onSnapshot(doc(db, 'orders', orderId), async snapshot => {
      if (!snapshot) {
        return;
      }

      const orderDocument = snapshot.data() as OrderResponse | undefined;

      if (orderDocument && (orderDocument.status === 'PAYMENT_APPROVED' || orderDocument.status === 'ALLOCATING')) {
        try {
          await deleteDoc(doc(db, 'users', uuid));
          showModalSuccess();
        } catch {
          addToast({
            title: 'Erro Inesperado',
            type: 'error',
            description: 'Erro ao excluir oportunidade do Firebase!',
          });
        }
      }
    });

    return unsubscribe;
  }, [addToast, orderId, showModalSuccess, uuid]);

  useEffect(() => {
    if (!orderId) {
      return;
    }

    // get Pix Code
    const unsubscribe = onSnapshot(doc(db, 'payments', orderId), snapshot => {
      if (!snapshot) {
        return;
      }

      const orderPayment = snapshot?.data() as PaymentStatus | undefined;

      if (orderPayment?.gatewayDetails?.pixText) {
        setPixCode(orderPayment.gatewayDetails.pixText);
      }
    });

    return unsubscribe;
  }, [orderId]);

  const handleOrigin = useCallback((latLng: google.maps.LatLngLiteral | undefined) => {
    if (!latLng) {
      return;
    }

    setOrigin({ latitude: latLng.lat, longitude: latLng.lng });
  }, []);

  const handleDestination = useCallback((latLng: google.maps.LatLngLiteral | undefined) => {
    if (!latLng) {
      return;
    }

    setDestination({ latitude: latLng.lat, longitude: latLng.lng });
  }, []);

  const handleOnChange = useCallback(() => {
    setPoints(undefined);
  }, []);

  const statusMessage = useCallback(() => {
    switch (orderStatus) {
      case OrderStatus.PAYMENT_PENDING:
        return 'Aguardando pagamento...';
      case OrderStatus.PAYMENT_APPROVED:
        return 'Pagamento aprovado...';
      case OrderStatus.PAYMENT_REFUSED:
        return 'Pagamento recusado...';
      case OrderStatus.ALLOCATING:
        return 'Procurando prestador de serviço...';
      case OrderStatus.ON_GOING:
        return 'Prestador de serviço indo até você...';
      default:
        return '';
    }
  }, [orderStatus]);

  const showPixCode = useCallback(
    (showMessagePix: boolean) => {
      return showMessagePix && pixCode === ''
        ? 'Gerando código Pix, aguarde'
        : showMessagePix && (
            <>
              <Typography align='center'>Código Pix: {pixCode}</Typography>

              <SimpleBottomNavigation>
                <CopyToClipboard
                  text={pixCode}
                  onCopy={() =>
                    addToast({
                      title: 'Sucesso!',
                      type: 'success',
                      description: 'O código Pix foi copiado com sucesso!',
                    })
                  }
                >
                  <SimpleBottomAction label='Copiar código' icon={GrCopy} loading={loading} disabled={loading} />
                </CopyToClipboard>
              </SimpleBottomNavigation>
            </>
          );
    },
    [addToast, loading, pixCode],
  );

  const paymentsType = useMemo(() => {
    if (!payments) {
      return undefined;
    }

    const paymentPix = payments.filter(payment => payment.type === 'ONLINE' && payment.payment_method === 'PIX');

    if (!paymentPix) {
      return undefined;
    }

    return [paymentPix[0].type, 'Pagar com PIX'];
  }, [payments]);

  const associationTotalValue: ValueType = useMemo(
    () =>
      dataCalculator?.cost_parameters
        .filter(
          item => !['CANCELLATION_FEE', 'COMMISSION_PERCENTAGE'].includes(item.type) && item.payer === 'ASSOCIATION',
        )
        .reduce((acc, item) => {
          const valueSum = item.value * item.multiplier * item.amount;

          if (acc[item.payer]) {
            acc[item.payer]['value'] = acc[item.payer]['value'] + valueSum;
          } else {
            acc[item.payer] = { value: valueSum };
          }
          return acc;
        }, {} as ValueType) || ({} as ValueType),
    [dataCalculator],
  );

  const confirmSolicitation = useCallback(
    async (type: string) => {
      if (!contactPhoneNumber || contactPhoneNumber.length < 13) {
        setConfirmationType(type);
        setOpenModalPhoneNumber(true);
        return;
      }

      refAllocation.current = false;

      if (!origin || !origin.latitude || !origin.longitude) {
        addToast({ title: 'Validação', type: 'error', description: 'Origem é obrigatória!' });
        return;
      }

      if (service?.displace && (!destination || !destination.latitude || !destination.longitude)) {
        addToast({ title: 'Validação', type: 'error', description: 'Destino é obrigatório!' });
        return;
      }

      if (
        (associationTotalValue['ASSOCIATION']?.value || 0) > (associationBalance?.available_balance || 0) &&
        !errorBalance
      ) {
        setPaymentError('Saldo insuficiente na empresa. deseja solicitar particular?');
        return;
      }

      setLoading(true);

      let coordinates: CoordsOrder[] = [
        {
          position: 'SOURCE',
          ordination: 0,
          latitude: origin.latitude,
          longitude: origin.longitude,
          address: addressOrigin?.fullAddress,
          neighborhood: addressOrigin?.neighborhood,
          city: addressOrigin?.city,
        },
      ];

      if (service?.displace) {
        coordinates = [
          ...coordinates,
          {
            position: 'DESTINATION',
            ordination: 1,
            latitude: destination?.latitude,
            longitude: destination?.longitude,
            address: addressDestination?.fullAddress,
            neighborhood: addressDestination?.neighborhood,
            city: addressDestination?.city,
          },
        ];
      }

      try {
        const quizWithPhone = [
          ...(quizAnswers.length > 0 ? quizAnswers : []),
          {
            question: 'Telefone de contato',
            answer: contactPhoneNumber,
            block: false,
          },
          {
            question: 'Alocação manual',
            answer: false,
            block: false,
          },
        ];

        const data: OrderRequest = {
          payment: {
            token: '3fa85f64-5717-4562-b3fc-2c963f66afa6', // id do payment (mockar)
            type,
          },
          association_name: vehicle?.association_trading_name,
          detached: separateService,
          customer_id: customerId,
          association_id: vehicle?.association_id,
          time_zone: timeZone,
          service_type: service?.description,
          cost: dataCalculator,
          vehicle: {
            type: vehicle?.vehicle_customer_type_description,
            model: vehicle?.model,
            model_year: vehicle?.model_year,
            color: vehicle?.color,
            manufacture: vehicle?.manufacture,
            status: vehicle?.status,
            vin: vehicle?.vin,
            license_plate: vehicle?.license_plate,
            state: vehicle?.state,
            city: vehicle?.city,
          },
          coordinates,
          association_code: vehicle?.association_code,
          service_id: service?.id,
          quiz_answers: JSON.stringify(quizWithPhone),
          disable_auto_allocation: refAllocation.current,
        };

        const response = await createOrder(data);
        const location = response.headers?.location;
        let locationOrderId: string;

        if (location) {
          locationOrderId = location.split('/').pop();
          setOrderId(locationOrderId);
          setOrderStatus(OrderStatus.PAYMENT_PENDING);

          setDoc(
            doc(db, 'orders', locationOrderId),
            {
              vin: vehicle?.vin,
              license_plate: vehicle?.license_plate,
              service_id: service?.id,
              service_type: service?.description,
              vehicle_type: vehicle?.vehicle_customer_type_description,
              association_code: vehicle?.association_code,
              service_displace: service?.displace,
              latitude: origin.latitude,
              longitude: origin.longitude,
              association_id: vehicle?.association_id,
              vehicle_id: vehicle?.id,
              separate_service: separateService,
              cost: {
                surcharge: dataCalculator?.surcharge,
              },
            },
            { merge: true },
          );

          addToast({ title: 'Sucesso', type: 'success', description: 'Oportunidade criada com sucesso!' });

          if (type === 'PIX') {
            return history.replace('/pix', { orderId: locationOrderId });
          }
        }

        setCalcStatus(CalcStatus.SEARCH);
      } catch (error) {
        const errorResponse = error as ErrorResponse;

        if (errorResponse.response?.data?.messages[0] === 'message.payment.association.balance.insufficient') {
          setForcePickTow(true);
          confirmLocations();
          return;
        }

        const errorMessages = handleApiErrorResponse(errorResponse, 'Erro ao criar oportunidade!');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoading(false);
        setContactPhoneNumber('');
      }
    },
    [
      addToast,
      addressDestination,
      addressOrigin,
      associationBalance,
      associationTotalValue,
      confirmLocations,
      contactPhoneNumber,
      createOrder,
      customerId,
      dataCalculator,
      destination,
      errorBalance,
      history,
      origin,
      quizAnswers,
      separateService,
      service,
      timeZone,
      vehicle,
    ],
  );

  const handleSubmit = useCallback(() => {
    const payment = payments?.find(payment => payment.type === 'ONLINE' && payment.payment_method === 'PIX');

    if (!payment) {
      addToast({ title: 'Validação', type: 'error', description: 'Pagamento obrigatório!' });
      return;
    }

    confirmSolicitation(payment.payment_method);
  }, [addToast, confirmSolicitation, payments]);

  const cancel = useCallback(() => {
    setCalcStatus(undefined);
    setMapLinkID(undefined);
    setOrigin(undefined);
    setDestination(undefined);
    setPoints(undefined);
  }, []);

  const handlePhoneNumber = useCallback(() => {
    if (!contactPhoneNumber) {
      addToast({ title: 'Validação', type: 'error', description: 'Telefone para contato é obrigatório' });
      return;
    }

    if (contactPhoneNumber.length < 13) {
      addToast({ title: 'Validação', type: 'error', description: 'Telefone inválido. (XX)XXXX-XXXX' });
      return;
    }

    setOpenModalPhoneNumber(false);

    if (!confirmationType) {
      addToast({ title: 'Erro Inesperado', type: 'error', description: 'Tipo de confirmação inválido!' });
      return;
    }

    confirmSolicitation(confirmationType);
  }, [addToast, confirmSolicitation, confirmationType, contactPhoneNumber]);

  const descriptionAndValue = useMemo(
    () => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {service?.description && (
          <Avatar
            alt={service?.description}
            src={getIconService(service?.description)}
            style={{ width: 52, height: 52 }}
          />
        )}
        {dataCalculator?.surcharge && (
          <Typography variant='h5' align='center'>{`R$ ${dataCalculator.surcharge
            .toFixed(2)
            .replace('.', ',')}`}</Typography>
        )}
      </div>
    ),
    [dataCalculator, service],
  );

  useEffect(() => {
    if (origin && !destination) {
      setPositionCenter({ lat: origin.latitude, lng: origin.longitude });
      return;
    }

    if (!origin && destination) {
      setPositionCenter({ lat: destination.latitude, lng: destination.longitude });
      return;
    }

    if (!origin || !destination) {
      return;
    }

    const latitudes = [origin.latitude, destination.latitude];
    const longitudes = [origin.longitude, destination.longitude];

    const bounds = {
      ne: {
        lat: Math.min(...latitudes),
        lng: Math.max(...longitudes),
      },
      sw: {
        lat: Math.max(...latitudes),
        lng: Math.min(...longitudes),
      },
    };

    const size = {
      width: 300, // Map width in pixels
      height: 600, // Map height in pixels
    };

    const { center, zoom } = fitBounds(bounds, size);

    setPositionCenter(center);
    setZoomPosition(zoom);
  }, [destination, origin]);

  const reasonsMessages = () => {
    const messages: string[] = [];

    if (dataCalculator?.benefits_quota_exceeded) {
      messages.push(
        'A cota foi consumida do pacote de benefício. Estamos a disposição caso queira solicitar particular.',
      );
    }

    if (dataCalculator?.charge_additional_fee) {
      messages.push('O beneficio permite que o destino seja cidade domicilio ou da cidade mais próxima da origem.');
    }

    if (
      (associationTotalValue['ASSOCIATION']?.value || 0) > (associationBalance?.available_balance || 0) &&
      !errorBalance
    ) {
      messages.push('Saldo insuficiente na empresa.');
    }

    if (messages.length === 0) return null;

    return (
      <GridContainer>
        <Grid item xs={12} sm={4}>
          <GridItemContainer>
            <Typography style={{ textDecoration: 'underline' }}>
              {messages.length > 0 ? (messages.length === 1 ? 'Mensagem' : 'Mensagens') : ''}
            </Typography>
            {messages.map((message, index) => (
              <Typography key={`${index}`}>{message}</Typography>
            ))}
          </GridItemContainer>
        </Grid>
      </GridContainer>
    );
  };

  const showMessagePix =
    !dataCalculator?.has_benefits ||
    dataCalculator?.benefits_quota_exceeded ||
    (dataCalculator?.surcharge || 0) > 0 ||
    false;

  return (
    <Content>
      <Container>
        <Title>Endereço</Title>
      </Container>

      {(!calcStatus || calcStatus === CalcStatus.ERROR) && (
        <Container>
          <LocationSearchInput
            label='De onde?'
            setLatLng={handleOrigin}
            fnSetAddress={setAddressOrigin}
            fnOnChange={handleOnChange}
          />

          {service?.displace && (
            <LocationSearchInput
              label='Para onde?'
              setLatLng={handleDestination}
              fnSetAddress={setAddressDestination}
              fnOnChange={handleOnChange}
            />
          )}
        </Container>
      )}

      {currentLatLng?.latitude && currentLatLng?.longitude && (
        <div style={{ height: '50vh', width: '100%' }} id='map'>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY ?? '' }}
            defaultCenter={{
              lat: currentLatLng.latitude,
              lng: currentLatLng.longitude,
            }}
            defaultZoom={16}
            center={positionCenter}
            zoom={zoomPosition}
            onGoogleApiLoaded={({ map }) => {
              setMap(map);
            }}
            yesIWantToUseGoogleMapApiInternals
          >
            {origin && <Pin lat={origin.latitude} lng={origin.longitude} />}
            {destination && <Pin lat={destination.latitude} lng={destination.longitude} />}
          </GoogleMapReact>
        </div>
      )}

      {(!calcStatus || calcStatus === CalcStatus.ERROR) && (
        <SimpleBottomNavigation>
          <SimpleBottomAction
            label='Voltar'
            icon={ChevronLeft}
            onClick={history.goBack}
            loading={loading}
            disabled={loading}
          />
          <SimpleBottomAction
            label='Cancelar'
            icon={Cancel}
            onClick={handleCancel}
            loading={loading}
            disabled={loading}
          />
          <SimpleBottomAction
            label='Avançar'
            icon={ChevronRight}
            onClick={confirmLocations}
            loading={loading}
            disabled={loading}
          />
        </SimpleBottomNavigation>
      )}

      {(calcStatus === CalcStatus.NEW ||
        calcStatus === CalcStatus.CALCULATING ||
        calcStatus === CalcStatus.CALCULATED ||
        calcStatus === CalcStatus.SEARCH) && (
        <div>
          {calcStatus === CalcStatus.SEARCH ? (
            <div>
              <Loading />
              <Typography align='center'>{statusMessage()}</Typography>
              <Typography align='center'>{showPixCode(showMessagePix)}</Typography>
            </div>
          ) : (
            calcStatus !== CalcStatus.NEW &&
            calcStatus !== CalcStatus.CALCULATING && (
              <>
                {dataCalculator?.has_benefits && !dataCalculator?.benefits_quota_exceeded ? (
                  <>
                    <Typography align='center'>
                      {service?.displace
                        ? `Sua rota é de ${((mapLinkData?.solution?.totalDistance ?? 0) / 1000).toFixed(0)} km${
                            dataCalculator?.surcharge
                              ? `, porém excedeu em ${
                                  valueOverDistance?.amount?.toFixed(0) ?? 0
                                } km o benefício contratado`
                              : ''
                          }`
                        : 'Solicitação aprovada'}
                    </Typography>

                    {dataCalculator?.surcharge ? (
                      <>
                        {descriptionAndValue}

                        {reasonsMessages()}

                        <SimpleBottomNavigation>
                          <SimpleBottomAction
                            label='Alterar endereço'
                            icon={Loop}
                            onClick={cancel}
                            loading={loading}
                            disabled={loading}
                          />
                          {paymentsType && (
                            <SimpleBottomAction
                              label={paymentsType[1]}
                              icon={AttachMoney}
                              onClick={handleSubmit}
                              loading={loading}
                              disabled={loading}
                            />
                          )}
                        </SimpleBottomNavigation>
                      </>
                    ) : (
                      <>
                        {reasonsMessages()}

                        <SimpleBottomNavigation>
                          <SimpleBottomAction
                            label='Alterar endereço'
                            icon={Loop}
                            onClick={cancel}
                            loading={loading}
                            disabled={loading}
                          />

                          <SimpleBottomAction
                            label='Solicitar Serviço'
                            icon={ChevronRight}
                            onClick={() => confirmSolicitation('VOUCHER')}
                            loading={loading}
                            disabled={loading}
                          />
                        </SimpleBottomNavigation>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Typography align='center'>
                      {service?.displace
                        ? `Sua rota é de ${((mapLinkData?.solution?.totalDistance ?? 0) / 1000).toFixed(0)} km`
                        : 'Solicitação aprovada, abaixo o valor para ser executado'}
                    </Typography>

                    {/* Encontramos sua rota, abaixo o valor para executar o serviço */}
                    {descriptionAndValue}

                    {reasonsMessages()}

                    <SimpleBottomNavigation>
                      <SimpleBottomAction
                        label='Alterar endereço'
                        icon={Loop}
                        onClick={cancel}
                        loading={loading}
                        disabled={loading}
                      />
                      {paymentsType && (
                        <SimpleBottomAction
                          label={paymentsType[1]}
                          icon={AttachMoney}
                          onClick={handleSubmit}
                          loading={loading}
                          disabled={loading}
                        />
                      )}
                    </SimpleBottomNavigation>
                  </>
                )}
              </>
            )
          )}
        </div>
      )}

      <ModalCancel
        onConfirm={handleConfirmCancel}
        onCancel={() => {
          setModalCancel(false);
        }}
        open={modalCancel}
      />

      <Modal onConfirm={() => setOpenModal(false)} open={openModal}>
        {message}
      </Modal>

      <Modal
        question
        onConfirm={handlePhoneNumber}
        onCancel={() => {
          setOpenModalPhoneNumber(false);

          setContactPhoneNumber('');
        }}
        open={openModalPhoneNumber}
        buttonPositive='Confirmar'
      >
        <div className={classes.container}>
          <Typography align='center'>Informe um telefone, para o prestador de serviço entrar em contato</Typography>
          <InputComponent
            type='text'
            onChange={e => setContactPhoneNumber(formatCellPhone(removeFormat(e.target.value)))}
            value={contactPhoneNumber}
            ref={refPhone}
            inputProps={{ maxLength: 14 }}
          />
        </div>
      </Modal>

      <Modal
        open={!!paymentError}
        onConfirm={() => {
          setForcePickTow(true);
          setPaymentError(undefined);
          confirmLocations();
        }}
        onCancel={() => {
          setPaymentError(undefined);
          setCalcStatus(CalcStatus.NEW);
        }}
      >
        <div>
          <Typography>{paymentError}</Typography>
        </div>
      </Modal>

      <Modal
        open={modalSuccess}
        onConfirm={handleCloseModalSuccess}
        onCancel={handleCloseModalSuccess}
        hasContent={false}
      >
        <ModalSuccessContainer>
          <ModalSuccessTop fullscreen={fullscreen}>
            <ModalSuccessCard>
              <Typography align='center'>
                Solicitação Realizada com sucesso, aguarde o contato do prestador. Na próxima solicitação você pode usar{' '}
                <Link to={{ pathname: 'https://qrco.de/picktow' }} target='_blank' style={{ color: Colors.primary }}>
                  <Typography style={{ fontStyle: 'italic', fontWeight: 'bold' }}>aplicativo da Picktow.</Typography>
                </Link>
              </Typography>
              <Divider height={24} />

              <p style={{ fontSize: '1rem', textAlign: 'center' }}>
                Para cancelar
                <Divider width={4} />
              </p>

              <ModalSuccessLink href='https://wa.me/message/DPTHI4ZOQERSC1' target='_blank' rel='noopener noreferrer'>
                fale com a Picktow!
                <img src={WhatsAppIcon} alt='WhatsApp' />
              </ModalSuccessLink>
            </ModalSuccessCard>
          </ModalSuccessTop>

          <ModalSuccessBottom fullscreen={fullscreen}>
            <Typography style={{ color: Colors.white, fontWeight: 'bold' }}>
              SOLICITAÇÃO REALIZADA COM SUCESSO
            </Typography>
          </ModalSuccessBottom>
        </ModalSuccessContainer>
      </Modal>
    </Content>
  );
};

export default OpportunityMap;
