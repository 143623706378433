import styled from 'styled-components';
import Colors from '~/styles/colors';

export const DivContainer = styled.div`
  min-height: 100vh;
  background: ${Colors.lightGreyNew};

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

export const Div = styled.div`
  max-width: 360px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  /* align-items: center; */

  form {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
