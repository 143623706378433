import { combineReducers } from 'redux';

import auth from './auth';
import order from './order';
import customerBenefit from './customerBenefit';
import { associationBalance } from './associationBalance';
import { snapshot } from './snapShot';

const reducers = combineReducers({
  auth,
  order,
  customerBenefit,
  associationBalance,
  snapshot,
});

export default reducers;
