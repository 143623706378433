import styled from 'styled-components';
import { Typography } from '@material-ui/core';

interface LabelProps {
  data?: {
    fontSize?: number;
    textAlign?: string;
    fontWeight?: string;
  };
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 85vh;
`;

export const ViewContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ViewButton = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
`;

export const Label = styled(Typography)<LabelProps>`
  ${({ data }) => (data?.fontSize ? `font-size: ${data.fontSize}px;` : '')}
  ${({ data }) => (data?.textAlign ? `text-align: ${data.textAlign};` : '')}
  ${({ data }) => (data?.fontWeight ? `font-weight: ${data.fontWeight};` : '')}
  display: inline;
`;
