import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { loadAssociationBalance } from '../api';
import { handleApiErrorResponse } from '../handleErrors';
import { useToast } from './toast';
import { clearBalance, setBalance, setBalanceError } from '~/store/ducks/associationBalance';

export interface AssociationBalance {
  account_statement?: {
    available_balance: number;
  };
}

export function useAssociationBalance(associationId?: string) {
  const { addToast } = useToast();
  const dispatch = useDispatch();

  const getAssociationBalance = useCallback(async () => {
    if (!associationId) {
      dispatch(clearBalance());
      return;
    }

    try {
      const { data } = await loadAssociationBalance(associationId);
      dispatch(setBalance(data));
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o saldo');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
      dispatch(clearBalance());
      dispatch(
        setBalanceError(
          errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        ),
      );
    }
  }, [addToast, associationId, dispatch]);

  useEffect(() => {
    getAssociationBalance();
  }, [getAssociationBalance]);
}

export default useAssociationBalance;
