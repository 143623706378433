import styled from 'styled-components';
import { Star as StarBase, StarBorder as StarBorderBase } from '@material-ui/icons';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 12px;
  justify-content: space-evenly;
`;

export const Star = styled(StarBase)`
  font-size: 40px;
  color: goldenrod;
`;

export const StarBorder = styled(StarBorderBase)`
  font-size: 40px;
  color: goldenrod;
`;
