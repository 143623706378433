import React from 'react';

import { IconButton, Phone } from './styles';

interface ButtonCallProps {
  phoneNumber: string;
  top?: number;
}

const ButtonCall = ({ phoneNumber, top }: ButtonCallProps) => {
  const handleCallContact = () => {
    document.location.href = `tel:${phoneNumber}`;
  };

  return (
    <IconButton onClick={handleCallContact} top={top}>
      <Phone />
    </IconButton>
  );
};

export default ButtonCall;
