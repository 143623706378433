import { replaceSpecialChars } from '~/util/format';

import BateriaIcon from '~/assets/svg/bateria.svg';
import ChaveiroIcon from '~/assets/svg/chaveiro.svg';
import GasolinaIcon from '~/assets/svg/gasolina.svg';
import GuinchoIcon from '~/assets/svg/guincho.svg';
import PneuIcon from '~/assets/svg/pneu.svg';

import BusIcon from '~/assets/svg/bus.svg';
import CaminhaoIcon from '~/assets/svg/caminhao.svg';
import MaquinaIcon from '~/assets/svg/maquina.svg';
import MotoIcon from '~/assets/svg/moto.svg';
import PasseioIcon from '~/assets/svg/passeio.svg';
import UtilitarioIcon from '~/assets/svg/utilitario.svg';
import VUCIcon from '~/assets/svg/vuc.svg';

const getIconService = (description: string) => {
  switch (replaceSpecialChars(description)) {
    case 'chaveiro':
      return ChaveiroIcon;

    case 'troca-de-pneu':
      return PneuIcon;

    case 'pane-seca':
      return GasolinaIcon;

    case 'pane-eletrica-bateria':
      return BateriaIcon;

    case 'reboque':
      return GuinchoIcon;

    default:
      break;
  }
};

const getIconVehicle = (description: string) => {
  switch (replaceSpecialChars(description)) {
    case 'utilitario':
      return UtilitarioIcon;

    case 'passeio':
      return PasseioIcon;

    case 'motocicleta':
      return MotoIcon;

    case 'caminhao':
      return CaminhaoIcon;

    case 'onibus':
      return BusIcon;

    case 'vuc':
      return VUCIcon;

    case 'maquinas':
      return MaquinaIcon;

    default:
      return GuinchoIcon;
  }
};

export { getIconService, getIconVehicle };
