import React from 'react';
import {
  Dialog,
  useTheme,
  useMediaQuery,
  DialogActions,
  Button,
  DialogProps,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

interface CancelConfirmProps extends DialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
  },
}));

const ModalCancel = ({ onConfirm, onCancel, ...props }: CancelConfirmProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog maxWidth='xl' fullScreen={fullscreen} disableScrollLock={true} onClose={_ => onCancel()} {...props}>
      <div className={classes.container}>
        <DialogTitle className={classes.title}>Tem certeza de que deseja cancelar essa solicitação?</DialogTitle>

        <DialogActions>
          <Button
            autoFocus
            variant='contained'
            color='secondary'
            style={{ textTransform: 'none' }}
            onClick={_ => onCancel()}
          >
            Cancelar
          </Button>
          <Button
            autoFocus
            variant='contained'
            color='secondary'
            style={{ textTransform: 'none' }}
            onClick={_ => onConfirm()}
          >
            Confirmar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ModalCancel;
