import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';

import { Container, Star, StarBorder } from './styles';

interface EvaluationProps {
  value: number;
  onValue: (value: number) => void;
}

interface StarButtonProps extends ButtonProps {
  fill: boolean;
}

export function Evaluation({ value, onValue }: EvaluationProps) {
  return (
    <Container>
      <StarButton fill={value >= 1} onClick={() => onValue(1)} />
      <StarButton fill={value >= 2} onClick={() => onValue(2)} />
      <StarButton fill={value >= 3} onClick={() => onValue(3)} />
      <StarButton fill={value >= 4} onClick={() => onValue(4)} />
      <StarButton fill={value >= 5} onClick={() => onValue(5)} />
    </Container>
  );
}

function StarButton({ fill, ...props }: StarButtonProps) {
  return <Button {...props}>{fill ? <Star /> : <StarBorder />}</Button>;
}

export default Evaluation;
