import React, { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField } from '@material-ui/core';

import { Container, Label, Spacer, ViewButton } from './styles';
import Evaluation from '~/components/Evaluation';
import { createSurveyContractorApi } from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useFirestore } from '~/services/hooks/useFirestore';
import { useToast } from '~/services/hooks/toast';
import { OrderFirestore } from '~/types';
import Button from '~/components/Button';
import Colors from '~/styles/colors';

const ContractorEvaluation = () => {
  const [rating, setRating] = useState(5);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);

  const { orderId } = (useLocation().state || {}) as { orderId?: string };
  const { snapshot: order, setDocument, getDocument, removeDocument } = useFirestore<OrderFirestore>(
    'orders',
    orderId,
    false,
  );
  const { addToast } = useToast();
  const history = useHistory();

  const onSubmit = useCallback(async () => {
    if (!order) {
      return;
    }

    if (rating <= 3 && feedback.trim().length === 0) {
      return addToast({
        title: 'O feedback é obrigatório',
        type: 'error',
      });
    }

    setLoading(true);

    try {
      await createSurveyContractorApi({
        order_id: orderId,
        social_security: order.contractor_id,
        feedback,
        rating,
      });

      if (!orderId) {
        return history.push('/');
      }

      await setDocument('orders', orderId, { customer_survey: true }, { merge: true });

      const document = await getDocument('orders', orderId);

      if (document && document.customer_survey && document.contractor_survey) {
        await removeDocument('orders', orderId);
      }

      history.push('/');
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao avaliar, tente novamente');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, feedback, getDocument, history, order, orderId, rating, removeDocument, setDocument]);

  return (
    <Container>
      <Spacer flex={2} />

      <Label fontWeight='bold' fontSize={24}>
        AVALIE O PRESTADOR
      </Label>

      <Spacer />

      <Evaluation value={rating} onValue={setRating} />

      <Spacer />

      <TextField
        id='outlined-multiline-static'
        label='DÊ SEU FEEDBACK'
        multiline
        rows={4}
        // defaultValue='Default Value'
        value={feedback}
        onChange={event => setFeedback(event.target.value)}
        variant='outlined'
      />

      <Spacer />

      <Label>{'SUA AVALIAÇÃO É IMPORTANTE\nPARA MANTER A QUALIDADE\nDOS SERVIÇOS.'}</Label>

      <Spacer />

      <ViewButton>
        <Button
          type='button'
          backgroundColor={Colors.primary}
          color={Colors.white}
          label='OK'
          onClick={onSubmit}
          width='100%'
          loading={loading}
        />
      </ViewButton>

      <Spacer flex={2} />
    </Container>
  );
};

export default ContractorEvaluation;
