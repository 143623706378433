import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Card,
  CardContent,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';

import { getIconVehicle } from '~/util/getIcon';

import { useCustomerVehicles } from '~/services/hooks/useCustomerVehicles';
import { useToast } from '~/services/hooks/toast';
import { useQuizAssociation } from '~/services/hooks/useQuizAssociation';

import { setOrderQuizAnswers, setOrderVehicle } from '~/store/ducks/order';

import Title from '../../components/Title';
import ModalCancel from '../../components/ModalCancel';

import { CustomerVehicle, CustomerVehicleRequest } from '~/types';
import { AddCircleOutline, Cancel, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { FipeModel, VehicleRegistration, VehicleInitialData, VehicleStatus } from './components/VehicleRegistration';
import { createCustomerVehicleApi } from '~/services/api';
import Modal from '~/components/ModalNewVehicle';
import SimpleBottomNavigation from '~/components/SimpleBottomNavigation';
import SimpleBottomAction from '~/components/SimpleBottomAction';

const OpportunityVehicles = () => {
  const [selectedVehicle, setSelectedVehicle] = useState<CustomerVehicle>();
  const [modalCancel, setModalCancel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalVehicleIsOpen, setModalVehicleIsOpen] = useState(false);
  const [fipeModel, setFipeModel] = useState<FipeModel>({} as FipeModel);
  const [vehicleInitialData, setVehicleInitialData] = useState<VehicleInitialData>();
  const [newVehicleId, setNewVehicleId] = useState<string>();

  const { addToast } = useToast();
  const history = useHistory();
  const dispatch = useDispatch();

  const { customerId, service, vehicle } = useSelector(state => ({
    service: state.order.service,
    vehicle: state.order.vehicle,
    customerId: state.auth.user['cognito:username'],
  }));

  const { customerVehicles, loadingVehicles, getVehicleBySocialSecurity } = useCustomerVehicles(customerId);
  const { getQuiz, loadQuiz } = useQuizAssociation();

  useEffect(() => {
    setSelectedVehicle(vehicle);
  }, [vehicle]);

  useEffect(() => {
    if (!service?.id) {
      history.push('/new-opportunity/services');
    }
  }, [history, service]);

  const handleSubmit = useCallback(
    async (vehicle?: CustomerVehicleRequest) => {
      if (!vehicle?.id) {
        addToast({ title: 'Validação', type: 'error', description: 'Veículo obrigatório!' });
        return;
      }

      dispatch(setOrderVehicle(vehicle));

      const quiz = await getQuiz(vehicle.association_id, service?.id);

      if (quiz && quiz.length > 0) {
        history.push('/new-opportunity/additional-information');
      } else {
        if (vehicle.association_code) {
          dispatch(setOrderQuizAnswers(false, []));
        } else {
          dispatch(setOrderQuizAnswers(true, []));
        }
        history.push('/new-opportunity/map');
      }
    },
    [addToast, dispatch, getQuiz, history, service],
  );

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCancel = useCallback(() => {
    setModalCancel(true);
  }, []);

  const handleConfirmCancel = useCallback(async () => {
    history.push('/new-opportunity');
  }, [history]);

  const selectInactiveVehicle = useCallback(async () => {
    if (!selectedVehicle) {
      addToast({ title: 'Validação', type: 'error', description: 'Veículo obrigatório!' });
      return;
    }

    dispatch(setOrderVehicle(selectedVehicle));

    const quiz = await getQuiz(undefined, service?.id);

    if (quiz && quiz.length > 0) {
      history.push('/new-opportunity/additional-information');
    } else {
      dispatch(setOrderQuizAnswers(false, {}));
      history.push('/new-opportunity/map');
    }
  }, [addToast, dispatch, getQuiz, history, selectedVehicle, service]);

  useEffect(() => {
    if (!newVehicleId) {
      return;
    }

    const vehicleFound = customerVehicles.find(vehicle => vehicle.id === newVehicleId);

    if (!vehicleFound) {
      return;
    }

    setSelectedVehicle(vehicleFound);
    handleSubmit(vehicleFound);
  }, [customerVehicles, handleSubmit, newVehicleId]);

  const handleListItemClick = useCallback((item: CustomerVehicle) => {
    setSelectedVehicle(item);

    if (item.status === 'INATIVO') {
      setOpenModal(true);
      return;
    }
  }, []);

  const handleCancelInactive = useCallback(() => {
    setSelectedVehicle(undefined);
    setOpenModal(false);
  }, []);

  const handleRegisterVehicle = async () => {
    const errors: FipeModel = {};

    if (!fipeModel.category?.description.trim()) {
      errors.category_error = true;
    }

    if (!fipeModel.manufacturer?.name.trim()) {
      errors.manufacturer_error = true;
    }

    if (!fipeModel.vehicle?.name.trim()) {
      errors.vehicle_error = true;
    }

    if (!fipeModel.model?.name.trim()) {
      errors.model_error = true;
    }

    if ((fipeModel.license_plate?.length || 0) < 7) {
      errors.license_plate_error = true;
    }

    if (!fipeModel.color?.trim()) {
      errors.color_error = true;
    }

    if (!fipeModel.city?.trim()) {
      errors.city_error = true;
    }

    if (!fipeModel.state?.trim()) {
      errors.state_error = true;
    }

    setFipeModel({ ...fipeModel, ...errors });

    if (Object.keys(errors).length !== 0) {
      return;
    }

    const fipe_data = {
      model_id: fipeModel.vehicle?.id,
      manufacture_id: fipeModel.manufacturer?.id,
      model_year_id: fipeModel.model?.id,
    };

    const data = {
      social_security: customerId,
      vehicle_customer_type_id: fipeModel.category?.id,
      license_plate: fipeModel.license_plate,
      manufacture: fipeModel.manufacturer?.name.toUpperCase(),
      model: fipeModel.vehicle?.name.toUpperCase(),
      model_year: fipeModel.model?.name,
      color: fipeModel.color,
      vin: fipeModel.vin || `${customerId}${fipeModel.license_plate?.replace(/-/g, '')}`,
      state: fipeModel.state,
      city: fipeModel.city,
      status: VehicleStatus.ACTIVE,
      fipe_data: JSON.stringify(fipe_data),
    };

    try {
      const { headers } = await createCustomerVehicleApi(data);
      const location = headers?.location;
      if (location) {
        const vehicleId = location.split('/').pop();
        setNewVehicleId(vehicleId);
      }

      setModalVehicleIsOpen(false);
      await getVehicleBySocialSecurity(customerId);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleCancelModalVehicle = () => {
    setFipeModel({} as FipeModel);
    setModalVehicleIsOpen(false);
  };

  const handleNewVehicle = () => {
    setVehicleInitialData(undefined);
    setFipeModel({} as FipeModel);
    setModalVehicleIsOpen(true);
  };

  return (
    <Container>
      <Title>Veículo</Title>

      <IconButton aria-label='novo veículo' onClick={handleNewVehicle}>
        <AddCircleOutline />
        <Typography style={{ marginLeft: 8 }}>Novo veículo</Typography>
      </IconButton>

      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <List component='nav' aria-label='main mailbox folders'>
            {customerVehicles.length === 0 && !loadingVehicles
              ? 'Sem veículos cadastrados'
              : customerVehicles.map(item => (
                  <ListItem
                    key={item.id}
                    button
                    selected={selectedVehicle?.id === item.id}
                    onClick={() => handleListItemClick(item)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={item.vehicle_customer_type_description}
                        src={getIconVehicle(item.vehicle_customer_type_description)}
                        style={{ width: 52, height: 52 }}
                      />
                    </ListItemAvatar>

                    <ListItemText
                      primary={`${item.license_plate}${item.association_code ? ` (${item.association_code})` : ''}`}
                      secondary={item.model}
                    />
                  </ListItem>
                ))}
          </List>
        </CardContent>
      </Card>

      <SimpleBottomNavigation>
        <SimpleBottomAction
          label='Voltar'
          icon={ChevronLeft}
          onClick={handleGoBack}
          loading={loadingVehicles || loadQuiz}
          disabled={loadingVehicles || loadQuiz}
        />
        <SimpleBottomAction
          label='Cancelar'
          icon={Cancel}
          onClick={handleCancel}
          loading={loadingVehicles || loadQuiz}
          disabled={loadingVehicles || loadQuiz}
        />
        <SimpleBottomAction
          label='Avançar'
          icon={ChevronRight}
          onClick={() => handleSubmit(selectedVehicle)}
          loading={loadingVehicles || loadQuiz}
          disabled={loadingVehicles || loadQuiz}
        />
      </SimpleBottomNavigation>

      <ModalCancel
        onConfirm={handleConfirmCancel}
        onCancel={() => {
          setModalCancel(false);
        }}
        open={modalCancel}
      />

      <Modal onConfirm={() => selectInactiveVehicle()} onCancel={handleCancelInactive} question open={openModal}>
        <Typography>O Veículo selecionado está inativo, por isso o pedido será avulso.</Typography>
      </Modal>

      <Modal
        onConfirm={handleRegisterVehicle}
        open={modalVehicleIsOpen}
        buttonPositive='Cadastrar e avançar'
        haveCustomButton
        buttonCustom='Cancelar'
        onCustom={handleCancelModalVehicle}
      >
        <VehicleRegistration
          modalVehicleIsOpen={modalVehicleIsOpen}
          fipeModel={fipeModel}
          setFipeModel={setFipeModel}
          initialData={vehicleInitialData}
          title={vehicleInitialData ? 'Editar Veículo' : undefined}
        />
      </Modal>
    </Container>
  );
};

export default OpportunityVehicles;
