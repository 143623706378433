import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import Colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  background-color: ${Colors.green};
  padding: 24px 8px;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled(Typography)`
  color: ${Colors.white};
  font-weight: bold;
`;

export const Icon = styled(ChevronRight)`
  color: ${Colors.white};
`;
