import produce from 'immer';

export const Types = {
  CLEAR_ORDER: '@order/CLEAR_ORDER',
  SET_ORDER_INITIAL: '@order/SET_ORDER_USER',
  SET_ORDER_SERVICE: '@order/SET_ORDER_SERVICE',
  SET_ORDER_VEHICLE: '@order/SET_ORDER_VEHICLE',
  SET_ORDER_QUIZ: '@order/SET_ORDER_QUIZ',
  SET_ORDER_QUIZ_ANSWERS: '@order/SET_ORDER_QUIZ_ANSWERS',
  SET_ORDER_BENEFIT: '@order/SET_ORDER_BENEFIT',
  NEED_CLEAR: '@order/NEED_CLEAR',
};

const INITIAL_STATE = {
  customerId: '',
  customerName: '',
  uuid: '',
  service: {},
  vehicle: {},
  benefit: {},
  quizAnswers: [],
  separateService: false,
  needClear: false,
  loading: false,
  error: false,
};

export function clearOrder() {
  return { type: Types.CLEAR_ORDER };
}

export function setOrderInitial(data) {
  return { type: Types.SET_ORDER_INITIAL, payload: { data } };
}

export function setOrderService(service) {
  return { type: Types.SET_ORDER_SERVICE, payload: { service } };
}

export function setOrderVehicle(vehicle) {
  return { type: Types.SET_ORDER_VEHICLE, payload: { vehicle } };
}

export function setOrderQuizAnswers(separateService = false, quizAnswers) {
  return { type: Types.SET_ORDER_QUIZ_ANSWERS, payload: { separateService, quizAnswers } };
}

export function needClear() {
  return { type: Types.NEED_CLEAR };
}

export function setOrderBenefit(benefit) {
  return { type: Types.SET_ORDER_BENEFIT, payload: { benefit } };
}

export default function orderReducer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.CLEAR_ORDER:
        Object.entries(INITIAL_STATE).forEach(([key, val]) => (draft[key] = val));
        break;

      case Types.SET_ORDER_INITIAL:
        draft.customerId = action.payload.data.customerId;
        draft.uuid = action.payload.data.uuid;
        draft.customerName = action.payload.data.customerName;
        break;

      case Types.SET_ORDER_SERVICE:
        draft.service = action.payload.service;
        break;

      case Types.SET_ORDER_VEHICLE:
        draft.vehicle = action.payload.vehicle;
        break;

      case Types.SET_ORDER_QUIZ_ANSWERS:
        draft.separateService = action.payload.separateService;
        draft.quizAnswers = action.payload.quizAnswers;
        break;

      case Types.NEED_CLEAR:
        draft.needClear = true;
        break;

      case Types.SET_ORDER_BENEFIT:
        draft.benefit = action.payload.benefit;
        break;

      default:
        break;
    }
  });
}
