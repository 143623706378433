import React from 'react';
import { useHistory } from 'react-router-dom';

import { Title, Subtitle } from './styles';
import Button from '~/components/Button';
import { Divider } from '../NewOpportunity/styles';
import RobotImage from '~/assets/images/robot.png';
import Colors from '~/styles/colors';
import LogoPicktow from '~/assets/svg/logo_p_picktow';
import ImageHome from '~/assets/svg/vetor_login-user.svg';

export function Initial() {
  const history = useHistory();

  const handleWhatsApp = () => {
    window.open('https://wa.me/557140421162?text=Olá, sou usuário da Picktow e necessito de ajuda', '_blank');
  };

  const handleLogin = (isAssociate?: boolean) => {
    history.push('/login', { isAssociate });
  };

  const handleSignUp = () => {
    history.push('/sign-up');
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <LogoPicktow width='50' height='50' />
        <img src={ImageHome} alt='Home' style={{ width: 170, height: 170 }} />
      </div>

      <Title>Bem vindo a Picktow</Title>
      <Subtitle>Tenha agilidade e excelência!</Subtitle>

      <Button
        type='button'
        backgroundColor={Colors.primary}
        color={Colors.white}
        width='100%'
        label='MEMBRO DE ASSOCIAÇÃO'
        onClick={() => handleLogin(true)}
      />

      <Button type='button' filled label='TENHO UMA CONTA' onClick={() => handleLogin()} />

      <Button
        type='button'
        label='CRIAR UMA CONTA'
        backgroundColor={Colors.white}
        color={Colors.primary}
        onClick={() => handleSignUp()}
        border={`1px solid ${Colors.blue}`}
      />
      <Divider height={80} />

      <button style={{ backgroundColor: 'transparent', border: 0, outline: 'none' }} onClick={handleWhatsApp}>
        <img src={RobotImage} alt='Robô' width={40} height={40} style={{ borderRadius: 20 }} />
        <Subtitle>Oi, em que posso te ajudar?</Subtitle>
      </button>
    </>
  );
}
