import { put, call, takeEvery } from 'redux-saga/effects';
import { loadVehicleBenefitQuota, loadVehicleBenefits } from '~/services/api';
import { ReduxAction } from '../@types/Action';
import { BenefitState, CustomerBenefitType as ActionTypes } from '../ducks/customerBenefit';

function* loadBenefitSaga(action: ReduxAction<ActionTypes, BenefitState>) {
  try {
    const associationId = action.payload?.associationId ?? '';
    const serviceId = action.payload?.serviceId ?? '';
    const socialSecurity = action.payload?.socialSecurity ?? '';
    const vehicleId = action.payload?.vehicleId ?? '';
    const vin = action.payload?.vin ?? '';

    const benefit = yield call(loadVehicleBenefits, associationId, serviceId, socialSecurity, vehicleId);
    const benefitQuota = yield call(loadVehicleBenefitQuota, {
      benefits_date_created: benefit.data.created_at,
      customer_id: socialSecurity,
      benefits_quantity: benefit.data.quantity,
      service_id: serviceId,
      vin: vin,
    });

    yield put({
      type: ActionTypes.ON_SUCESS,
      payload: {
        vehicleBenefits: {
          benefits: { ...benefit.data, quota_expended: benefitQuota.data.quota_expended },
          id: vehicleId,
        },
        associationId: associationId,
        serviceId: serviceId,
        socialSecurity: socialSecurity,
        vehicleId: vehicleId,
        loading: false,
      },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: ActionTypes.ON_FAILURE,
      payload: {
        loading: false,
      },
    });
  }
}

export default function* benefitsSaga() {
  yield takeEvery(ActionTypes.ON_REQUEST, loadBenefitSaga);
}
