import messages from '~/config/messages';

export function handleApiErrorResponse(error, defaultMessage = messages['message-default-error']) {
  const errors = error?.response?.data ?? error?.message;

  if (!errors) {
    return;
  }

  if (errors && Array.isArray(errors)) {
    const msgTranslated = errors.map(errorItem => messages[errorItem] ?? defaultMessage);

    const uniqMessages = Array.from(new Set(msgTranslated));

    const stringErrors = uniqMessages.join('\n');

    return {
      applicationErrorMessage: stringErrors,
      apiError: '',
    };
  } else if (errors.error) {
    return {
      applicationErrorMessage: messages[errors.error] ? messages[errors.error] : defaultMessage,
      apiError: '',
    };
  } else if (errors?.messages && Array.isArray(errors?.messages)) {
    const errorsMessages = errors?.messages;

    const msgTranslated = errorsMessages.map(errorItem => messages[errorItem] ?? defaultMessage);

    const uniqMessages = Array.from(new Set(msgTranslated));

    const stringErrors = uniqMessages.join('\n');

    return {
      applicationErrorMessage: stringErrors,
      apiError: '',
    };
  }

  return {
    applicationErrorMessage: error.message,
    apiError: errors.errorDescription || errors.data,
    apiResponseStatus: error.response && error.response.status,
    handledMessage: messages[errors] ?? defaultMessage,
  };
}
