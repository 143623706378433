import * as React from 'react';
import { SVGProps } from 'react';

const LogoPicktow = ({ fill, ...rest }: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={140} height={160} viewBox='0 0 140 160' xmlSpace='preserve' {...rest}>
    <g transform='matrix(.6 0 0 -.6 71.79 92.45)' clipPath='url(#a)'>
      <clipPath id='a'>
        <path transform='translate(-497.39 -594.65)' d='M0 1080h1980V0H0Z' strokeLinecap='round' />
      </clipPath>
      <path
        style={{
          stroke: 'none',
          strokeWidth: 1,
          strokeDasharray: 'none',
          strokeLinecap: 'butt',
          strokeDashoffset: 0,
          strokeLinejoin: 'miter',
          strokeMiterlimit: 4,
          fill: fill || '#2e397b',
          fillRule: 'nonzero',
          opacity: 1,
        }}
        vectorEffect='non-scaling-stroke'
        transform='translate(7.96 37.67)'
        d='M0 0h27.622C47.13 0 62.997 15.866 62.997 35.372c0 19.499-15.867 35.369-35.379 35.369l-106.534-.039 18.628-16.653 87.91.052c10.327 0 18.731-8.404 18.731-18.729 0-10.328-8.404-18.722-18.731-18.722h-44.269l.016-128.471-24.62 24.624v103.86h-16.647V-94.1l57.92-51.977z'
      />
    </g>
    <g transform='matrix(.6 0 0 -.6 70.35 48.58)' clipPath='url(#b)'>
      <clipPath id='b'>
        <path transform='translate(-494.99 -667.75)' d='M0 1080h1980V0H0Z' strokeLinecap='round' />
      </clipPath>
      <path
        style={{
          stroke: 'none',
          strokeWidth: 1,
          strokeDasharray: 'none',
          strokeLinecap: 'butt',
          strokeDashoffset: 0,
          strokeLinejoin: 'miter',
          strokeMiterlimit: 4,
          fill: fill || '#2e397b',
          fillRule: 'nonzero',
          opacity: 1,
        }}
        vectorEffect='non-scaling-stroke'
        transform='translate(37.98 76.54)'
        d='M0 0h-152.555l18.609-16.673L0-16.647c33.057 0 59.954-26.897 59.954-59.951 0-31.565-24.601-57.802-56.05-59.828H-.285v-16.647h4.448l.502.013c40.338 2.441 71.936 36.026 71.936 76.462C76.601-34.365 42.239 0 0 0'
      />
    </g>
  </svg>
);

export default LogoPicktow;
