import React from 'react';
import { Switch } from 'react-router-dom';

import RouterWrapper, { RouterWrapperProps } from './Route';

import Login from '~/pages/Login';
import ForgotPassword from '~/pages/ForgotPassword';
import ForgotPasswordCode from '~/pages/ForgotPasswordCode';
import { ChangePassword } from '~/pages/ChangePassword';
import NotFound from '~/pages/NotFound';
import OpportunityServices from '~/pages/NewOpportunity/pages/OpportunityServices';
import OpportunityVehicles from '~/pages/NewOpportunity/pages/OpportunityVehicles';
import OpportunityAdditionalInformation from '~/pages/NewOpportunity/pages/OpportunityAdditionalInformation';
import OpportunityMap from '~/pages/NewOpportunity/pages/OpportunityMap';
import SetEmail from '~/pages/SetEmail';
import { useSelector } from 'react-redux';
import SetEmailConfirm from '~/pages/SetEmailConfirm';
import EmailRequired from '~/pages/EmailRequired';
import ConfirmSignUp from '~/pages/ConfirmSignUp';
import { Initial } from '~/pages/Initial';
import UpdateOrder from '~/pages/UpdateOrder';
import WaitingSearchingContractor from '~/pages/WaitingSearchingContractor';
import RequestCanceledByCustomer from '~/pages/RequestCanceledByCustomer';
import Tracking from '~/pages/Tracking';
import OrdersInExecution from '~/pages/OrdersInExecution';
import StatusOrder from '~/pages/StatusOrder';
import WaitingContractorPictures from '~/pages/WaitingContractorPictures';
import ContractorEvaluation from '~/pages/ContractorEvaluation';
import RouteConfirmation from '~/pages/RouteConfirmation';
import Pix from '~/pages/Pix';
import RequestCanceled from '~/pages/RequestCanceled';
import SignUp from '~/pages/SignUp';

const loginRoutes: RouterWrapperProps[] = [
  {
    path: '/',
    component: Initial,
    exact: true,
    layout: 'LOGIN',
  },
  {
    path: '/login',
    component: Login,
    exact: true,
    layout: 'LOGIN',
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/forgot-confirmation',
    component: ForgotPasswordCode,
  },
  {
    path: '/sign-up',
    component: SignUp,
    marginTop: 24,
  },
];

const loggedRoutes: RouterWrapperProps[] = [
  {
    path: '/',
    component: OpportunityServices,
    exact: true,
    showFirebaseOrder: false,
  },
  {
    path: '/login',
    component: OpportunityServices,
    exact: true,
    showFirebaseOrder: false,
  },
  {
    path: '/new-opportunity',
    component: OpportunityServices,
    exact: true,
    showFirebaseOrder: false,
  },
  {
    path: '/new-opportunity/services',
    component: OpportunityServices,
    exact: true,
    showFirebaseOrder: false,
  },
  {
    path: '/new-opportunity/vehicles',
    component: OpportunityVehicles,
    exact: true,
  },
  {
    path: '/new-opportunity/additional-information',
    component: OpportunityAdditionalInformation,
    exact: true,
  },
  {
    path: '/new-opportunity/map',
    component: OpportunityMap,
    exact: true,
  },
  {
    path: '/change-password',
    component: ChangePassword,
  },
  {
    path: '/update-order',
    component: UpdateOrder,
  },
  {
    path: '/waiting-searching-contractor',
    component: WaitingSearchingContractor,
  },
  {
    path: '/request-canceled-by-customer',
    component: RequestCanceledByCustomer,
  },
  {
    path: '/tracking',
    component: Tracking,
  },
  {
    path: '/orders-in-execution',
    component: OrdersInExecution,
    showFirebaseOrder: false,
  },
  {
    path: '/status-order',
    component: StatusOrder,
  },
  {
    path: '/waiting-contractor-pictures',
    component: WaitingContractorPictures,
  },
  {
    path: '/contractor-evaluation',
    component: ContractorEvaluation,
  },
  {
    path: '/route-confirmation',
    component: RouteConfirmation,
  },
  {
    path: '/pix',
    component: Pix,
  },
  {
    path: '/request-canceled',
    component: RequestCanceled,
  },
];

const requireEmailRoute: RouterWrapperProps[] = [
  {
    path: '/',
    component: SetEmail,
    exact: true,
    layout: 'AUTH',
  },
  {
    path: '/login',
    component: SetEmail,
    exact: true,
    layout: 'AUTH',
  },
  {
    path: '/set-email',
    component: SetEmail,
    exact: true,
    layout: 'AUTH',
  },
  {
    path: '/set-email/confirmation',
    component: SetEmailConfirm,
    exact: true,
    layout: 'AUTH',
  },
];

const requireValidationRoute: RouterWrapperProps[] = [
  {
    path: '/',
    component: EmailRequired,
    exact: true,
    layout: 'AUTH',
  },
  {
    path: '/login',
    component: EmailRequired,
    exact: true,
    layout: 'AUTH',
  },
];

const confirmSignUp: RouterWrapperProps[] = [
  {
    path: '/',
    component: ConfirmSignUp,
    exact: true,
  },
  {
    path: '/login',
    component: ConfirmSignUp,
    exact: true,
  },
];

export default function Routes() {
  const {
    authenticated,
    emailValidationRequired,
    phoneNumberValidationRequired,
    email,
    phoneNumber,
    confirmCodeSignUp,
  } = useSelector(state => ({
    authenticated: state.auth.authorization.authenticated,
    emailValidationRequired: state.auth.authorization.email_validation_required,
    phoneNumberValidationRequired: state.auth.authorization.phone_number_validation_required,
    email: state.auth.user.email,
    phoneNumber: state.auth.user.phone_number,
    confirmCodeSignUp: state.auth.authorization.confirmCodeSignUp,
  }));

  return (
    <Switch>
      {!confirmCodeSignUp && !authenticated
        ? loginRoutes.map((route, index) => (
            <RouterWrapper
              key={`${index}`}
              exact={route.exact}
              path={route.path}
              component={route.component}
              layout={route.layout}
              marginTop={route.marginTop}
            />
          ))
        : (emailValidationRequired && !email && phoneNumberValidationRequired && !phoneNumber) ||
          (emailValidationRequired && phoneNumberValidationRequired && confirmCodeSignUp)
        ? requireEmailRoute.map((route, index) => (
            <RouterWrapper
              key={`${index}`}
              exact={route.exact}
              path={route.path}
              component={route.component}
              layout={route.layout}
            />
          ))
        : emailValidationRequired && phoneNumberValidationRequired
        ? requireValidationRoute.map((route, index) => (
            <RouterWrapper
              key={`${index}`}
              exact={route.exact}
              path={route.path}
              component={route.component}
              layout={route.layout}
            />
          ))
        : confirmCodeSignUp
        ? confirmSignUp.map((route, index) => (
            <RouterWrapper key={`${index}`} exact={route.exact} path={route.path} component={route.component} />
          ))
        : loggedRoutes.map((route, index) => (
            <RouterWrapper
              key={`${index}`}
              exact={route.exact}
              path={route.path}
              component={route.component}
              showFirebaseOrder={route.showFirebaseOrder}
            />
          ))}

      <RouterWrapper path='*' component={NotFound} />
    </Switch>
  );
}
