import { useCallback, useEffect, useState } from 'react';
import { getIncomingByContractorIdApi } from '~/services/api';

interface FetchIncomingContractor {
  name: string;
  phone_number: string;
  picture: string;
  vehicle_model: string;
  vehicle_color: string;
  vehicle_license_plate: string;
}

interface UseContractor {
  data?: FetchIncomingContractor;
  error?: unknown;
  loading?: boolean;
  load: (value: string) => void;
  refresh: () => void;
}

export function useContractor(contractorId: string): UseContractor {
  const [id, setId] = useState(contractorId);
  const [data, setData] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const loadContractor = useCallback(async () => {
    if (!id) {
      return;
    }

    setLoading(true);

    try {
      const { data } = await getIncomingByContractorIdApi(id);
      setData(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    loadContractor();
  }, [loadContractor]);

  const refresh = useCallback(() => {
    loadContractor();
  }, [loadContractor]);

  const load = useCallback(
    (newId: string) => {
      if (newId && newId !== id) {
        setId(newId);
      }
    },
    [id],
  );

  return {
    data,
    loading,
    error,
    load,
    refresh,
  };
}

export default useContractor;
