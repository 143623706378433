import styled from 'styled-components';

import Colors from '~/styles/colors';

export const Title = styled.h1`
  font-size: 1rem;
  margin-bottom: 16px;
  color: ${Colors.primary};
`;

export const Subtitle = styled.h1`
  font-size: 0.7rem;
  margin-bottom: 10px;
  color: ${Colors.primary};
`;
