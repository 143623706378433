import styled from 'styled-components';
import { NavLink as BaseLink } from 'react-router-dom';

import Colors from '~/styles/colors';

export const Link = styled(BaseLink).attrs({
  activeStyle: {
    color: Colors.blue,
  },
})`
  display: flex;
  padding: 0 10px;
  align-items: center;
  color: ${Colors.primary};

  &:hover {
    color: ${Colors.blue};

    svg {
      color: ${Colors.blue};
    }
  }

  &.active {
    svg {
      color: ${Colors.blue};
    }
  }
  svg {
    color: ${Colors.primary};
  }
`;

export const Button = styled.a`
  display: flex;
  padding: 0 10px;
  align-items: center;
  color: ${Colors.primary};
  cursor: pointer;

  &:hover {
    color: ${Colors.blue};

    svg {
      color: ${Colors.blue};
    }
  }

  svg {
    color: ${Colors.primary};
  }
`;
