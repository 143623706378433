import styled from 'styled-components';
import { AppBar as BaseAppBar } from '@material-ui/core';

export const AppBar = styled(BaseAppBar)``;

export const BoxLogo = styled.div`
  flex-grow: 1;

  img {
    height: 40px;
  }
`;

export const Header = styled.header`
  width: 100%;
  grid-area: 1 / 1 / 1 / 3;

  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

  img {
    height: 40px;
  }
`;

export const Main = styled.main`
  /* padding: 20px; */
`;
