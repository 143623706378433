import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import GoogleMapReact, { fitBounds } from 'google-map-react';

import { Container, Contractor, Label, ViewUserData, ViewUser, ProfileImage } from './styles';
import ButtonCancel from '~/components/ButtonCancel';
import ModalCancel from '~/components/ModalCancel';

import { handleApiErrorResponse } from '~/services/handleErrors';

import { ContractorsFirestore, LatLng, OrderFirestore } from '~/types';
import { formatCellPhone, removeFormat, splitWords } from '~/util/format';
import Loading from '~/components/Loading';
import { WORDS } from '~/config/constants';
import { useFirestore } from '~/services/hooks/useFirestore';
import { useToast } from '~/services/hooks/toast';
import { Pin } from '../NewOpportunity/pages/OpportunityMap/styles';
import useContractor from '~/services/hooks/useContractor';
import Colors from '~/styles/colors';
import ButtonCall from '~/components/ButtonCall';

const Tracking = () => {
  const history = useHistory();
  const state = useLocation().state as {
    deviceId: string;
    contractorId: string;
    orderId: string;
    serviceDisplace: boolean;
    destination: {
      latitude: number;
      longitude: number;
    };
  };

  const { addToast } = useToast();

  const [positionCenter, setPositionCenter] = useState<GoogleMapReact.Coords>();

  const [contractorPhoneNumber, setContractorPhoneNumber] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [currentLatLng, setCurrentLatLng] = useState<LatLng>();
  const [zoomPosition, setZoomPosition] = useState(16);

  const orderId = state.orderId;
  const serviceDisplace = state.serviceDisplace;
  const destination = state.destination;

  const contractorPosition = useFirestore<ContractorsFirestore>('contractors', state.deviceId).snapshot;
  const orderData = useFirestore<OrderFirestore>('orders', orderId).snapshot;
  const orderStatus = orderData?.status;

  const contractorCoords = useMemo(
    () =>
      contractorPosition?.d?.coordinates?._lat && contractorPosition?.d?.coordinates?._long
        ? {
            latitude: contractorPosition.d.coordinates._lat,
            longitude: contractorPosition.d.coordinates._long,
          }
        : undefined,
    [contractorPosition],
  );

  const dispatch = useDispatch();
  const contractor = useContractor(state.contractorId);

  useEffect(() => {
    if (contractorCoords && !destination) {
      setPositionCenter({ lat: contractorCoords.latitude, lng: contractorCoords.longitude });
      return;
    }

    if (!contractorCoords && destination) {
      setPositionCenter({ lat: destination.latitude, lng: destination.longitude });
      return;
    }

    if (!contractorCoords || !destination) {
      return;
    }

    const latitudes = [contractorCoords.latitude, destination.latitude];
    const longitudes = [contractorCoords.longitude, destination.longitude];

    const bounds = {
      ne: {
        lat: Math.min(...latitudes),
        lng: Math.max(...longitudes),
      },
      sw: {
        lat: Math.max(...latitudes),
        lng: Math.min(...longitudes),
      },
    };

    const size = {
      width: 300, // Map width in pixels
      height: 600, // Map height in pixels
    };

    const { center, zoom } = fitBounds(bounds, size);

    setPositionCenter(center);
    setZoomPosition(zoom);
  }, [contractorCoords, destination]);

  useEffect(() => {
    switch (orderStatus) {
      case 'CANCELED':
        history.replace('/request-canceled', {});
        break;

      case 'ALLOCATING':
      case 'MANUAL_ALLOCATION':
        history.replace('/waiting-searching-contractor', {
          orderId,
        });
        break;

      default:
        break;
    }
  }, [history, orderId, orderStatus]);

  useEffect(() => {
    switch (orderStatus) {
      case 'FINISHED':
        return history.replace('/contractor-evaluation', {});

      case 'PLATE_CONFIRMED':
        if (serviceDisplace) {
          return history.replace('/contractor-evaluation', {});
        }
        break;

      case 'IN_EXECUTION':
        if (!serviceDisplace) {
          return history.replace('/waiting-contractor-pictures');
        }
        break;

      default:
        break;
    }
  }, [history, orderStatus, serviceDisplace]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const {
        coords: { latitude, longitude },
      } = position;
      setCurrentLatLng({ latitude, longitude });
    });
  }, []);

  useEffect(() => {
    if (!contractorCoords) {
      return;
    }

    const { latitude, longitude } = contractorCoords;

    if (!latitude || !longitude) {
      return;
    }

    // setRegion(prevState => ({ ...prevState, latitude, longitude }));
  }, [contractorCoords]);

  useEffect(() => {
    if (contractor.data?.phone_number) {
      const phoneNumber = formatCellPhone(removeFormat(contractor.data.phone_number.replace('+55', '')));

      setContractorPhoneNumber(phoneNumber);
    }
  }, [contractor.data, dispatch]);

  useEffect(() => {
    if (contractor.error) {
      const errorMessages = handleApiErrorResponse(contractor.error, 'Erro ao consultar o prestador, tente novamente');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  }, [addToast, contractor.error, dispatch]);

  const handleToggleModal = useCallback(() => {
    setOpenModal(prevState => !prevState);
  }, []);

  const handleRedirect = useCallback(() => {
    history.replace('/request-canceled-by-customer', { orderId });
  }, [history, orderId]);

  if ((!positionCenter && !currentLatLng?.latitude && !currentLatLng?.longitude) || contractor.loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <>
        {orderStatus === 'ON_GOING' && <ButtonCancel onClick={handleToggleModal} />}

        {!!contractorPhoneNumber && (
          <ButtonCall top={orderStatus === 'ON_GOING' ? 90 : 30} phoneNumber={contractorPhoneNumber} />
        )}

        <div style={{ display: 'flex', flex: 1 }} id='map'>
          <GoogleMapReact
            bootstrapURLKeys={
              process.env.REACT_APP_GOOGLE_API_KEY ? { key: process.env.REACT_APP_GOOGLE_API_KEY } : undefined
            }
            defaultCenter={
              currentLatLng?.latitude && currentLatLng?.longitude
                ? {
                    lat: currentLatLng.latitude,
                    lng: currentLatLng.longitude,
                  }
                : undefined
            }
            defaultZoom={16}
            center={positionCenter}
            zoom={zoomPosition}
            yesIWantToUseGoogleMapApiInternals
          >
            {contractorCoords && (
              <Pin lat={contractorCoords.latitude} lng={contractorCoords.longitude} color={Colors.blue} />
            )}
            {destination && <Pin lat={destination.latitude} lng={destination.longitude} />}
          </GoogleMapReact>
        </div>

        <Contractor>
          <ViewUserData>
            {contractor.data?.picture && <ProfileImage src={contractor.data.picture} width={80} height={80} />}
            <ViewUser>
              <Label fontWeight='bold' fontSize={20}>
                {contractor.data?.name}
              </Label>

              <Label fontWeight='bold' fontSize={14}>
                {`${splitWords(
                  contractor.data?.vehicle_model,
                  WORDS,
                )} - ${contractor.data?.vehicle_color.toUpperCase()}`}
              </Label>

              <Label fontWeight='bold' fontSize={14}>
                {`Placa - ${contractor.data?.vehicle_license_plate}`}
              </Label>
            </ViewUser>
          </ViewUserData>
        </Contractor>
      </>

      <ModalCancel
        open={openModal}
        data={{
          orderId,
          cost: { ...orderData?.cost },
          onCancel: handleToggleModal,
          onError: handleToggleModal,
          onConfirm: handleToggleModal,
          redirectTo: handleRedirect,
        }}
      />
    </Container>
  );
};

export default Tracking;
