import React from 'react';

import { LayoutProps } from '~/types';
import { DivContainer, Div } from './styles';

export default function LoginLayout({ children }: LayoutProps) {
  return (
    <DivContainer>
      <Div>{children}</Div>
    </DivContainer>
  );
}
