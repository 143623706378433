import React, { useEffect, useCallback, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from '@material-ui/core';

// import { Container } from './styles';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { OrderFirestore, OrderFirestoreWithId } from '~/types';
import Loading from '~/components/Loading';
import { useToast } from '~/services/hooks/toast';
import { useFirestore } from '~/services/hooks/useFirestore';

const StatusOrder = () => {
  const history = useHistory();
  const { orderId } = (useLocation().state || {}) as { orderId?: string };
  const { addToast } = useToast();
  const { getDocument } = useFirestore<OrderFirestore>();

  useLayoutEffect(() => {
    if (!orderId) {
      history.push('/');
    }
  }, [history, orderId]);

  const getOrder = useCallback(async () => {
    if (!orderId) {
      return;
    }

    const docData = await getDocument('orders', orderId);

    if (!docData) {
      return;
    }

    if (docData.customer_survey) {
      return;
    }

    const currentOrder: OrderFirestoreWithId = {
      order_id: orderId,
      ...docData,
    };

    return currentOrder;
  }, [getDocument, orderId]);

  const navigateToRoute = useCallback(async () => {
    const currentOrder = await getOrder();

    switch (currentOrder?.status) {
      case 'ALLOCATING':
      case 'MANUAL_ALLOCATION':
        return history.replace('/waiting-searching-contractor', {
          orderId,
        });

      case 'CHECKIN':
      case 'ON_GOING':
        return history.replace('/tracking', {
          orderId,
          deviceId: currentOrder.device_id,
          contractorId: currentOrder.contractor_id,
          serviceDisplace: currentOrder.service_displace,
          destination: {
            latitude: currentOrder.latitude,
            longitude: currentOrder.longitude,
          },
        });

      case 'IN_EXECUTION':
        if (!currentOrder.service_displace) {
          return history.replace('/waiting-contractor-pictures');
        }

        return history.replace('/tracking', {
          orderId,
          deviceId: currentOrder.device_id,
          contractorId: currentOrder.contractor_id,
          serviceDisplace: currentOrder.service_displace,
          destination: {
            latitude: currentOrder.latitude,
            longitude: currentOrder.longitude,
          },
        });

      case 'CHECKOUT':
        return history.replace('/waiting-contractor-pictures');

      case 'FINISHED':
        return history.replace('/contractor-evaluation', { orderId });

      case 'PLATE_CONFIRMED':
        return history.replace('/route-confirmation', {
          orderId,
        });

      case 'PAYMENT_PENDING':
        return history.replace('/pix', { orderId });

      case 'CANCELED':
        return history.replace('/request-canceled', {
          orderId,
          serviceType: currentOrder.service_type,
          licensePlate: currentOrder.license_plate,
        });

      default:
        return history.push('/orders-in-execution');
    }
  }, [getOrder, history, orderId]);

  useEffect(() => {
    const callApi = async () => {
      try {
        return navigateToRoute();
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o status do prestador');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      }
    };

    callApi();
  }, [addToast, navigateToRoute]);

  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default StatusOrder;
