import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { forgotPasswordRequest, clearError } from '~/store/ducks/auth';
import Button from '~/components/Button';
import { Input, Label } from './styles';
import { formatUsername, removeFormat } from '~/util/format';
import { validCNPJ, validCPF } from '~/util/validate';
import Colors from '~/styles/colors';
import { Error } from '~/types';

export default function ForgotPassword({ history }) {
  const [username, setUsername] = useState('');
  const [error, setError] = useState<Error | null>(null);
  const dispatch = useDispatch();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    let newUsername = removeFormat(username);

    if (!newUsername.trim()) {
      return setError({ message: 'CPF / CNPJ não informado!' });
    }

    if (newUsername.length <= 11) {
      newUsername = newUsername.padStart(11, '0');
      if (!validCPF(newUsername)) {
        return setError({ message: 'CPF inválido!' });
      }
    } else {
      newUsername = newUsername.padStart(14, '0');
      if (!validCNPJ(newUsername)) {
        return setError({ message: 'CNPJ inválido!' });
      }
    }

    dispatch(forgotPasswordRequest(newUsername));
    history.push('/forgot-confirmation', newUsername);
    return true;
  };

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      <Label>ESQUECEU A SENHA</Label>

      <Input
        name='username'
        label='CPF / CNPJ'
        value={username}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(formatUsername(e.target.value))}
        autoFocus
        error={error}
      />

      <Button
        type='submit'
        backgroundColor={Colors.primary}
        color={Colors.white}
        width='100%'
        label='Recuperar senha'
      />

      <Button
        width='100%'
        type='button'
        label='Voltar'
        backgroundColor={Colors.white}
        color={Colors.primary}
        onClick={handleGoBack}
        border={`1px solid ${Colors.blue}`}
      />
    </form>
  );
}
