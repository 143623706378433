import { AddressComponent } from '~/types';

const getAddress = (components: google.maps.GeocoderAddressComponent[]) => {
  const address: AddressComponent = {} as AddressComponent;

  components.forEach(component => {
    if (component.types.includes('street_number')) {
      address.number = component.long_name;
    }

    if (component.types.includes('route')) {
      address.street = component.long_name;
    }

    if (
      component.types.includes('sublocality_level_1') ||
      (component.types.includes('administrative_area_level_4') && !address.neighborhood)
    ) {
      address.neighborhood = component.long_name;
    }

    if (component.types.includes('administrative_area_level_2')) {
      address.city = component.long_name;
    }

    if (component.types.includes('point_of_interest')) {
      address.point_of_interest = component.long_name;
    }
  });

  address.fullAddress = `${address?.street ?? address?.point_of_interest ?? '-'}${
    address?.number ? `, ${address?.number}` : ''
  }`;

  return address;
};

export default getAddress;
