import styled from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import Colors from '~/styles/colors';
import InputComponent from '~/components/InputComponent';

interface LabelProps {
  data?: {
    fontWeight?: string;
    marginTop?: number;
    marginHorizontal?: number;
    fontSize?: number;
    textAlign?: string;
  };
}

export const Input = styled(InputComponent)`
  input {
    color: ${Colors.primary};
    border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.white)};

    &:hover {
      border-bottom-color: ${Colors.white};
    }
  }
  & label {
    color: ${Colors.primary};
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${Colors.primary};
  }

  && .MuiInput-underline:before {
    border-bottom-color: ${Colors.primary};
  }
  && .MuiInput-underline:hover:before {
    border-bottom: 1px solid ${Colors.primary};
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: red;
    }
    &:hover fieldset {
      border-bottom-color: ${Colors.primary};
    }
    &.Mui-focused fieldset {
      border-bottom-color: ${Colors.primary};
    }
  }
`;

export const Link = styled(BaseLink)`
  color: ${Colors.primary};
  margin-top: 15px;
  transition: opacity 0.3s;
  text-align: left;

  &:hover {
    opacity: 0.7;
  }
`;

export const Label = styled(Typography).attrs({
  variant: 'inherit',
})<LabelProps>`
  color: ${Colors.primary};
  font-weight: ${({ data }) => data?.fontWeight ?? 'normal'};
  margin-top: ${({ data }) => data?.marginTop ?? 0}px;
  margin-left: ${({ data }) => data?.marginHorizontal ?? 20}px;
  margin-right: ${({ data }) => data?.marginHorizontal ?? 20}px;
  font-size: ${({ data }) => data?.fontSize ?? 16}px;
  text-align: ${({ data }) => data?.textAlign ?? 'center'};
`;

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  height: 32px;
`;
