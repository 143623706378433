import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { handleApiErrorResponse } from '~/services/handleErrors';
import { confirmSignUpApi } from '~/services/api';
import { resendConfirmationCode, confirmSignUpSuccess, logoutSuccess } from '~/store/ducks/auth';

import { Title, Input } from './styles';

import { removeSpaces } from '~/util/format';
import Button from '~/components/Button';
import { useToast } from '~/services/hooks/toast';
import Colors from '~/styles/colors';

export default function ConfirmSignUp() {
  const [confirmationCode, setConfirmationCode] = useState('');

  const dispatch = useDispatch();
  const { username, attributeName } = useSelector(state => ({
    username: state?.auth?.user?.username ?? state?.auth?.user['cognito:username'],
    attributeName: state.auth.signUp?.data?.attribute_name,
  }));
  const { addToast } = useToast();

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!confirmationCode.trim()) {
      return validation('Código de Confirmação não informado!');
    }

    try {
      await confirmSignUpApi(username, confirmationCode);
      addToast({
        title: `${
          attributeName === 'email' ? 'E-mail' : attributeName === 'phone_number' ? 'Telefone' : 'Código'
        } confirmado com sucesso, realize o login para continuar.`,
        type: 'success',
      });
      dispatch(confirmSignUpSuccess());
    } catch (err) {
      const errorMessages = handleApiErrorResponse(err);
      validation(errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage);
    }
  };

  const validation = (error?: string) => {
    addToast({
      title: 'Erro Inesperado',
      type: 'error',
      description: error,
    });
  };

  const handleResendConfirmationCode = () => {
    dispatch(resendConfirmationCode(username));
  };

  const handleLogout = () => {
    dispatch(logoutSuccess());
  };

  return (
    <form onSubmit={submitForm} autoComplete='off'>
      <Title>
        Enviamos {`${attributeName === 'email' ? 'por e-mail' : attributeName === 'phone_number' ? 'por SMS' : ''}`} um
        código de verificação!
      </Title>

      <Input
        name='confirmation-code'
        label='Código de Confirmação'
        value={confirmationCode}
        onChange={e => setConfirmationCode(removeSpaces(e.target.value))}
        autoFocus
      />

      <Button type='submit' filled label='Confirmar' />

      <Button
        autoFocus
        variant='contained'
        color={Colors.primary}
        style={{ textTransform: 'none' }}
        onClick={handleResendConfirmationCode}
        label='Não recebeu? Clique aqui para reenviar!'
        type='button'
      />

      <Button
        width='100%'
        type='button'
        label='Voltar'
        backgroundColor={Colors.white}
        color={Colors.primary}
        onClick={handleLogout}
        border={`1px solid ${Colors.blue}`}
      />
    </form>
  );
}
