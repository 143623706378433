import styled from 'styled-components';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Card } from '@material-ui/core';

import Colors from '~/styles/colors';

interface ModalSuccessProps {
  fullscreen: boolean;
}

export const Pin = styled(FaMapMarkerAlt).attrs<{ color?: string }>(({ color }) => ({
  size: 24,
  color: color ?? Colors.primary,
}))<{
  lat: number;
  lng: number;
}>`
  position: absolute;
  top: -20px;
  transform-origin: 50% 50%;
`;

export const Container = styled.div`
  padding: 0 16px 16px 16px;
`;

export const ModalSuccessContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const ModalSuccessTop = styled.div<ModalSuccessProps>`
  display: flex;
  min-height: 30vh;
  flex-direction: column;
  position: relative;
  align-items: center;
  flex: ${({ fullscreen }) => (fullscreen ? 1 : 'auto')};
`;

export const ModalSuccessBottom = styled.div<ModalSuccessProps>`
  display: flex;
  background-color: ${Colors.green};
  align-items: center;
  justify-content: center;
  min-height: 30vh;
  padding: 16px;
  flex: ${({ fullscreen }) => (fullscreen ? 1 : 'auto')};
`;

export const ModalSuccessCard = styled(Card)`
  position: absolute;
  bottom: -50px;
  padding: 24px;
  max-width: 85%;
`;

export const ModalSuccessLink = styled.a`
  font-style: italic;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GridItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
`;

export const Content = styled.div`
  padding-bottom: 80px;
`;
