import styled from 'styled-components';

interface ContainerProps {
  topScroll: number;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  right: 0;
  top: ${props => props.topScroll + 60 ?? 60}px;
  overflow: hidden;
  z-index: 1500;
  max-width: 100vw;
`;
