import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { handleApiErrorResponse } from '~/services/handleErrors';
import { useFirestore } from '~/services/hooks/useFirestore';
import Loading from '~/components/Loading';
import { useToast } from '~/services/hooks/toast';

const RouteConfirmation = () => {
  const { orderId } = (useLocation().state || {}) as { orderId?: string };
  const history = useHistory();
  const { addToast } = useToast();

  const { setDocument } = useFirestore();

  useEffect(() => {
    if (!orderId) {
      history.push('/');
    }
  }, [history, orderId]);

  const handleConfirmAddress = useCallback(async () => {
    try {
      if (!orderId) {
        return;
      }

      await setDocument('route-confirmation', orderId, { confirmed: true });

      history.replace('/waiting-contractor-pictures');
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar a ordem, tente novamente');
      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  }, [addToast, history, orderId, setDocument]);

  useEffect(() => {
    handleConfirmAddress();
  }, [handleConfirmAddress]);

  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default RouteConfirmation;
