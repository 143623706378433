import styled from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';

import Colors from '~/styles/colors';
import InputComponent from '~/components/InputComponent';

export const Form = styled.form`
  display: grid;
`;

export const Title = styled.h1`
  font-size: 1rem;
  color: ${Colors.primary};
`;

export const Input = styled(InputComponent)`
  input {
    color: ${Colors.primary};
    border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.primary)};

    &:hover {
      border-bottom-color: ${Colors.primary};
    }
  }
  & label {
    color: ${Colors.primary};
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${Colors.blue};
  }

  && .MuiInput-underline:before {
    border-bottom-color: ${Colors.primary};
  }
  && .MuiInput-underline:hover:before {
    border-bottom: 1px solid ${Colors.primary};
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: red;
    }
    &:hover fieldset {
      border-bottom-color: ${Colors.blue};
    }
    &.Mui-focused fieldset {
      border-bottom-color: ${Colors.blue};
    }
  }
`;

export const Link = styled(BaseLink)`
  color: ${Colors.primary};
  margin-top: 15px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;
