import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { changeEmailConfirmation } from '~/services/api';
import { logout, resendAliasConfirmationCodeRequest } from '~/store/ducks/auth';
import { useToast } from '~/services/hooks/toast';

import { Input, Title } from './styles';
import { handleApiErrorResponse } from '~/services/handleErrors';
import Button from '~/components/Button';
import { removeSpaces } from '~/util/format';
import { ConfirmationType } from '~/types';
import Colors from '~/styles/colors';

export default function SetEmailConfirm() {
  const [resetCode, setResetCode] = useState('');

  const { access_token, user, resendAlias } = useSelector(state => ({
    access_token: state?.auth?.authorization?.access_token,
    user: state?.auth?.user,
    resendAlias: state.auth.resendAlias,
  }));
  const { addToast } = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedConfirmation } = (useLocation().state || {}) as { selectedConfirmation?: ConfirmationType };

  const submitForm = async event => {
    event.preventDefault();

    if (!resetCode.trim()) {
      return validation('Código de Confirmação não informado!');
    }

    try {
      await changeEmailConfirmation(
        user['cognito:username'],
        resetCode,
        selectedConfirmation === 'email' ? 'EMAIL' : 'PHONE_NUMBER',
        access_token,
      );

      addToast({
        title: 'Faça login no sistema',
        type: 'info',
      });
      history.push('/');
      dispatch(logout());
    } catch (err) {
      const errorMessages = handleApiErrorResponse(err);
      validation(errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage);
    }
  };

  const handleResendConfirmationCode = useCallback(() => {
    dispatch(
      resendAliasConfirmationCodeRequest({
        confirmation_type: selectedConfirmation === 'email' ? 'EMAIL' : 'PHONE_NUMBER',
        access_token,
      }),
    );
  }, [access_token, dispatch, selectedConfirmation]);

  const validation = error => {
    addToast({
      title: 'Erro Inesperado',
      type: 'error',
      description: error,
    });
  };

  const goBack = () => {
    history.push('/');
    dispatch(logout());
  };

  useEffect(() => {
    if (resendAlias.success) {
      addToast({ title: 'Novo Código Enviado!', type: 'success' });
    }

    if (resendAlias.error) {
      addToast({ title: 'Erro Inesperado', type: 'error', description: resendAlias.message });
    }
  }, [addToast, resendAlias]);

  useEffect(() => {
    if (!selectedConfirmation) {
      history.push('/');
    }
  }, [history, selectedConfirmation]);

  return (
    <form onSubmit={submitForm} autoComplete='off'>
      <Title>Enviamos por {`${selectedConfirmation === 'email' ? 'e-mail' : 'SMS'}`} um código de verificação!</Title>

      <Input
        name='confirmation-code'
        label='Código de Validação'
        value={resetCode}
        onChange={e => setResetCode(removeSpaces(e.target.value))}
        autoFocus
      />

      <Button type='submit' backgroundColor={Colors.primary} color={Colors.white} width='100%' label='Validar código' />

      <Button
        type='button'
        filled
        label='Não recebeu? Clique aqui para reenviar!'
        onClick={handleResendConfirmationCode}
      />

      <Button
        type='button'
        label='Cancelar'
        backgroundColor={Colors.white}
        color={Colors.primary}
        onClick={goBack}
        border={`1px solid ${Colors.blue}`}
        width='100%'
      />
    </form>
  );
}
