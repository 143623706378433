import styled from 'styled-components';

import Colors from '~/styles/colors';

interface LabelProps {
  fontWeight?: string;
  marginTop?: number;
  marginHorizontal?: number;
  fontSize?: number;
  textAlign?: string;
}

export const Container = styled.div`
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${Colors.lightGreyNew};
  height: 85vh;
  position: relative;
`;

export const CarouselPageContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: space-around;
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
