import React from 'react';
import { BottomNavigationAction, Theme, withStyles } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';

import Loading from '../Loading';
import Colors from '~/styles/colors';

interface SimpleBottomActionProps {
  label: string;
  icon: SvgIconComponent;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  classes?: any;
}

const styles = (theme: Theme) => ({
  child: {
    backgroundColor: Colors.primary,
  },
  rippleVisible: {
    opacity: 0.5,
    animation: `$enter 50ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes enter': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0.1,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0.5,
    },
  },
});

function SimpleBottomAction({ classes, label, icon: Icon, onClick, loading, disabled }: SimpleBottomActionProps) {
  return (
    <BottomNavigationAction
      label={label}
      icon={loading ? <Loading size='1rem' /> : <Icon />}
      onClick={onClick}
      disabled={disabled}
      selected
      TouchRippleProps={{ classes, center: true }}
    />
  );
}

export default withStyles(styles)(SimpleBottomAction);
