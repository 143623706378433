import React, { forwardRef } from 'react';
import { Container, InputText, Input } from './styles';

interface InputComponentProps {
  label: string;
  placeholder?: string;
  style?: React.CSSProperties;
  error: any;
  name: string;
  [key: string]: any;
}

function InputComponent({ label, placeholder, style, error, name, ...rest }: InputComponentProps, ref: React.Ref<any>) {
  return label ? (
    <Container style={style}>
      <InputText
        label={label}
        name={name}
        error={!!error}
        helperText={error ? error.message : ''}
        placeholder={placeholder}
        inputRef={ref}
        {...rest}
      />
    </Container>
  ) : (
    <Input style={style} name={name} error={!!error} placeholder={placeholder} inputRef={ref} {...rest} />
  );
}
export default forwardRef(InputComponent);
