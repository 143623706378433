import styled from 'styled-components';

import Button from '~/components/Button';
import Colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: ${Colors.background};
  position: relative;
  height: 90vh;
`;

export const Card = styled.div`
  width: 100%;
  margin: 10px 0;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const TextMessageContainer = styled.div`
  background-color: ${Colors.white};
  border-width: 1px;
  border-color: ${Colors.primary};
  border-radius: 10px;
  border-style: solid;
  margin: 35px;
  padding: 40px 10px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10%;
  flex: 1;
  display: flex;
`;

export const BackgroundWithColor = styled.div`
  background-color: ${Colors.blue};
  height: 60%;
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const ButtonStyled = styled(Button)`
  margin: 10px;
`;
