import { useEffect, useState } from 'react';

import {
  getFipeManufacturersByCategoryApi,
  getFipeModelsByVehiclesAndManufacturersAndCategoryApi,
  getFipeVehiclesByManufacturersAndCategoryApi,
  getVehicleTypesApi,
} from '../api';

export interface CategoryType {
  id: string;
  description: string;
}

export interface ManufacturerType {
  id: string;
  name: string;
}

export interface VehicleType {
  id: string;
  name: string;
}

export interface ModelType {
  id: string;
  name: string;
}

interface UseFipeProps {
  getInfo?: boolean;
  category?: CategoryType;
  manufacturer?: ManufacturerType;
  vehicle?: VehicleType;
}

export function useFipe({ getInfo, category, manufacturer, vehicle }: UseFipeProps) {
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [categoryTypes, setCategoryTypes] = useState<CategoryType[]>([]);
  const [manufacturerTypes, setManufacturerTypes] = useState<ManufacturerType[]>([]);
  const [loadingManufacturer, setLoadingManufacturer] = useState(false);
  const [vehiclesTypes, setVehiclesTypes] = useState<VehicleType[]>([]);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [modelsTypes, setModelsTypes] = useState<ModelType[]>([]);
  const [loadingModels, setLoadingModels] = useState(false);

  const categoryFipe = (categoryId: string) =>
    categoryId.toLowerCase() === 'motocicleta'
      ? 'MOTORCYCLE'
      : categoryId.toLowerCase() === 'caminhao'
      ? 'TRUCK'
      : 'CAR';

  const isSpecialVehicle = (categoryName?: string) => ['ÔNIBUS', 'MÁQUINAS'].includes(categoryName || '');

  const getManufacturers = async (categoryName: string) => {
    try {
      setLoadingManufacturer(true);
      const { data } = await getFipeManufacturersByCategoryApi(categoryName);
      setManufacturerTypes(data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingManufacturer(false);
    }
  };

  const getVehicles = async (categoryName: string, manufactureId: string) => {
    try {
      setLoadingVehicles(true);
      const { data } = await getFipeVehiclesByManufacturersAndCategoryApi(categoryName, manufactureId);
      setVehiclesTypes(data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingVehicles(false);
    }
  };

  const getModels = async (categoryName: string, manufactureId: string, vehicleId: string) => {
    try {
      setLoadingModels(true);
      const { data } = await getFipeModelsByVehiclesAndManufacturersAndCategoryApi(
        categoryName,
        manufactureId,
        vehicleId,
      );
      setModelsTypes(data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingModels(false);
    }
  };

  useEffect(() => {
    setManufacturerTypes([]);
    setVehiclesTypes([]);
    setModelsTypes([]);

    if (!category?.description || isSpecialVehicle(category.description)) {
      return;
    }

    const fipe = categoryFipe(category.description);
    getManufacturers(fipe);
  }, [category]);

  useEffect(() => {
    setVehiclesTypes([]);
    setModelsTypes([]);

    if (!category?.description || !manufacturer?.id || isSpecialVehicle(category.description)) {
      return;
    }

    const fipe = categoryFipe(category.description);

    getVehicles(fipe, manufacturer.id);
  }, [category, manufacturer]);

  useEffect(() => {
    if (!category?.description || !manufacturer?.id || !vehicle?.id || isSpecialVehicle(category.description)) {
      return;
    }

    const fipe = categoryFipe(category.description);

    getModels(fipe, manufacturer.id, vehicle.id);
  }, [category, manufacturer, vehicle]);

  useEffect(() => {
    const loadFipeTypes = async () => {
      if (!getInfo) {
        return;
      }

      setLoadingCategory(true);

      try {
        const { data } = await getVehicleTypesApi();
        setCategoryTypes(data);
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoadingCategory(false);
      }
    };

    loadFipeTypes();
  }, [getInfo]);

  return {
    categoryTypes,
    loadingCategory,
    manufacturerTypes,
    loadingManufacturer,
    vehiclesTypes,
    loadingVehicles,
    modelsTypes,
    loadingModels,
    isSpecialVehicle,
  };
}
