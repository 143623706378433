import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Clipboard } from 'phosphor-react';

import Button from '~/components/Button';
import Colors from '~/styles/colors';
import { PaymentsFirestore } from '~/types';
import { CSSProperties } from 'react';

interface BackgroundWithColorProps {
  status?: PaymentsFirestore['status'];
}

interface TextCodeContainerProps {
  cursor?: CSSProperties['cursor'];
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: ${Colors.background};
  height: 100vh;
  padding: 24px;
`;

export const ImageContainer = styled.div`
  padding: 20px;
  align-items: center;
  justify-content: center;
`;

export const HoldMobileImage = styled.img`
  width: 125px;
  height: 188px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  text-align: center;
  flex: 1;
`;

export const CardItem = styled(Button).attrs({
  activeOpacity: 0.7,
})`
  height: 40px;
  border-radius: 7px;
  align-items: center;
  flex-direction: row;
  background: ${Colors.white};
  margin: 5px 0;
`;

export const TextCard = styled(Typography)`
  padding: 0 5px;
  color: ${Colors.primary};
  font-size: 12px;
  text-align: center;
`;
export const TextMessage = styled(Typography)`
  padding: 25px 10px;
  flex: 1;
  color: ${Colors.primary};
  font-size: 8px;
  flex-wrap: wrap;
  text-align: center;
`;

export const TextTitle = styled(Typography)`
  text-align: center;
  padding: 0 5px;
  color: ${Colors.primary};
  font-size: 16px;
  font-weight: bold;
`;

export const TextCodeContainer = styled.div<TextCodeContainerProps>`
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : '')}
  display: flex;
  background-color: ${Colors.white};
  border-width: 1px;
  border-color: ${Colors.primary};
  border-radius: 10px;
  border-style: dashed;
  margin: 10px;
  padding: 6px 12px 6px 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center; /* */
`;

export const TextMessageContainer = styled.div`
  background-color: ${Colors.white};
  border-width: 1px;
  border-color: ${Colors.primary};
  border-radius: 10px;
  border-style: solid;
  margin: 35px;
  padding: 40px 10px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10%;
  flex: 1;
`;

export const TextCode = styled(Typography)`
  color: ${Colors.primary};
  font-size: 12px;
  text-align: center;
`;

export const ContentCard = styled.div`
  flex-direction: row;
  padding: 0 10px;
  flex: 1;
  align-items: center;
`;

export const Icon = styled(Clipboard).attrs({
  color: Colors.primary,
  weight: 'duotone',
})`
  font-size: 32px;
`;

export const ViewPicker = styled.div`
  flex: 1;
  height: 40px;
  border: 1px solid ${Colors.primary};
  border-radius: 20px;
  padding: 0 5px;
  margin: 0 0 20px;
`;

export const BackgroundWithColor = styled.div<BackgroundWithColorProps>`
  background-color: ${({ status }) =>
    status
      ? status === 'PAYMENT_APPROVED'
        ? Colors.green
        : status === 'PAYMENT_REFUSED'
        ? Colors.red
        : status === 'CANCELED'
        ? Colors.blue
        : 'transparent'
      : Colors.blue};
  height: 60%;
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const PixButton = styled(Button)`
  margin: 10px 0px;
`;
