import styled from 'styled-components';
import { Icon as BaseIcon } from '@material-ui/core';

import Colors from '~/styles/colors';

interface BaseButtonProps {
  filled?: boolean;
  backgroundColor?: string;
  width?: string;
  border?: React.CSSProperties['border'];
}

export const BaseButton = styled.button<BaseButtonProps>`
  width: ${({ filled, width }) => (filled ? '100%' : width ? width : 'auto')};
  height: 45px;
  border: ${({ border }) => border || '0'};
  background: ${({ filled, backgroundColor }) => (filled ? Colors.blue : backgroundColor ?? 'transparent')};
  color: ${({ color }) => color ?? Colors.primary};
  margin-top: 20px;
  border-radius: 5px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`;

export const Icon = styled(BaseIcon)`
  & svg {
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;
