import React from 'react';

import LogoPicktow from '~/assets/svg/logo_p_picktow';
import { LayoutProps } from '~/types';
import { DivContainer, Div } from './styles';

export default function AuthLayout({ children, marginTop }: LayoutProps) {
  return (
    <DivContainer>
      <Div>
        <div style={{ height: marginTop }} />
        <div>
          <LogoPicktow width='50' height='50' />
        </div>
        {children}
      </Div>
    </DivContainer>
  );
}
