import styled from 'styled-components';
// import { Picker as BasePicker } from 'native-base';
import { DialogActions as DialogActionsBase } from '@material-ui/core';

import Colors from '~/styles/colors';

export const ViewPicker = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid ${Colors.primary};
  border-radius: 20px;
  padding: 0 5px;
  margin: 20px 0 10px;
`;

export const Picker = styled.select`
  height: 40px;
`;

export const ViewLoading = styled.div`
  width: 300px;
  height: 50px;
`;

export const DialogActions = styled(DialogActionsBase)`
  display: flex;
  margin: 30px 0;
  justify-content: space-around;
  width: 100%;
`;
