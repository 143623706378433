import React from 'react';

import { Refresh } from '@material-ui/icons';
import { BaseButton, Icon } from './styles';

export interface ButtonProps {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  [key: string]: any;
}

export default function Button({ label, loading, disabled, ...rest }: ButtonProps) {
  return (
    <BaseButton {...rest} disabled={loading || disabled}>
      {loading ? (
        <Icon>
          <Refresh />
        </Icon>
      ) : (
        label
      )}
    </BaseButton>
  );
}
