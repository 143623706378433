import styled from 'styled-components';
import Colors from '~/styles/colors';

interface TitleProps {
  fontSize?: string;
}

const Title = styled.h1<TitleProps>`
  font-size: ${({ fontSize }) => `${fontSize ?? '36px'}`};
  color: ${Colors.primary};
  margin-bottom: 20px;
`;

export default Title;
