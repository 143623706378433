import React from 'react';

import { IconButton, Close } from './styles';

interface ButtonCancel {
  onClick: () => void;
}

const ButtonCancel = ({ onClick }: ButtonCancel) => {
  return (
    <IconButton onClick={onClick}>
      <Close />
    </IconButton>
  );
};

export default ButtonCancel;
