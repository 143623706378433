import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useHistory, useLocation } from 'react-router-dom';

import Stop from '~/assets/svg/stop.svg';
import Cars from '~/assets/svg/cars.svg';
import Triangle from '~/assets/svg/triangle.svg';
import Fix from '~/assets/svg/fix.svg';
import Shield from '~/assets/svg/shield.svg';
import StopWatch from '~/assets/svg/stopwatch.svg';

import { Container, CarouselPageContainer, CarouselContainer } from './styles';
import { useFirestore } from '~/services/hooks/useFirestore';
import { OrderFirestore } from '~/types';
import ButtonCancel from '~/components/ButtonCancel';
import ModalCancel from '~/components/ModalCancel';

type DataType = {
  Header?: JSX.Element;
  Image: JSX.Element;
  Text: JSX.Element;
};

const DATA: DataType[] = [
  {
    Header: (
      <Typography style={{ marginTop: 15, fontSize: 15, fontWeight: 'bold' }}>
        O TEMPO MÉDIO PARA O ACEITE É:
      </Typography>
    ),
    Image: <img alt='StopWatch' src={StopWatch} width={30} height={30} />,
    Text: (
      <Typography style={{ marginTop: 20, fontSize: 13 }}>
        {
          'Capital e Região\nMetropolitana: de\n10 a 15 minutos\n\nFora da Capital e Região\nMetropolitana: de\n15 a 30 minutos\n\nCidades do interior:\nem até 1 hora'
        }
      </Typography>
    ),
  },
  {
    Image: <img alt='Stop' src={Stop} width={60} height={60} />,
    Text: (
      <Typography style={{ marginTop: 60, fontSize: 15, marginLeft: 40, marginRight: 40 }}>
        Pare em segurança, em caso de o veículo parar no meio de uma estrada ou via, busque ajuda para empurrá-lo para
        um local mais seguro e que não obstrua a circulação do trânsito.
      </Typography>
    ),
  },
  {
    Image: <img alt='Cars' src={Cars} width={60} height={60} />,
    Text: (
      <Typography style={{ marginTop: 60, fontSize: 15, marginLeft: 40, marginRight: 40 }}>
        Ligue o pisca-alerta, sinalizando que o veículo está parado.
      </Typography>
    ),
  },
  {
    Image: <img alt='Triangle' src={Triangle} width={60} height={60} />,
    Text: (
      <Typography style={{ marginTop: 60, fontSize: 15, marginLeft: 40, marginRight: 40 }}>
        Sinalize, não deixe de utilizar o triângulo de segurança a pelo menos 100 metros de distância do veículo,
        reduzindo o risco de outros veículos baterem contra você.
      </Typography>
    ),
  },
  {
    Image: <img alt='Fix' src={Fix} width={60} height={60} />,
    Text: (
      <Typography style={{ marginTop: 60, fontSize: 15, marginLeft: 40, marginRight: 40 }}>
        Aguarde o socorro, nunca tente consertar o veículo com ele parado em uma rodovia. Além de oferecer perigo de
        assaltos e eventuais acidentes, o Código Brasileiro de Trânsito considera como infração média.
      </Typography>
    ),
  },
  {
    Image: <img alt='Shield' src={Shield} width={60} height={60} />,
    Text: (
      <Typography style={{ marginTop: 60, fontSize: 15, marginLeft: 40, marginRight: 40 }}>
        Não fique dentro do veículo, vá para um local seguro, mantenha-se a 50 metros do veículo. Logo um prestador irá
        até você.
      </Typography>
    ),
  },
];

const useStyles = makeStyles(() => ({
  'carousel-root': {
    display: 'flex',
    flex: 1,
  },
}));

const WaitingSearchingContractor = () => {
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles();
  const { orderId } = (useLocation().state || {}) as { orderId?: string };
  const orderDocument = useFirestore<OrderFirestore>('orders', orderId).snapshot;
  const history = useHistory();

  // const { orderId: orderIdService } = useService();
  // const orderIdParam = route?.params?.orderId;
  // const orderId = orderIdParam || orderIdService;

  useEffect(() => {
    if (!orderId) {
      history.replace('/');
    }
  }, [history, orderId]);

  useEffect(() => {
    if (!orderDocument) {
      return;
    }

    const status = orderDocument?.status;
    const deviceId = orderDocument?.device_id;
    const contractorId = orderDocument?.contractor_id;

    switch (status) {
      case 'CHECKIN':
      case 'ON_GOING':
        return history.replace('/tracking', {
          deviceId,
          contractorId,
        });

      default:
        break;
    }
  }, [history, orderDocument]);

  const handleToggleModal = () => {
    setOpenModal(prevState => !prevState);
  };

  const handleRedirect = () => {
    history.replace('/request-canceled-by-customer', { orderId });
  };

  return (
    <Container>
      <ButtonCancel onClick={handleToggleModal} />

      <Typography style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>
        {'AGUARDE ENQUANTO\nBUSCAMOS UM PRESTADOR!'}
      </Typography>

      <CarouselContainer>
        <Carousel
          infiniteLoop
          autoPlay
          interval={8 * 1000} // 8 seconds
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          className={classes['carousel-root']}
        >
          {DATA.map(CarouselPage)}
        </Carousel>
      </CarouselContainer>

      <ModalCancel
        open={openModal}
        data={{
          orderId: orderId!,
          cost: { ...orderDocument?.cost },
          onCancel: handleToggleModal,
          onError: handleToggleModal,
          onConfirm: handleToggleModal,
          redirectTo: handleRedirect,
        }}
      />
    </Container>
  );
};

const CarouselPage = ({ Image, Text, Header }: DataType, index: number) => (
  <CarouselPageContainer key={index}>
    {Header}
    {Image}
    {Text}
  </CarouselPageContainer>
);

export default WaitingSearchingContractor;
