import { Radio, RadioGroup, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '~/components/Button';
import { changeEmailApi, changePhoneNumberApi } from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';

import { logout } from '~/store/ducks/auth';
import Colors from '~/styles/colors';
import { ConfirmationType, Error } from '~/types';
import { formatCellPhone, removeFormat, removeSpaces } from '~/util/format';
import { validEmail } from '~/util/validate';
import { Input, Title } from './styles';

export default function SetEmail() {
  const { accessToken, emailValidationRequired, phoneNumberValidationRequired } = useSelector(state => ({
    accessToken: state.auth.authorization.access_token,
    emailValidationRequired: state.auth.authorization.email_validation_required,
    phoneNumberValidationRequired: state.auth.authorization.phone_number_validation_required,
  }));

  const [field, setField] = useState('');
  const [confirmField, setConfirmField] = useState('');
  const [error, setError] = useState<Error | null>(null);
  const [selectedConfirmation, setSelectedConfirmation] = useState<ConfirmationType>('email');

  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToast();
  const location = useLocation();

  const unconfirmedEmailAndPhone = emailValidationRequired && phoneNumberValidationRequired;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    if (!field.trim()) {
      return setError({ message: `${selectedConfirmation === 'email' ? 'E-mail' : 'Telefone'} não informado!` });
    }

    if (field !== confirmField) {
      return setError({ message: `${selectedConfirmation === 'email' ? 'E-mails' : 'Telefones'} não conferem!` });
    }

    if (!validEmail(field) && selectedConfirmation === 'email') {
      return setError({ message: 'E-mail inválido!' });
    }

    try {
      selectedConfirmation === 'email'
        ? await changeEmailApi(field, accessToken)
        : await changePhoneNumberApi(`+55${removeFormat(field)}`, accessToken);

      history.push('/set-email/confirmation', { selectedConfirmation });
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao alterar o e-mail, tente novamente');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  };

  const handleLogout = () => {
    history.push('/');
    dispatch(logout());
  };

  const handleChangeConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedConfirmation(event.target.value as ConfirmationType);
  };

  const populateField = (func: (param: string) => void, value: string) => {
    let newValue = removeSpaces(value);

    if (selectedConfirmation === 'phone_number') {
      newValue = formatCellPhone(removeFormat(newValue));
    }

    func(newValue);
  };

  useEffect(() => {
    if (location.pathname === '/login') {
      history.replace('/');
    }
  }, [history, location.pathname]);

  useEffect(() => {
    setError(null);
    setField('');
    setConfirmField('');
  }, [selectedConfirmation]);

  return (
    <form onSubmit={handleSubmit} autoComplete='off'>
      {unconfirmedEmailAndPhone ? (
        <Title>Cadastre um e-mail ou telefone para poder continuar!</Title>
      ) : emailValidationRequired ? (
        <Title>Cadastre um e-mail para poder continuar!</Title>
      ) : (
        <Title>Cadastre um telefone para poder continuar!</Title>
      )}

      {unconfirmedEmailAndPhone && (
        <RadioGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 16 }}>
          <Radio
            checked={selectedConfirmation === 'email'}
            onChange={handleChangeConfirmation}
            value='email'
            name='typeConfirmation'
            inputProps={{ 'aria-label': 'E-mail' }}
          />
          <Typography style={{ color: Colors.primary }}>E-mail</Typography>
          <div style={{ width: 24, display: 'flex' }} />

          <Radio
            checked={selectedConfirmation === 'phone_number'}
            onChange={handleChangeConfirmation}
            value='phone_number'
            name='typeConfirmation'
            inputProps={{ 'aria-label': 'Phone Number' }}
          />
          <Typography style={{ color: Colors.primary }}>Telefone</Typography>
        </RadioGroup>
      )}

      <Input
        name={selectedConfirmation}
        label={selectedConfirmation === 'email' ? 'E-mail' : 'Telefone'}
        value={field}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => populateField(setField, e.target.value)}
        autoFocus
        error={error}
        inputProps={{
          inputMode: selectedConfirmation === 'email' ? 'email' : 'numeric',
          ...(selectedConfirmation === 'phone_number' ? { maxLength: 14 } : {}),
        }}
      />

      <Input
        name={`confirm-${selectedConfirmation}`}
        label={`Confirmar ${selectedConfirmation === 'email' ? 'e-mail' : 'telefone'}`}
        value={confirmField}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => populateField(setConfirmField, e.target.value)}
        autoFocus
        error={error}
        inputProps={{
          inputMode: selectedConfirmation === 'email' ? 'email' : 'numeric',
          ...(selectedConfirmation === 'phone_number' ? { maxLength: 14 } : {}),
        }}
      />

      <Button
        type='submit'
        backgroundColor={Colors.primary}
        color={Colors.white}
        width='100%'
        label={`Cadastrar ${selectedConfirmation === 'email' ? 'E-mail' : 'Telefone'}`}
      />

      <Button
        width='100%'
        type='button'
        label='Cancelar'
        backgroundColor={Colors.white}
        color={Colors.primary}
        onClick={handleLogout}
        border={`1px solid ${Colors.blue}`}
      />
    </form>
  );
}
