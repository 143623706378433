import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { resetPasswordRequest, resendConfirmationCode, clearError } from '~/store/ducks/auth';
import { validPassword } from '~/util/validate';
import Button from '~/components/Button';
import { Input } from './styles';
import history from '~/services/history';
import { Error } from '~/types';
import Colors from '~/styles/colors';

export default function ForgotPasswordCode({ location }) {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [secureText, setSecureText] = useState(true);
  const [secureConfirmText, setSecureConfirmText] = useState(true);
  const [codeError, setCodeError] = useState<Error | null>(null);
  const [passError, setPassError] = useState<Error | null>(null);
  const [confirmError, setConfirmError] = useState<Error | null>(null);
  const { loading, error } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const username = location.state;

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setCodeError(null);
    setPassError(null);
    setConfirmError(null);

    if (!code.trim()) {
      return setCodeError({ message: 'Código de Confirmação não informado!' });
    }

    if (!password.trim()) {
      return setPassError({ message: 'Senha não informado!' });
    }

    if (password !== confirmPassword) {
      setPassError({ message: 'Senhas não conferem!' });
      setConfirmError({ message: 'Senhas não conferem!' });
      return true;
    }

    if (!validPassword(password)) {
      return setPassError({
        message: 'Deve conter 8 caracteres, sendo. Letras maiúsculas, letras minúsculas e números.',
      });
    }

    dispatch(resetPasswordRequest(username, password, code));
    return true;
  };

  const resendCode = () => {
    dispatch(resendConfirmationCode(username));
  };

  const goBack = () => {
    dispatch(clearError());
    history.push('/');
  };

  useEffect(() => {
    if (error) {
      return setCodeError({
        message: error.apiError && error.apiError.message,
      });
    }
  }, [error]);

  return (
    <form onSubmit={handleSubmit}>
      <Input
        name='code'
        label='Código de confirmação'
        value={code}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
        error={codeError}
        autoFocus
      />
      <Input
        name='password'
        label='Nova senha'
        type={secureText ? 'password' : 'text'}
        value={password}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        error={passError}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position='end'
              onClick={() => setSecureText(secure => !secure)}
              style={{ cursor: 'pointer', color: Colors.primary }}
            >
              {secureText ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          ),
        }}
      />
      <Input
        name='password'
        label='Confirmar nova senha'
        type={secureConfirmText ? 'password' : 'text'}
        value={confirmPassword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
        error={confirmError}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position='end'
              onClick={() => setSecureConfirmText(secure => !secure)}
              style={{ cursor: 'pointer', color: Colors.primary }}
            >
              {secureConfirmText ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          ),
        }}
      />

      <Button
        type='submit'
        backgroundColor={Colors.primary}
        color={Colors.white}
        width='100%'
        label='Confirmar'
        loading={loading}
      />

      <Button type='button' filled label='Reenviar código de confirmação' onClick={() => resendCode()} />

      <Button
        width='100%'
        type='button'
        label='Cancelar'
        backgroundColor={Colors.white}
        color={Colors.primary}
        onClick={goBack}
        border={`1px solid ${Colors.blue}`}
      />
    </form>
  );
}
