import React, { useEffect, useState } from 'react';
import { Avatar } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import { OrderFirestore, OrderFirestoreWithId } from '~/types';
import { getIconService } from '~/util/getIcon';
import {
  // OrdersList,
  CardItem,
  ContentCard,
  // Icon,
  TextCard,
  CardData,
  Container,
  Label,
} from './styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFirestore } from '~/services/hooks/useFirestore';
import { where } from 'firebase/firestore';
import Colors from '~/styles/colors';

const OrdersInExecution = () => {
  const [ordersList, setOrdersList] = useState<OrderFirestoreWithId[]>();

  const history = useHistory();
  const userName = useSelector(state => state.auth.user['cognito:username']);
  const { snapshotWithWhere, snapshotQuery } = useFirestore<OrderFirestoreWithId>();

  useEffect(() => {
    const unsubscribe = snapshotWithWhere('orders', where('customer_id', '==', userName));

    return unsubscribe;
  }, [snapshotWithWhere, userName]);

  useEffect(() => {
    setOrdersList(
      snapshotQuery
        ?.filter(doc => {
          const docData = doc.data() as OrderFirestore;
          return !docData.customer_survey && docData.license_plate;
        })
        .map(doc => ({ order_id: doc.id, ...(doc.data() as OrderFirestore) })),
    );
  }, [snapshotQuery]);

  const renderItem = (item: OrderFirestoreWithId, index: number) => {
    return (
      <CardItem
        variant='outlined'
        key={`${item.order_id}-${index}`}
        onClick={() => {
          history.push('/status-order', {
            orderId: item.order_id,
          });
        }}
        fullWidth
      >
        <ContentCard>
          {item.service_type && (
            <Avatar alt={item.service_type} src={getIconService(item.service_type)} style={{ width: 52, height: 52 }} />
          )}

          <TextCard>{item.service_type}</TextCard>
        </ContentCard>

        <CardData>
          <TextCard>Placa</TextCard>
          <TextCard>{item.license_plate}</TextCard>
          {item.association_code && <TextCard fontSize={12}>({item.association_code})</TextCard>}
        </CardData>
        <ChevronRight style={{ color: Colors.primary }} />
      </CardItem>
    );
  };

  if (!ordersList) {
    return <Label>Não há solicitações em execução.</Label>;
  }

  return <Container>{ordersList.map(renderItem)}</Container>;
};

export default OrdersInExecution;
