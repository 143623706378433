import axios from 'axios';
import { store } from '~/store';
import { refreshTokenSuccess, logout } from '~/store/ducks/auth';
import { appendQueryParamsTo } from './urlutils';

const api = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;
    if (error?.response?.data?.exp === 'token expired') {
      const { refresh_token } = store.getState().auth.authorization;

      return new Promise((resolve, reject) => {
        api
          .post('/identity-providers/CUSTOMER/authentications:refresh', {
            refresh_token,
          })
          .then(({ data }) => {
            store.dispatch(refreshTokenSuccess(data));
            originalRequest.headers.Authorization = `Bearer ${data.id_token}`;
            resolve(axios(originalRequest));
          })
          .catch(err => {
            reject(err);
            store.dispatch(logout());
          });
      });
    }
    return Promise.reject(error);
  },
);

api.interceptors.request.use(config => {
  const token = store.getState().auth.authorization.id_token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export function loginApi(username, password) {
  return api.post('/identity-providers/CUSTOMER/authentications', {
    username,
    password,
  });
}

export function setAuthorizationHeader(token) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export const forgotApi = username => {
  return api.post('/identity-providers/CUSTOMER/authentications:forgottenPassword', { username });
};

export const resetPasswordApi = (username, password, resetCode) => {
  return api.post('/identity-providers/CUSTOMER/authentications:resetPassword', {
    username,
    new_password: password,
    reset_code: resetCode,
  });
};

export const resendConfirmationCodeApi = username => {
  return api.post(`/identity-providers/CUSTOMER/authentications/${username}:resendConfirmationCode`);
};

export const changePasswordApi = (username, currentPassword, newPassword) => {
  return api.post(`/identity-providers/CUSTOMER/authentications/${username}:changePassword`, {
    old_password: currentPassword,
    new_password: newPassword,
  });
};

export const loadServices = () => {
  return api.get('services');
};

export function loadOpportunities(status, from, to) {
  if (Array.isArray(status)) {
    return loadOpportunitiesInSeveralStatuses(status, from, to);
  }
  const source = axios.CancelToken.source();
  const promise = api.get(`orders?status=${status}${from && to ? `&from=${from}&to=${to}` : ''}`, {
    cancelToken: source.token,
  });
  promise.cancel = () => source.cancel();
  return promise;
}

export function loadOpportunitiesInSeveralStatuses(statuses = [], from, to) {
  const source = axios.CancelToken.source();

  const promise = api.get(appendQueryParamsTo('orders', { status: statuses, from, to }), {
    cancelToken: source.token,
  });
  promise.cancel = () => source.cancel();
  return promise;
}

export function loadCustomerApi(customerId) {
  return api.get(`customers/${customerId}:fetchDetails`);
}

export function resetCustomer(username, confirmation_type) {
  return api.post(`identities/CUSTOMER/${username}:resetAlias`, { confirmation_type });
}

export function deleteCustomer(username) {
  return api.post(`identities/CUSTOMER/${username}:delete`);
}

export function loadQuizAnswers(orderId) {
  return api.get(`quiz-answers/orders/${orderId}`);
}

export function loadOrderDocuments(orderId) {
  return api.get(`documents/orders/${orderId}`);
}

export function loadOrderDetails(orderId, type) {
  return api.get(`order-details/orders/${orderId}`, { params: { type } });
}

export function loadOrderCosts(orderId) {
  return api.get(`cost-parameters/orders/${orderId}`);
}

export const deleteCustomerVehicleApi = vehicleId => {
  return api.delete(`customers/vehicles/${vehicleId}`);
};

export const getVehicleBySocialSecurityApi = socialSecurity => {
  return api.get(`customers/vehicles/social-security/${socialSecurity}`);
};

export const getQuizzByAssociationServiceApi = (associationId, serviceId) => {
  return api.get(`/quizzes/associations/${associationId}/services/${serviceId}`);
};

export const getQuizzByServiceApi = serviceId => {
  return api.get(`/quizzes/services/${serviceId}`);
};

export const validateRegionsServiceApi = data => {
  return api.post('service-regions/within', data);
};

export const getTimeZoneByGoogleApi = (API_KEY, latitude, longitude) => {
  return axios.get(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=1458000000&key=${API_KEY}`,
  );
};

export const maplinkTripApi = data => {
  return api.post('trip', data);
};

export const mapCalculatorApi = data => {
  return api.post('customers/calculation', data);
};

export const getAllPaymentMethodsApi = () => {
  return api.get('payment-methods');
};

export const createOrderApi = data => {
  return api.post('orders', data);
};

export function addNoteByOrderId(orderId, note) {
  return api.put(`orders/${orderId}:addAnnotation`, { note });
}
export const uploadFileToOrderAPI = data => {
  return api.put('documents/orders/photo-file:upload', data);
};

export const loadVehicleBenefits = (associationId, serviceId, socialSecurity, vehicleId) => {
  return api.get(
    `benefits/associations/${associationId}/services/${serviceId}/social-security/${socialSecurity}/vehicle/${vehicleId}`,
  );
};

export const loadVehicleBenefitQuota = data => {
  return api.post(`orders/vehicles:validateBenefitsQuota`, data);
};

export const getVehicleTypesApi = () => {
  return api.get('/customers/vehicles/types');
};

export const getFipeManufacturersByCategoryApi = categoryName => {
  return api.get(`/integrations/FIPE/categories/${categoryName}/manufacturers`);
};

export const getFipeVehiclesByManufacturersAndCategoryApi = (categoryName, manufacturerId) => {
  return api.get(`/integrations/FIPE/categories/${categoryName}/manufacturers/${manufacturerId}/vehicles`);
};

export const getFipeModelsByVehiclesAndManufacturersAndCategoryApi = (categoryName, manufacturerId, vehicleId) => {
  return api.get(
    `/integrations/FIPE/categories/${categoryName}/manufacturers/${manufacturerId}/vehicles/${vehicleId}/models`,
  );
};

export const createCustomerVehicleApi = data => {
  return api.post('customers/vehicles', data);
};

export const changeEmailApi = (email, access_token) => {
  return api.patch('/identities/CUSTOMER:changeEmail', {
    email,
    access_token,
  });
};

export const changePhoneNumberApi = (phone_number, access_token) => {
  return api.patch('/identities/CUSTOMER:changePhoneNumber', {
    phone_number,
    access_token,
  });
};

export const resendAliasConfirmationCodeApi = data => {
  return api.post('identity-providers/CUSTOMER/authentications:resendAliasConfirmationCode', data);
};

export const changeEmailConfirmation = (userName, confirmation_code, confirmation_type, access_token) => {
  return api.post(`/identity-providers/CUSTOMER/authentications/${userName}:confirmAlias`, {
    confirmation_code,
    confirmation_type,
    access_token,
  });
};

export const confirmSignUpApi = (username, confirmationCode) => {
  return api.post(`/identity-providers/CUSTOMER/authentications/${username}:confirmSignUp`, {
    confirmation_code: confirmationCode,
  });
};

export const loadAssociationBalance = associationId => {
  return api.get(`associations/${associationId}:getAccountStatement`);
};

export const getOrderCancelReasonsApi = () => {
  return api.get('cancellation-reasons', { params: { owner: 'CUSTOMER' } });
};

export const cancelOrderByReasonApi = (orderId, data) => {
  return api.post(`orders/${orderId}:cancel`, data);
};

export const getIncomingByContractorIdApi = contractorId => {
  return api.get(`contractors/${contractorId}:fetchIncomingContractor`);
};

export const createSurveyContractorApi = data => {
  return api.post('contractors/survey', data);
};

export const signUpApi = user => {
  return api.post('/identities/CUSTOMER', user);
};

export default api;
