import { Grid } from '@material-ui/core';
import React from 'react';

import { WithChildren } from '~/types';

interface GridContainerProps extends WithChildren {}

export function GridContainer({ children }: GridContainerProps) {
  return (
    <Grid container spacing={2} style={{ paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
      {children}
    </Grid>
  );
}
