import styled from 'styled-components';

import Colors from '~/styles/colors';

interface LabelProps {
  fontWeight?: string;
  marginTop?: number;
  marginLeft?: number;
  fontSize?: number;
  marginBottom?: number;
  textAlign?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85vh;
  position: relative;
`;

export const Contractor = styled.div`
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

export const Label = styled.p<LabelProps>`
  color: ${Colors.primary};
  font-weight: ${props => props.fontWeight ?? 'normal'};
  margin-top: ${props => props.marginTop ?? 0}px;
  margin-left: ${props => props.marginLeft ?? 0}px;
  font-size: ${props => props.fontSize ?? 14}px;
  margin-bottom: ${props => props.marginBottom ?? 0}px;
  text-align: ${props => props.textAlign ?? 'left'};
`;

export const ViewUserData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ViewUser = styled.div`
  margin-left: 8px;
`;

export const ProfileImage = styled.img`
  border-radius: 50px;
  margin-right: 5px;
`;
