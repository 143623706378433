import styled from 'styled-components';
import { DialogContent } from '@material-ui/core';
import React from 'react';

interface ContentProps {
  flex?: boolean;
  align?: React.CSSProperties['alignItems'];
}

export const Content = styled(DialogContent)<ContentProps>`
  ${({ flex }) => (flex ? `display: 'flex';` : '')}
  ${({ align }) => (align ? `align-items: ${align};` : '')}
`;
