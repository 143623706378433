import produce from 'immer';

import { AssociationBalance } from '~/services/hooks';

const AssociationBalanceType = {
  SET_BALANCE: '@associationBalance/SET_BALANCE',
  CLEAR_BALANCE: '@associationBalance/CLEAR_BALANCE',
  SET_BALANCE_ERROR: '@associationBalance/SET_BALANCE_ERROR',
} as const;

type SetBalance = ReturnType<typeof setBalance>;
type ClearBalance = ReturnType<typeof clearBalance>;
type SetBalanceError = ReturnType<typeof setBalanceError>;

type BalanceActions = SetBalance | ClearBalance | SetBalanceError;

type InitialState = AssociationBalance & {
  error: boolean;
  errorMessage?: string;
};

const INITIAL_STATE: InitialState = {
  account_statement: {
    available_balance: 0,
  },
  error: false,
};

export const setBalance = (data: AssociationBalance) => {
  return {
    type: AssociationBalanceType.SET_BALANCE,
    payload: { data },
  };
};

export const clearBalance = () => {
  return {
    type: AssociationBalanceType.CLEAR_BALANCE,
  };
};

export const setBalanceError = (message: string) => {
  return {
    type: AssociationBalanceType.SET_BALANCE_ERROR,
    payload: { message },
  };
};

export function associationBalance(state = INITIAL_STATE, action: BalanceActions) {
  switch (action.type) {
    case AssociationBalanceType.SET_BALANCE:
      return produce(state, draft => {
        draft.account_statement = action.payload.data.account_statement;
        draft.error = false;
        draft.errorMessage = undefined;
      });

    case AssociationBalanceType.CLEAR_BALANCE:
      return produce(state, draft => {
        draft.account_statement = {
          available_balance: 0,
        };
        draft.error = false;
        draft.errorMessage = undefined;
      });

    case AssociationBalanceType.SET_BALANCE_ERROR:
      return produce(state, draft => {
        draft.error = true;
        draft.errorMessage = action.payload.message;
      });

    default:
      return state;
  }
}
