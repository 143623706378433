import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';
import { cancelOrderByReasonApi, getOrderCancelReasonsApi } from '~/services/api';
import { useFirestore } from '~/services/hooks/useFirestore';
import { CancelationReason } from '~/types';
import Button from '~/components/Button';
import Colors from '~/styles/colors';
import { DialogActions } from './styles';
import Loading from '../Loading';

interface ModalCancelProps extends DialogProps {
  data: {
    orderId: string;
    cost: {
      surcharge?: number;
    };
    onConfirm: () => void;
    onCancel: () => void;
    onError: () => void;
    redirectTo: () => void;
  };
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
  },
}));

const ModalCancel = (props: ModalCancelProps) => {
  const {
    data: { orderId, cost, redirectTo, onError, onCancel, onConfirm },
  } = props;

  const [selectedReason, setSelectedReason] = useState<string>('');
  const [cancelReasons, setCancelReasons] = useState<CancelationReason[]>();
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const dispatch = useDispatch();
  const { addToast } = useToast();
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { setDocument } = useFirestore();

  useEffect(() => {
    const callApis = async () => {
      try {
        const responseCancel = await getOrderCancelReasonsApi();

        setCancelReasons(responseCancel.data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar a ordem, tente novamente');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });

        onError();
      } finally {
        setLoading(false);
      }
    };

    callApis();
  }, [addToast, dispatch, onError]);

  const handleCancel = useCallback(async () => {
    try {
      if (!selectedReason) {
        onCancel();
        addToast({
          title: 'Selecione uma opção',
          type: 'error',
        });
        return;
      }

      setSaveLoading(true);

      const reasonFound = cancelReasons?.find(item => item.id === selectedReason);

      if (!reasonFound) {
        return;
      }

      await setDocument('orders', orderId, { canceled_by_user: true }, { merge: true });
      await cancelOrderByReasonApi(orderId, { reason: reasonFound.reason, penalty_charge: reasonFound.penalty_charge });
      onCancel();
      redirectTo();
    } catch (error) {
      const errorMessages = handleApiErrorResponse(error, 'Erro ao cancelar o convite, tente novamente');

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });

      onError();
    } finally {
      setSaveLoading(false);
      setLoading(false);
    }
  }, [addToast, cancelReasons, onCancel, onError, orderId, redirectTo, selectedReason, setDocument]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Dialog maxWidth='xl' fullScreen={fullscreen} disableScrollLock={true} onClose={_ => handleCancel()} {...props}>
      <div className={classes.container}>
        <DialogTitle className={classes.title}>Tem certeza de que deseja cancelar essa solicitação?</DialogTitle>

        <Typography style={{ color: 'red', textAlign: 'center', marginBottom: 12, fontWeight: 'bold' }}>
          {cost?.surcharge ? 'Pode ser gerado uma taxa de cancelamento!' : 'Pode ser gerado um consumo da cota!'}
        </Typography>

        <Select
          value={selectedReason}
          onChange={e => {
            setSelectedReason(String(e.target.value));
          }}
          style={{ fontSize: 14 }}
        >
          {cancelReasons?.map(reason => (
            <MenuItem key={reason.id} value={reason.id}>
              {reason.reason}
            </MenuItem>
          ))}
        </Select>

        <DialogActions>
          <Button
            type='button'
            label='Fechar'
            disabled={saveLoading}
            width='30%'
            backgroundColor={Colors.backButton}
            color={Colors.white}
            onClick={onConfirm}
          />

          <Button
            type='button'
            backgroundColor={Colors.primary}
            color={Colors.white}
            label='Cancelar'
            onClick={handleCancel}
            width='30%'
            loading={saveLoading}
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ModalCancel;
