import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Like from '~/assets/svg/like.svg';
import Sad from '~/assets/svg/sad.svg';
import ImgHoldMobile from '~/assets/images/hold-mobile-pix.png';
import { cancelOrderByReasonApi } from '~/services/api';
import { useToast } from '~/services/hooks/toast';
import { useFirestore } from '~/services/hooks/useFirestore';
import Colors from '~/styles/colors';
import { PaymentsFirestore } from '~/types';
import {
  // BackgroundWithColor,
  Card,
  Container,
  HoldMobileImage,
  ImageContainer,
  PixButton,
  TextCard,
  TextCode,
  TextCodeContainer,
  TextMessage,
  TextMessageContainer,
  TextTitle,
  Icon,
} from './styles';
import Loading from '~/components/Loading';

interface OrderStatusProps {
  status?: PaymentsFirestore['status'];
  title: string;
  message: string;
}

const STATUS_MESSAGE: OrderStatusProps[] = [
  {
    status: 'PAYMENT_PENDING',
    title: 'Aguardando aprovação do pagamento',
    message:
      'Clique no código ou no botão abaixo para copiar o código e pagar via PIX em qualquer aplicativo habilitado.',
  },
  {
    status: 'PRE_AUTHORIZATION',
    title: 'Aprovação do pagamento em andamento',
    message: 'Aguarde só mais um pouco, seu pagamento já está sendo processado.',
  },
  {
    status: 'PAYMENT_APPROVED',
    title: 'PAGAMENTO APROVADO',
    message: 'Tudo certo. Aguarde alguns instantes, seu chamado está sendo processado.',
  },
  {
    status: 'PAYMENT_REFUSED',
    title: 'FALHA NO PAGAMENTO',
    message: 'Tente novamente através de uma nova forma de pagamento.',
  },
  {
    status: 'CANCELED',
    title: 'PAGAMENTO CANCELADO',
    message: 'Sua ordem foi cancelada.',
  },
];

export default function Pix() {
  const history = useHistory();
  const { orderId } = (useLocation().state || {}) as { orderId?: string };
  const { addToast } = useToast();
  const { snapshot: snapshotPayment, removeDocument } = useFirestore<PaymentsFirestore>('payments', orderId, false);

  const [orderStatus, setOrderStatus] = useState(STATUS_MESSAGE[0]);
  const [code, setCode] = useState<string>();

  useEffect(() => {
    if (!orderId) {
      history.replace('/');
    }
  }, [history, orderId]);

  useEffect(() => {
    setCode(snapshotPayment?.gatewayDetails?.pixText);
    snapshotPayment?.status &&
      setOrderStatus(STATUS_MESSAGE[STATUS_MESSAGE.findIndex(st => st.status === snapshotPayment.status)]);
  }, [snapshotPayment]);

  useEffect(() => {
    switch (orderStatus?.status) {
      case 'CANCELED':
        history.replace('/pix');
        break;

      case 'PAYMENT_REFUSED':
        if (orderId) {
          removeDocument('payments', orderId);
        }
        break;

      case 'PAYMENT_APPROVED':
        addToast({
          title: 'Pagamento aprovado!',
          type: 'info',
        });
        history.replace('/');
        break;

      default:
        break;
    }
  }, [addToast, history, orderId, orderStatus, removeDocument]);

  const copyToClipboard = (content?: string) => {
    if (!content) {
      return;
    }

    navigator.clipboard.writeText(content);
    addToast({
      title: 'Código copiado com sucesso!',
      type: 'info',
    });
  };

  const cancelOrder = async () => {
    const data = {
      reason: 'Canceled by Payment Refused PIX',
      penalty_charge: false,
    };

    await cancelOrderByReasonApi(orderId, data);
    history.goBack();
  };

  return (
    <Container>
      {orderStatus?.status === 'PAYMENT_PENDING' || orderStatus?.status === 'PRE_AUTHORIZATION' ? (
        <>
          <ImageContainer>
            <HoldMobileImage src={ImgHoldMobile} />
          </ImageContainer>

          <Card>
            {code ? (
              <>
                <TextTitle>{orderStatus?.title}</TextTitle>

                <TextCard>{orderStatus?.message}</TextCard>

                <TextCodeContainer cursor='pointer' onClick={() => copyToClipboard(code)}>
                  <TextCode>{code?.slice(0, 60)}...</TextCode>
                  <Icon />
                </TextCodeContainer>

                <PixButton
                  type='button'
                  label='Copiar Código'
                  variant='filled'
                  onClick={() => copyToClipboard(code)}
                  backgroundColor={Colors.primary}
                  color={Colors.white}
                />
              </>
            ) : (
              <>
                <TextCodeContainer>
                  <TextCode>Estamos criando seu código, isso pode demorar um pouco, aguarde por favor.</TextCode>
                  <Loading size={16} />
                </TextCodeContainer>
              </>
            )}
          </Card>
        </>
      ) : (
        <Card>
          <TextMessageContainer>
            {orderStatus?.status === 'PAYMENT_REFUSED' ? <img src={Sad} alt='Sad' /> : <img src={Like} alt='Like' />}

            <TextMessage>{orderStatus?.message}</TextMessage>
          </TextMessageContainer>

          <TextTitle style={{ color: Colors.white }}>{orderStatus?.title}</TextTitle>
          {orderStatus?.status === 'PAYMENT_REFUSED' ? (
            <PixButton
              text='Solicitar o serviço novamente'
              color='primary'
              variant='filled'
              size='large'
              onPress={cancelOrder}
            />
          ) : (
            orderStatus?.status === 'PAYMENT_APPROVED' && <Loading />
          )}
        </Card>
      )}
    </Container>
  );
}
