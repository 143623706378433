import { useEffect } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';

import { db } from '~/config/firebase';
import { clearSnapshot, setSnapShot } from '~/store/ducks/snapShot';
import { OrderFirestore, OrderFirestoreWithId } from '~/types';
import { useHistory } from 'react-router-dom';

export default function UserOrders() {
  const { useName, snapshot } = useSelector(state => ({
    useName: state.auth.user['cognito:username'],
    snapshot: state.snapshot,
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!useName) {
      dispatch(clearSnapshot());
      return;
    }

    const queryFirestore = query(collection(db, 'orders'), where('customer_id', '==', useName));
    const unsubscribe = onSnapshot(queryFirestore, snapshotFire => {
      if (snapshotFire.empty) {
        dispatch(clearSnapshot());
        return;
      }

      const snapArray: OrderFirestoreWithId[] = snapshotFire.docs.map(doc => {
        const docData = doc.data() as OrderFirestore;

        return {
          order_id: doc.id,
          service_type: docData.service_type,
          license_plate: docData.license_plate,
          status: docData.status,
          device_id: docData.device_id,
          contractor_id: docData.contractor_id,
          customer_survey: docData.customer_survey,
          service_displace: docData.service_displace,
          canceled_by_user: docData.canceled_by_user,
          latitude: docData.latitude,
          longitude: docData.longitude,
        };
      });

      dispatch(setSnapShot(snapArray));
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, useName]);

  useEffect(() => {
    snapshot.snapShot
      ?.filter(item => item.updated)
      .forEach(item => {
        switch (item.status) {
          case 'ALLOCATING':
          case 'MANUAL_ALLOCATION':
            history.push('/update-order', {
              orderId: item.order_id,
              serviceType: item.service_type,
              licensePlate: item.license_plate,
              screenAfter: '/waiting-searching-contractor',
              message: 'A solicitação está aguardando o prestador.',
              paramsScreenAfter: {
                orderId: item.order_id,
              },
            });
            break;

          case 'CHECKIN':
          case 'ON_GOING':
            history.push('/update-order', {
              orderId: item.order_id,
              serviceType: item.service_type,
              licensePlate: item.license_plate,
              screenAfter: '/tracking',
              message: 'A solicitação está com o prestador em trânsito.',
              paramsScreenAfter: {
                orderId: item.order_id,
                deviceId: item.device_id,
                contractorId: item.contractor_id,
                serviceDisplace: item.service_displace,
                destination: {
                  latitude: item.latitude,
                  longitude: item.longitude,
                },
              },
            });
            break;

          case 'IN_EXECUTION':
            if (!item.service_displace) {
              history.push('/update-order', {
                orderId: item.order_id,
                serviceType: item.service_type,
                licensePlate: item.license_plate,
                screenAfter: '/waiting-contractor-pictures',
                message: 'A solicitação está aguardando o prestador fotografar o veículo.',
              });
              break;
            }

            history.push('/update-order', {
              orderId: item.order_id,
              serviceType: item.service_type,
              licensePlate: item.license_plate,
              screenAfter: '/tracking',
              message: 'A solicitação está com o prestador em trânsito.',
              paramsScreenAfter: {
                orderId: item.order_id,
                deviceId: item.device_id,
                contractorId: item.contractor_id,
                serviceDisplace: item.service_displace,
                destination: {
                  latitude: item.latitude,
                  longitude: item.longitude,
                },
              },
            });
            break;

          case 'CHECKOUT':
            history.push('/update-order', {
              orderId: item.order_id,
              serviceType: item.service_type,
              licensePlate: item.license_plate,
              message: 'A solicitação está aguardando o prestador fotografar o veículo.',
              screenAfter: '/waiting-contractor-pictures',
            });
            break;

          case 'FINISHED':
            if (item.customer_survey) {
              break;
            }

            history.push('/update-order', {
              orderId: item.order_id,
              serviceType: item.service_type,
              licensePlate: item.license_plate,
              message: 'A solicitação está aguardando sua avaliação.',
              screenAfter: '/contractor-evaluation',
              paramsScreenAfter: {
                orderId: item.order_id,
              },
            });
            break;

          case 'PLATE_CONFIRMED':
            history.push('/update-order', {
              orderId: item.order_id,
              serviceType: item.service_type,
              licensePlate: item.license_plate,
              message: 'A solicitação está aguardando a confirmação da rota.',
              screenAfter: '/route-confirmation',
              paramsScreenAfter: {
                orderId: item.order_id,
              },
            });
            break;

          case 'CANCELED':
            if (!item.canceled_by_user) {
              history.push('/request-canceled', {
                orderId: item.order_id,
                serviceType: item.service_type,
                licensePlate: item.license_plate,
                gotoTop: false,
              });
            }
            break;

          default:
            break;
        }
      });
  }, [history, snapshot]);

  return null;
}
