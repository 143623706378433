import styled from 'styled-components';
import Colors from '~/styles/colors';

interface DividerProps {
  height?: number;
  width?: number;
}

export const TitleModal = styled.h1`
  font-size: 22px;
  color: ${Colors.darkBlue};
  text-align: center;
  margin-bottom: 15px;
`;

export const Divider = styled.div<DividerProps>`
  ${({ height }) => (height ? `height: ${height}px` : '')};
  ${({ width }) => (width ? `width: ${width}px;` : '')};
`;
