import styled from 'styled-components';
import { TextField, Input as BaseInput } from '@material-ui/core';

import Colors from '~/styles/colors';

export const Container = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  position: relative;
  flex: 1;
  width: 100%;

  p {
    font-size: 11px;
    margin-top: 0px;
  }
`;

export const InputText = styled(TextField)`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.white)};

  & label.Mui-focused {
    color: #999;
  }

  & .MuiInput-underline::after {
    border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.blue)};
  }

  && .MuiInput-underline:hover::before {
    border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.blue)};
  }
`;

export const Input = styled(BaseInput)`
  &&:hover:not(.Mui-disabled)::before {
    border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.blue)};
    transition: none;
  }

  &.Mui-focused::after {
    border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.blue)};
  }

  &&::after {
    border-bottom: 1px solid ${({ error }) => (error ? 'red' : Colors.blue)};
  }
`;
