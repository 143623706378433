import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Typography as TypographyBase, TypographyProps as TypographyPropsBase } from '@material-ui/core';

interface TypographyProps extends TypographyPropsBase {
  data?: { textAlign?: CSSProperties['textAlign'] };
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85vh;
`;

export const ViewContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ViewButton = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: space-around;
`;

export const Typography = styled(TypographyBase)<TypographyProps>`
  ${({ data }) => (data && data.textAlign ? `text-align: ${data.textAlign};` : '')}
`;
