export const removeFormat = (text, length = 14) => {
  if (!text) {
    return '';
  }

  return text.replace(/\D/g, '').substring(0, length);
};

export const formatCPF = valor => {
  return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};

export const formatCNPJ = s => {
  return s.length > 12
    ? s.replace(/(^\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2}$)/g, '$1.$2.$3/$4-$5')
    : s.length > 8
    ? s.replace(/(^\d{2})(\d{3})(\d{3})(\d{0,4}$)/g, '$1.$2.$3/$4')
    : s.length > 5
    ? s.replace(/(^\d{2})(\d{3})(\d{0,3}$)/g, '$1.$2.$3')
    : s.length > 2
    ? s.replace(/(^\d{2})(\d{0,3}$)/g, '$1.$2')
    : s;
};

export const formatUsername = (text, onlyCNPJ = false, onlyCPF = false) => {
  if (!text) {
    return '';
  }

  let textFormated = removeFormat(text);

  if (onlyCPF) {
    textFormated = textFormated.substr(0, 11);
  }

  if (textFormated.length <= 11 && !onlyCNPJ) {
    textFormated = formatCPF(textFormated);
  } else if (!onlyCPF) {
    textFormated = formatCNPJ(textFormated);
  }
  return textFormated;
};

export const replaceSpecialChars = str => {
  if (!str) {
    return;
  }

  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
    .replace(/--+/g, '-') // Substitui multiplos hífens por um único hífen
    .replace(/(^-+|-+$)/, '')
    .toLowerCase(); // Remove hífens extras do final ou do inicio da string
};

export function formatCellPhone(phone) {
  if (!phone) {
    return '';
  }

  return phone
    .replace(/^0/, '')
    .replace(/(\d{2})(\d{4,5})(\d{4})/, '($1)$2-$3')
    .trim();
}

export const onlyNumbers = text => text.replace(/[^0-9]+/g, '');

export const removeSpaces = text => text.replace(/\s/g, '');

export const splitWords = (phrase, wordsNumber) => {
  if (!phrase) {
    return;
  }

  return phrase.split(' ').slice(0, wordsNumber).join(' ');
};
