import React from 'react';

import { Container, ViewLabel, Label, Spacer, Camera } from './styles';
import CameraSvg from '~/assets/svg/camera.svg';

const WaitingContractorPictures = () => {
  return (
    <Container>
      <Spacer flex={2} />

      <ViewLabel>
        <Label fontWeight='bold' fontSize={24}>
          {'O PRESTADOR\nESTÁ FOTOGRAFANDO\nO VEICULO!'}
        </Label>

        <Label fontSize={24} marginTop={20}>
          {'AGUARDE ENQUANTO\nFINALIZA!'}
        </Label>
      </ViewLabel>

      <Spacer />

      <Camera src={CameraSvg} />

      <Spacer flex={2} />
    </Container>
  );
};

export default WaitingContractorPictures;
