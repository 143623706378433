import produce from 'immer';

import { OrderFirestoreWithId } from '~/types';

const SnapshotType = {
  SET_SNAPSHOT: '@snapshot/SET_SNAPSHOT',
  CLEAR_SNAPSHOT: '@snapshot/CLEAR_SNAPSHOT',
  SET_SNAPSHOT_ERROR: '@snapshot/SET_SNAPSHOT_ERROR',
} as const;

type SetSnapshot = ReturnType<typeof setSnapShot>;
type ClearSnapshot = ReturnType<typeof clearSnapshot>;

type BalanceActions = SetSnapshot | ClearSnapshot;

type OrderFirestoreWithIdReducer = OrderFirestoreWithId & {
  updated?: boolean;
};

type InitialState = {
  snapShot?: OrderFirestoreWithIdReducer[];
  error: boolean;
  errorMessage?: string;
};

export type SnapshotReducerType = InitialState;

const INITIAL_STATE: InitialState = {
  error: false,
  snapShot: undefined,
  errorMessage: undefined,
};

export const setSnapShot = (data: OrderFirestoreWithId[]) => {
  return {
    type: SnapshotType.SET_SNAPSHOT,
    payload: { data },
  };
};

export const clearSnapshot = () => {
  return {
    type: SnapshotType.CLEAR_SNAPSHOT,
  };
};

export function snapshot(state = INITIAL_STATE, action: BalanceActions) {
  switch (action.type) {
    case SnapshotType.SET_SNAPSHOT:
      return produce(state, draft => {
        draft.snapShot = action.payload.data.map(item => {
          const foundOrderSameState =
            state.snapShot?.findIndex(
              currentItem => currentItem.order_id === item.order_id && currentItem.status === item.status,
            ) ?? -1;

          if (foundOrderSameState >= 0) {
            return { ...item, updated: false };
          }

          return { ...item, updated: true };
        });
        draft.error = false;
        draft.errorMessage = undefined;
      });

    case SnapshotType.CLEAR_SNAPSHOT:
      return produce(state, (draft: any) => {
        Object.entries(INITIAL_STATE).forEach(([key, value]) => {
          draft[key] = value;
        });
      });

    // case SnapshotType.SET_SNAPSHOT_ERROR:
    //   return produce(state, draft => {
    //     draft.error = true;
    //     draft.errorMessage = action.payload.message;
    //   });

    default:
      return state;
  }
}
