import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Title, Input } from './styles';

import { logout, resendAliasConfirmationCodeRequest } from '~/store/ducks/auth';

import { removeSpaces } from '~/util/format';
import { changeEmailConfirmation } from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import Button from '~/components/Button';
import { useToast } from '~/services/hooks/toast';
import Colors from '~/styles/colors';

const EmailRequired = () => {
  const [confirmationCode, setConfirmationCode] = useState<string>();

  const { user, access_token, resendAlias } = useSelector(state => ({
    user: state?.auth?.user,
    access_token: state?.auth?.authorization?.access_token,
    resendAlias: state.auth.resendAlias,
  }));

  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToast();
  const location = useLocation();

  const handleLogout = () => {
    history.push('/');
    dispatch(logout());
  };

  const validation = useCallback(
    error => {
      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: error,
      });
    },
    [addToast],
  );

  const submitForm = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      try {
        if (!confirmationCode?.trim()) {
          return validation('Código de Confirmação não informado!');
        }

        await changeEmailConfirmation(
          user['cognito:username'],
          confirmationCode,
          !user.email_verified && user.email ? 'EMAIL' : 'PHONE_NUMBER',
          access_token,
        );

        addToast({
          title: 'Faça login no sistema',
          type: 'info',
        });

        history.push('/');
        dispatch(logout());
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error);

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      }
    },
    [access_token, addToast, confirmationCode, dispatch, history, user, validation],
  );

  const handleResendConfirmationCode = useCallback(async () => {
    dispatch(
      resendAliasConfirmationCodeRequest({
        confirmation_type: !user.email_verified && user.email ? 'EMAIL' : 'PHONE_NUMBER',
        access_token,
      }),
    );
  }, [access_token, dispatch, user.email, user.email_verified]);

  useEffect(() => {
    if (location.pathname === '/login') {
      history.replace('/');
    }
  }, [history, location.pathname]);

  useEffect(() => {
    if (resendAlias.success) {
      addToast({ title: 'Novo Código Enviado!', type: 'success' });
    }

    if (resendAlias.error) {
      addToast({ title: 'Erro Inesperado', type: 'error', description: resendAlias.message });
    }
  }, [addToast, resendAlias]);

  return (
    <form onSubmit={submitForm} autoComplete='off'>
      <Title>
        {user.name}, enviamos
        {!user.email_verified && user.email ? ' por e-mail' : ' por SMS'} um código de verificação!
      </Title>

      <Input
        name='confirmation-code'
        label='Código de Validação'
        value={confirmationCode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmationCode(removeSpaces(e.target.value))}
        autoFocus
      />

      <Button type='submit' backgroundColor={Colors.primary} color={Colors.white} width='100%' label='Validar código' />

      <Button
        type='button'
        filled
        label='Não recebeu? Clique aqui para reenviar!'
        onClick={handleResendConfirmationCode}
      />

      <Button
        type='button'
        label='Sair'
        backgroundColor={Colors.white}
        color={Colors.primary}
        onClick={handleLogout}
        border={`1px solid ${Colors.blue}`}
        width='100%'
      />
    </form>
  );
};

export default EmailRequired;
