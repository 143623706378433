import { useCallback, useState } from 'react';

import { getQuizzByAssociationServiceApi, getQuizzByServiceApi } from '../api';

import { handleApiErrorResponse } from '../handleErrors';

import { useToast } from './toast';

import { QuizAssociationResponse } from '~/types';

export function useQuizAssociation() {
  const [loadQuiz, setLoadQuiz] = useState(false);

  const { addToast } = useToast();

  const getQuiz = useCallback(
    async (associationId?: string, serviceId?: string): Promise<QuizAssociationResponse[] | undefined> => {
      try {
        setLoadQuiz(true);

        if (associationId && serviceId) {
          const response = await getQuizzByAssociationServiceApi(associationId, serviceId);

          return response.data;
        } else if (serviceId) {
          const response = await getQuizzByServiceApi(serviceId);

          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar o quiz!');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadQuiz(false);
      }
    },
    [addToast],
  );

  return {
    getQuiz,
    loadQuiz,
  };
}
