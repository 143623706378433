import { useState, useEffect, useCallback } from 'react';
import { CustomerVehicle } from '~/types';
import { getVehicleBySocialSecurityApi } from '../api';

export function useCustomerVehicles(customerId?: string) {
  const [customerVehicles, setCustomerVehicles] = useState<CustomerVehicle[]>([]);
  const [loadingVehicles, setLoadingVehicles] = useState(false);

  const getVehicleBySocialSecurity = useCallback(async (socialSecurity?: string) => {
    if (!socialSecurity) {
      setCustomerVehicles([]);
      return;
    }

    try {
      setLoadingVehicles(true);
      const { data } = await getVehicleBySocialSecurityApi(socialSecurity);
      setCustomerVehicles(data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingVehicles(false);
    }
  }, []);

  useEffect(() => {
    getVehicleBySocialSecurity(customerId);
  }, [customerId, getVehicleBySocialSecurity]);

  return {
    customerVehicles,
    loadingVehicles,
    setCustomerVehicles,
    getVehicleBySocialSecurity,
  };
}
