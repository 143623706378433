import styled from 'styled-components';
import { IconButton as IconButtonBase } from '@material-ui/core';
import { Phone as PhoneBase } from '@material-ui/icons';

import Colors from '~/styles/colors';

interface IconButtonProps {
  top?: number;
}

export const IconButton = styled(IconButtonBase)<IconButtonProps>`
  position: absolute;
  z-index: 1;
  top: ${({ top }) => top || 30}px;
  right: 20px;
  background-color: ${Colors.primary};
  width: 50px;
  height: 50px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;

  -webkit-box-shadow: 0px 0px 15px -2px #000000;
  box-shadow: 0px 0px 15px -2px #000000;
`;

export const Phone = styled(PhoneBase)`
  color: ${Colors.white};
  font-size: 2.2rem;
  font-weight: bold;
`;
