import React from 'react';

import { Link } from './styles';

export default function NotFound() {
  return (
    <>
      <p style={{ color: '#fff' }}>Página não encontrada</p>
      <Link to='/'>Voltar</Link>
    </>
  );
}
