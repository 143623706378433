import React from 'react';
import { BottomNavigation, Paper } from '@material-ui/core';
import { WithChildren } from '~/types';

interface SimpleBottomNavigationProps extends WithChildren {}

export default function SimpleBottomNavigation({ children }: SimpleBottomNavigationProps) {
  return (
    <>
      <Paper style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels>{children}</BottomNavigation>
      </Paper>
    </>
  );
}
