import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '~/components/Button';
import { useToast } from '~/services/hooks/toast';
import { useFirestore } from '~/services/hooks/useFirestore';
import Colors from '~/styles/colors';
import { OrderFirestore } from '~/types';
import { Container, ViewContent, ViewButton, Typography } from './styles';

interface UpdateOrderParams {
  orderId: string;
  serviceType: string;
  licensePlate: string;
  screenAfter: string;
  message: string;
  paramsScreenAfter: Record<string, string>;
}

const UpdateOrder = () => {
  const { orderId, serviceType, licensePlate, screenAfter, message, paramsScreenAfter } = (useLocation().state ||
    {}) as UpdateOrderParams;

  const { getDocument } = useFirestore<OrderFirestore>();
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!orderId) {
      history.replace('/');
    }
  }, [history, orderId]);

  const handleNextScreen = async () => {
    try {
      setLoading(true);

      const doc = await getDocument('orders', orderId);

      setLoading(false);

      if (!doc) {
        return history.goBack();
      }

      history.replace(screenAfter, {
        ...paramsScreenAfter,
      });
    } catch {
      setLoading(false);
      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: 'Erro ao consultar a solicitação',
      });
    }
  };

  return (
    <Container>
      <ViewContent>
        <Typography variant='subtitle1' gutterBottom>
          Serviço {serviceType}
        </Typography>

        <Typography variant='subtitle1' gutterBottom>
          Placa {licensePlate}
        </Typography>

        <Typography data={{ textAlign: 'center' }} variant='subtitle1' gutterBottom>
          {message}
        </Typography>
      </ViewContent>

      <ViewButton>
        <Button
          type='button'
          label='Voltar'
          disabled={loading}
          width='30%'
          backgroundColor={Colors.backButton}
          color={Colors.white}
          onClick={history.goBack}
        />

        <Button
          type='button'
          backgroundColor={Colors.primary}
          color={Colors.white}
          label='Avançar'
          onClick={handleNextScreen}
          width='30%'
        />
      </ViewButton>
    </Container>
  );
};

export default UpdateOrder;
