import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import SetTransform from './SetTransform';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'picktow-app-web',
      storage,
      // TODO persist snapshot
      // whitelist: ['auth', 'order', 'customerBenefits', 'snapshot'],
      whitelist: ['auth', 'order', 'customerBenefits'],
      transforms: [SetTransform],
    },
    reducers,
  );

  return persistedReducer;
};
