import { useEffect, useState } from 'react';

import { loadServices } from '../api';

import { ServicesResponse } from '~/types';

export function useServices() {
  const [services, setServices] = useState<ServicesResponse[]>();
  const [loadingServices, setLoadingServices] = useState(false);

  useEffect(() => {
    setLoadingServices(true);
    loadServices()
      .then(response => response.data)
      .then(setServices)
      .finally(() => {
        setLoadingServices(false);
      });
  }, []);

  return { services, loadingServices };
}

export default useServices;
