import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAoOBBjKMR7l89FBFKXw3JGRtPmYSZJZHE',
  authDomain: 'picktow-1e30e.firebaseapp.com',
  databaseURL: 'https://picktow-1e30e.firebaseio.com',
  projectId: 'picktow-1e30e',
  storageBucket: 'picktow-1e30e.appspot.com',
  messagingSenderId: '69293472283',
  appId: '1:69293472283:web:8df72f5c47a3bf3ebdd6be',
  measurementId: 'G-PJ5214QTSS',
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
const db = getFirestore(app);

export { db };
