import styled from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';
import Colors from '~/styles/colors';

export const Link = styled(BaseLink)`
  color: ${Colors.white};
  margin-top: 15px;
  transition: opacity 0.3s;
  display: block;
  &:hover {
    opacity: 0.7;
  }
`;
