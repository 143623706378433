import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { ExitToApp, VpnKey, AddCircleOutline } from '@material-ui/icons';
import { List, ListItem, ListItemText, ListItemIcon, Tooltip } from '@material-ui/core';

import history from '~/services/history';

import { logout } from '~/store/ducks/auth';

import { Link, Button } from './styles';

export interface MenuProps {
  toggleDrawer?: (option: boolean) => void;
  menuIsOpen: boolean;
}

export function Menu({ toggleDrawer, menuIsOpen }: MenuProps) {
  const dispatch = useDispatch();

  function handleLink(url: string) {
    history.push(url);
    if (typeof toggleDrawer === 'function') {
      toggleDrawer(false);
    }
  }

  function logoutApp() {
    history.push('/');
    dispatch(logout());
  }

  function Item(props: Omit<MenuItemProps, 'handleLink' | 'menuIsOpen'>) {
    return <MenuItem handleLink={handleLink} menuIsOpen={menuIsOpen} {...props} />;
  }

  return (
    <>
      <List>
        <Item to='/new-opportunity' text='Nova Oportunidade' icon={<AddCircleOutline />} />

        <Item to='/change-password' text='Alterar Senha' icon={<VpnKey />} />

        <ListItem>
          <Button onClick={() => logoutApp()}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </Button>
        </ListItem>
      </List>
    </>
  );
}

export default Menu;

interface MenuItemProps {
  to: string;
  handleLink: (url: string) => void;
  text: string;
  icon: ReactNode;
  menuIsOpen: boolean;
}

function MenuItem(props: MenuItemProps) {
  return (
    <ListItem>
      <Link to={props.to} onClick={() => props.handleLink(props.to)}>
        <Tooltip title={props.text} placement='right' disableHoverListener={props.menuIsOpen}>
          <ListItemIcon>{props.icon}</ListItemIcon>
        </Tooltip>

        <ListItemText>{props.text}</ListItemText>
      </Link>
    </ListItem>
  );
}
