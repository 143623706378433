import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';
import LoginLayout from '~/pages/_layouts/login';

export interface RouterWrapperProps extends RouteProps {
  component: (props: any) => ReactElement;
  layout?: 'DEFAULT' | 'AUTH' | 'LOGIN';
  showFirebaseOrder?: boolean;
  marginTop?: number;
}

export function RouterWrapper({
  component: Component,
  layout,
  showFirebaseOrder = true,
  marginTop,
  ...props
}: RouterWrapperProps) {
  const { authenticated } = useSelector(state => ({
    authenticated: state.auth.authorization.authenticated,
  }));

  let Layout = authenticated ? DefaultLayout : AuthLayout;

  switch (layout) {
    case 'DEFAULT':
      Layout = DefaultLayout;
      break;

    case 'AUTH':
      Layout = AuthLayout;
      break;

    case 'LOGIN':
      Layout = LoginLayout;
      break;

    default:
      break;
  }

  return (
    <Route
      {...props}
      render={props => (
        <Layout showFirebaseOrder={showFirebaseOrder} marginTop={marginTop}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export default RouterWrapper;
