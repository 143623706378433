import {
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  onSnapshot,
  setDoc,
  collection,
  query,
  QueryConstraint,
} from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';

import { db } from '~/config/firebase';

const useFirestore = <T>(collectionPath?: string, documentPath?: string, create = true) => {
  const [snapshot, setSnapshot] = useState<T | undefined>({} as T);
  const [snapshotQuery, setSnapshotQuery] = useState<DocumentData[]>();

  useEffect(() => {
    if (!collectionPath || !documentPath) {
      return undefined;
    }

    const unsubscribe = onSnapshot(
      doc(db, collectionPath, documentPath),
      snapshot => {
        if (!snapshot.exists && create) {
          setDoc(doc(db, collectionPath, documentPath), {});
        }

        setSnapshot(snapshot.data() as T);
      },
      error => {
        console.error(error);
      },
    );

    return unsubscribe;
  }, [collectionPath, create, documentPath]);

  const getDocument = async (collectionPathParam: string, documentPathParam: string) => {
    const docRef = doc(db, collectionPathParam, documentPathParam);

    try {
      const doc = await getDoc(docRef);
      if (doc.exists()) {
        return doc.data() as T;
      } else {
        return undefined;
      }
    } catch (error) {
      console.error('Error getting document:', error);
    }
  };

  const setDocument = async <T = DocumentData>(
    collectionPathParam: string,
    documentPathParam: string,
    data: Record<string, any>,
    options?: {
      merge?: boolean;
    },
  ) => {
    setDoc(doc(db, collectionPathParam, documentPathParam), data, { merge: options?.merge ?? false });
  };

  const removeDocument = async (collectionPathParam: string, documentPathParam: string) => {
    await deleteDoc(doc(db, collectionPathParam, documentPathParam));
  };

  const snapshotWithWhere = useCallback((collectionPathParam: string, ...queryConstraints: QueryConstraint[]) => {
    const q = query(collection(db, collectionPathParam), ...queryConstraints);
    const unsubscribe = onSnapshot(q, querySnapshot => {
      if (querySnapshot.empty) {
        setSnapshotQuery(undefined);
        return;
      }

      setSnapshotQuery(querySnapshot.docs);
    });

    return unsubscribe;
  }, []);

  return { snapshot, getDocument, setDocument, removeDocument, snapshotWithWhere, snapshotQuery };
};

export { useFirestore };
