import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import BaseButton from '~/components/Button';
import Button from '~/components/Button';
import InputComponent from '~/components/InputComponent';
import Colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: ${Colors.primary}; */
  flex: 1;
  padding: 0px 16px;
`;

export const FormInput = styled(InputComponent)`
  margin-bottom: 10px;
  color: ${Colors.primary};
`;

export const SubmitButton = styled(BaseButton)`
  margin-top: 10px;
`;

export const BackLink = styled(Button).attrs({
  activeOpacity: 0.7,
})`
  margin-bottom: 20px;
`;

export const LabelError = styled(Typography)`
  color: ${Colors.primary};
  text-align: center;
  font-weight: bold;
`;

export const Label = styled(Typography)`
  color: ${Colors.primary};
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;
