import React, { useCallback, useRef, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import InputComponent from '~/components/InputComponent';
import { AddressComponent } from '~/types';
import getAddress from '../../util/getAddress';

interface FullAddress {
  address?: string;
  lat?: number;
  lng?: number;
}

interface LocationSearchInputProps {
  label: string;
  setLatLng: (latLng: google.maps.LatLngLiteral | undefined) => void;
  fnSetAddress: (address: AddressComponent) => void;
  fnOnChange?: () => void;
}

const LocationSearchInput = ({ label, setLatLng, fnSetAddress, fnOnChange }: LocationSearchInputProps) => {
  const [address, setAddress] = useState('');
  const refText = useRef(null);

  const handleChange = useCallback(
    address => {
      setAddress(address);

      fnOnChange && fnOnChange();
    },
    [fnOnChange],
  );

  const handleSelect = useCallback(
    async (addressSelected: string) => {
      try {
        const results = await geocodeByAddress(addressSelected);

        const addressDetail = getAddress(results[0].address_components);

        const formattedAddress = results[0].formatted_address;
        const latLng = await getLatLng(results[0]);

        setLatLng(latLng);
        fnSetAddress(addressDetail);

        setAddress(formattedAddress);
      } catch (error) {
        return undefined;
      }
    },
    [fnSetAddress, setLatLng],
  );

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{ componentRestrictions: { country: 'br' } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <InputComponent
            {...getInputProps({
              className: 'location-search-input',
            })}
            label={label}
            type='text'
            ref={refText}
          />

          <div className='autocomplete-dropdown-container'>
            {loading && <div>Carregando...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer', lineHeight: 3 }
                : { backgroundColor: '#ffffff', cursor: 'pointer', lineHeight: 3 };

              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={index}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
