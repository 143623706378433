import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Card, CardContent, Container, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { v4 as uuidV4 } from 'uuid';
import { ChevronRight } from '@material-ui/icons';
import { where } from 'firebase/firestore';

import { getIconService } from '~/util/getIcon';
import useServices from '~/services/hooks/useServices';
import { useToast } from '~/services/hooks/toast';
import { clearOrder, setOrderInitial, setOrderService } from '~/store/ducks/order';
import Title from '../../components/Title';
import { OrderFirestore, ServicesResponse } from '~/types';
import SimpleBottomNavigation from '~/components/SimpleBottomNavigation';
import SimpleBottomAction from '~/components/SimpleBottomAction';
import { clearBalance } from '~/store/ducks/associationBalance';
import HasOrderInFirestore from '~/components/HasOrderInFirestore';
import { useFirestore } from '~/services/hooks/useFirestore';

const OpportunityServices = () => {
  const [service, setService] = useState<ServicesResponse>();
  const [hasOrderInFirestore, setHasOrderInFirestore] = useState(false);

  const { services, loadingServices } = useServices();
  const { addToast } = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const userName = useSelector(state => state.auth.user['cognito:username']);
  const { snapshotWithWhere, snapshotQuery } = useFirestore();

  useEffect(() => {
    const unsubscribe = snapshotWithWhere('orders', where('customer_id', '==', userName));

    return unsubscribe;
  }, [snapshotWithWhere, userName]);

  useEffect(() => {
    const snapshotFiltered = snapshotQuery?.filter(doc => {
      const docData = doc.data() as OrderFirestore;
      return !docData.customer_survey && docData.license_plate;
    });

    setHasOrderInFirestore((snapshotFiltered || []).length > 0);
  }, [snapshotQuery]);

  useEffect(() => {
    if (location.pathname === '/login') {
      history.replace('/');
    }
  }, [history, location.pathname]);

  const { service: serviceState } = useSelector(state => state.order);

  useEffect(() => {
    setService(serviceState);
    dispatch(clearOrder());
    dispatch(clearBalance());
    dispatch(setOrderInitial({ uuid: uuidV4() }));
  }, [dispatch, serviceState]);

  const handleListItemClick = useCallback((service: ServicesResponse) => {
    setService(service);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!service?.id) {
      addToast({ title: 'Validação', type: 'error', description: 'Tipo do serviço obrigatório!' });
      return;
    }

    dispatch(setOrderService(service));
    history.push('/new-opportunity/vehicles');
  }, [addToast, dispatch, history, service]);

  return (
    <>
      {hasOrderInFirestore && <HasOrderInFirestore />}

      <Container>
        <Title>Tipo de serviço</Title>

        <Card style={{ marginTop: 30 }}>
          <CardContent>
            <List component='nav' aria-label='main mailbox folders'>
              {services?.map(item => (
                <ListItem
                  key={item.id}
                  button
                  selected={service?.id === item.id}
                  onClick={() => handleListItemClick(item)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={item.description}
                      src={getIconService(item.description)}
                      style={{ width: 48, height: 48 }}
                    />
                  </ListItemAvatar>

                  <ListItemText primary={item.description} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Container>

      <SimpleBottomNavigation>
        <SimpleBottomAction
          label='Avançar'
          icon={ChevronRight}
          onClick={handleSubmit}
          loading={loadingServices}
          disabled={loadingServices}
        />
      </SimpleBottomNavigation>
    </>
  );
};

export default OpportunityServices;
