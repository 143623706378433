import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Canceled from '~/assets/svg/canceled.svg';
import { useFirestore } from '~/services/hooks/useFirestore';
import Colors from '~/styles/colors';
import { BackgroundWithColor, ButtonStyled, Card, Container, TextMessageContainer } from './styles';

const STATUS_MESSAGE = {
  status: 'CANCELED',
  title: 'PAGAMENTO CANCELADO',
  message:
    'Como a solicitação de cancelamento foi feita após um período de 5 min do prestador de serviços ter aceitado a solicitação será cobrada uma taxa.',
};

export default function RequestCanceledByCustomer() {
  const { orderId } = useLocation().state as { orderId?: string };
  const history = useHistory();
  const { removeDocument } = useFirestore();

  useEffect(() => {
    if (!orderId) {
      return history.replace('/');
    }

    removeDocument('orders', orderId);
  }, [history, orderId, removeDocument]);

  const handleGoTop = () => {
    history.push('/new-opportunity/services');
  };

  return (
    <Container>
      <BackgroundWithColor />

      <Card>
        <TextMessageContainer>
          <img alt='Canceled' src={Canceled} width={100} height={100} />
          <Typography>{STATUS_MESSAGE.message}</Typography>
        </TextMessageContainer>

        <Typography style={{ color: Colors.white }}>{STATUS_MESSAGE.title}</Typography>

        <ButtonStyled
          type='button'
          backgroundColor={Colors.primary}
          color={Colors.white}
          onClick={handleGoTop}
          label='Solicitar o serviço novamente'
        />
      </Card>
    </Container>
  );
}
