import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import {
  validateRegionsServiceApi,
  getTimeZoneByGoogleApi,
  maplinkTripApi,
  mapCalculatorApi,
  getAllPaymentMethodsApi,
  createOrderApi,
} from '../api';

import {
  Coords,
  MapCalculatorRequest,
  MapCalculatorResponse,
  OrderRequest,
  PaymentMethodResponse,
  TripRequest,
} from '~/types';

interface TimeZoneResponse {
  data: {
    timeZoneId: string;
  };
}

export function useNewOrder() {
  const [payments, setPayments] = useState<PaymentMethodResponse[]>();

  useEffect(() => {
    const callApi = async () => {
      const response = (await getAllPaymentMethodsApi()) as AxiosResponse<PaymentMethodResponse[]>;

      setPayments(response.data);
    };

    callApi();
  }, []);

  const validateRegionsService = useCallback(async (origin: Coords): Promise<boolean> => {
    try {
      await validateRegionsServiceApi(origin);
      return true;
    } catch (_) {
      return false;
    }
  }, []);

  const getTimeZoneByGoogle = useCallback(async (origin: Coords): Promise<string | undefined> => {
    try {
      const {
        data: { timeZoneId },
      } = (await getTimeZoneByGoogleApi(
        process.env.REACT_APP_GOOGLE_API_KEY,
        origin.latitude,
        origin.longitude,
      )) as TimeZoneResponse;

      return timeZoneId;
    } catch (error) {
      return undefined;
    }
  }, []);

  const maplinkTrip = useCallback(async (request: TripRequest): Promise<AxiosResponse<any>> => {
    return await maplinkTripApi(request);
  }, []);

  const mapCalculator = useCallback(async (request: MapCalculatorRequest): Promise<
    AxiosResponse<MapCalculatorResponse>
  > => {
    return await mapCalculatorApi(request);
  }, []);

  const createOrder = useCallback(async (request: OrderRequest): Promise<AxiosResponse<any>> => {
    return await createOrderApi(request);
  }, []);

  return {
    validateRegionsService,
    getTimeZoneByGoogle,
    maplinkTrip,
    mapCalculator,
    payments,
    createOrder,
  };
}
