import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Container, ViewContent, ViewButton, Label } from './styles';
import Button from '~/components/Button';
import { PICKTOW_PHONE } from '~/config/constants';
import { useFirestore } from '~/services/hooks/useFirestore';
import Colors from '~/styles/colors';

const RequestCanceled = () => {
  const history = useHistory();
  const { orderId, licensePlate, serviceType, canceledByUser } = (useLocation().state || {}) as {
    orderId: string;
    licensePlate: string;
    serviceType: string;
    canceledByUser: boolean;
  };
  const { removeDocument } = useFirestore();

  useEffect(() => {
    if (!orderId) {
      history.push('/');
    }
  }, [history, orderId]);

  const handleGoTop = useCallback(async () => {
    if (orderId) {
      await removeDocument('orders', orderId);
    }

    history.push('/');
  }, [history, orderId, removeDocument]);

  const handleCallPicktow = useCallback(() => {
    document.location.href = `tel:${PICKTOW_PHONE}`;
  }, []);

  const handleGoBackAndRemoveOrder = async () => {
    if (orderId) {
      await removeDocument('orders', orderId);
    }

    history.replace('/');
  };

  useEffect(() => {
    if (canceledByUser) {
      handleGoTop();
    }
  }, [canceledByUser, handleGoTop]);

  return (
    <Container>
      <ViewContent>
        <Label data={{ fontSize: 22, textAlign: 'center' }}>
          Sua oportunidade
          <br />
          foi cancelada.
          <br />
          <br />
        </Label>

        {serviceType && <Label data={{ fontSize: 22, textAlign: 'center' }}>Serviço {serviceType}</Label>}

        {licensePlate && <Label data={{ fontSize: 22, textAlign: 'center' }}>Placa {licensePlate}</Label>}

        {(serviceType || licensePlate) && (
          <Label data={{ fontSize: 22, textAlign: 'center' }}>
            <br />
          </Label>
        )}

        <Label data={{ fontSize: 22, textAlign: 'center' }}>
          Entre em contato
          <br />
          com a <Label data={{ fontSize: 22, fontWeight: 'bold' }}>Picktow</Label>
        </Label>

        <Label data={{ fontSize: 22, fontWeight: 'bold' }} onClick={handleCallPicktow}>
          {PICKTOW_PHONE}
        </Label>
      </ViewContent>

      <ViewButton>
        <Button
          label='Voltar'
          onClick={handleGoBackAndRemoveOrder}
          fontWeight='bold'
          borderSize={0}
          color={Colors.white}
          backgroundColor={Colors.primary}
        />
      </ViewButton>
    </Container>
  );
};

export default RequestCanceled;
