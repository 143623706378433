import React from 'react';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';

import { Container } from './styles';

const Loading = ({ ...rest }: CircularProgressProps) => {
  return (
    <Container>
      <CircularProgress {...rest} />
    </Container>
  );
};

export default Loading;
