import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { IconButton, SwipeableDrawer, Toolbar, makeStyles } from '@material-ui/core';
import { Menu as MenuIcon, PlayCircleOutline } from '@material-ui/icons';
import { where } from 'firebase/firestore';
import { useSelector } from 'react-redux';

import LogoPicktow from '~/assets/svg/logo_p_picktow.svg';
import Menu from '~/components/Menu';
import { BoxLogo, AppBar, Main } from './styles';
import { LayoutProps, OrderFirestore } from '~/types';
import Colors from '~/styles/colors';
import { useFirestore } from '~/services/hooks/useFirestore';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export function DefaultLayout({ children, showFirebaseOrder }: LayoutProps) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [hasOrderInFirestore, setHasOrderInFirestore] = useState(false);

  const history = useHistory();
  const userName = useSelector(state => state.auth.user['cognito:username']);
  const { snapshotWithWhere, snapshotQuery } = useFirestore();

  useEffect(() => {
    const unsubscribe = snapshotWithWhere('orders', where('customer_id', '==', userName));

    return unsubscribe;
  }, [snapshotWithWhere, userName]);

  useEffect(() => {
    const snapshotFiltered = snapshotQuery?.filter(doc => {
      const docData = doc.data() as OrderFirestore;
      return !docData.customer_survey && docData.license_plate;
    });

    setHasOrderInFirestore((snapshotFiltered || []).length > 0);
  }, [snapshotQuery]);

  const toggleDrawer = (status?: boolean) => {
    if (status === undefined) {
      setOpen(!open);
    } else {
      setOpen(status);
    }
  };

  return (
    <Main>
      <AppBar position='fixed' color='primary' className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={() => {
              toggleDrawer();
            }}
          >
            <MenuIcon />
          </IconButton>

          <BoxLogo>
            <NavLink to='/'>
              <img src={LogoPicktow} alt='Picktow' />
            </NavLink>
          </BoxLogo>

          {showFirebaseOrder && hasOrderInFirestore && (
            <IconButton
              edge='end'
              onClick={() => {
                history.push('/orders-in-execution');
              }}
            >
              <PlayCircleOutline style={{ color: Colors.green, fontSize: 32 }} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <SwipeableDrawer onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(true)} open={open}>
        <Menu menuIsOpen={open} toggleDrawer={toggleDrawer} />
      </SwipeableDrawer>

      <Toolbar />
      {children}
    </Main>
  );
}

export default DefaultLayout;
